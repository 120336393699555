import React, { FC } from 'react';

import { Accordion, AccordionSummary, TableContainer, Paper } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

import { formatter } from '../../utils/formatter';

import Table from '../Table';
import { LoadingContainer, StyledAccordionDetails, StyledButton } from './styled';
import { PaymentRefund } from '../../types/gql';

export type ChargebeePayment = {
    __typename?: 'ChargebeePayment' | undefined;
    paymentId: string;
    last4: string;
    amount: number;
    date: string;
    status: string;
    paymentMethod: string;
    type: string;
    refund?: PaymentRefund | null;
};

export interface ChargebeePaymentHistoryProps {
    payments?: ChargebeePayment[];
    paymentsLoading: boolean;
    refundLoading: boolean;
    refundPaymentHandler: (paymentId: string) => Promise<void>;
}

const ChargebeePaymentHistory: FC<ChargebeePaymentHistoryProps> = ({
    payments,
    paymentsLoading,
    refundLoading,
    refundPaymentHandler,
}) => (
    <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <h3>Billing History</h3>
        </AccordionSummary>

        <StyledAccordionDetails>
            {!payments?.length ? (
                <LoadingContainer>
                    {paymentsLoading ? 'Loading payments' : 'No payments'}
                </LoadingContainer>
            ) : (
                <TableContainer component={Paper}>
                    <Table
                        columns={[
                            {
                                label: 'Date',
                                render: (p: ChargebeePayment) =>
                                    p.date ? moment(p.date).format('L') : '',
                            },
                            {
                                label: 'Amount',
                                render: (p: ChargebeePayment) => formatter.format(p.amount / 100),
                            },
                            {
                                label: 'Payment Method',
                                render: (p: ChargebeePayment) => p.paymentMethod,
                            },
                            {
                                label: 'Status',
                                render: (p: ChargebeePayment) => p.status,
                            },
                            {
                                label: 'Mask',
                                render: (p: ChargebeePayment) => p.last4,
                            },
                            {
                                label: 'Refund',
                                render: (p: ChargebeePayment) => {
                                    if (!p.refund) return 'n/a';
                                    return `Status: ${p.refund?.status} SettleDate: ${p.refund?.settleDate}`;
                                },
                            },
                            {
                                label: 'Refund Payment',
                                render: (p: ChargebeePayment) => (
                                    <StyledButton
                                        type="button"
                                        disabled={
                                            refundLoading || !!p.refund || p.status === 'refunded'
                                        }
                                        onClick={() => refundPaymentHandler(p.paymentId)}
                                    >
                                        Refund
                                    </StyledButton>
                                ),
                            },
                        ]}
                        rows={payments}
                        rowsPerPageOptions={[5, 10, 20]}
                        options={{
                            showSearch: false,
                            showTitle: false,
                            allowSorting: false,
                        }}
                    />
                </TableContainer>
            )}
        </StyledAccordionDetails>
    </Accordion>
);

export default ChargebeePaymentHistory;

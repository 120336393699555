import * as session from './session';

const defaultHeaders = () => {
    const headers = {
        Accept: 'application/json',
    };

    const s = session.get();
    if (s) {
        //! FIXME type issue
        // @ts-expect-error ts migration issue
        headers.Authorization = `Bearer ${s.tokens.hiatus}`;
    }

    return headers;
};

const doFetch = async ({ path, apiId, stage }: any, opts: any) => {
    let apiPath = `/api/${path}`;
    if (apiId) {
        // passing apiId will transform path to resolve to api stage
        // ie `hiatus/profile/user/123` => `hiatus-dev/${apiId}/${stage}/user/123
        // used for consuming feature api branches in development
        apiPath = apiPath.replace(/hiatus\/.*?\//, `hiatus-dev/${apiId}/${stage}/`);
    }

    const resp = await fetch(apiPath, opts);
    let body;
    try {
        const type = resp.headers.get('Content-Type') || 'application/json';
        switch (true) {
            case type.includes('application/json'):
                body = await resp.json();
                break;
            case type.includes('text/html'):
                body = await resp.text();
                break;
            default:
                throw new Error(`unsupported response type ${type}`);
        }
    } catch (err) {
        body = null;
    }

    return {
        status: resp.status,
        body,
    };
};

const get = ({ path, headers = {}, apiId, stage }: any = {}) => {
    return doFetch(
        { path, apiId, stage },
        {
            method: 'GET',
            headers: { ...defaultHeaders(), ...headers },
        }
    );
};

const post = ({ path, headers = {}, body, apiId, stage }: any = {}) => {
    return doFetch(
        { path, apiId, stage },
        {
            method: 'POST',
            headers: { ...defaultHeaders(), 'Content-Type': 'application/json', ...headers },
            body: JSON.stringify(body),
        }
    );
};

const put = ({ path, headers = {}, body, apiId, stage }: any = {}) => {
    return doFetch(
        { path, apiId, stage },
        {
            method: 'PUT',
            headers: { ...defaultHeaders(), 'Content-Type': 'application/json', ...headers },
            body: JSON.stringify(body),
        }
    );
};

const del = ({ path, headers = {}, apiId, stage }: any = {}) => {
    return doFetch(
        { path, apiId, stage },
        {
            method: 'DELETE',
            headers: { ...defaultHeaders(), ...headers },
        }
    );
};

export { get, post, put, del };

import { actionTypes } from './actions';

const initialState = {
    connections: [],
    institutions: [],
    reset: {
        loading: false,
        data: '',
    },
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.CONNECTIONS_GETALL_SUCCESS: {
            const { connections, institutions } = action.payload;

            return { ...state, connections, institutions };
        }
        case actionTypes.CONNECTIONS_RESET_REQUEST: {
            return {
                ...state,
                reset: { ...state.reset, loading: true },
            };
        }
        case actionTypes.CONNECTIONS_RESET_SUCCESS: {
            return {
                ...state,
                reset: { loading: false, data: action.payload.reset },
            };
        }
        case actionTypes.CONNECTIONS_RESET_ERROR: {
            return {
                ...state,
                reset: { loading: false, data: action.payload.error },
            };
        }
        default: {
            return state;
        }
    }
};

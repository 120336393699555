/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ErrorMessage from '../../../ui/ErrorMessage';
import Checkbox from '../../../ui/finalform/Checkbox';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    },
    progress: {
        verticalAlign: 'middle',
        marginLeft: theme.spacing(1)
    }
}));

const typeMappings = {
    insurance: 'Insurance',
    utility: 'Utility'
};

export default ({ mode, initial, onCancel, onSave }: any = {}) => {
    const classes = useStyles();
    const saveLabel = mode === 'edit' ? 'Save' : 'Create';

    const [theType, setTheType] = React.useState(initial?.type ?? '');
    const handleChange = (event) => {
        setTheType(event.target.value);
    };
    return (
        <Form initialValues={initial} onSubmit={onSave}>
            {({ submitting, handleSubmit, submitError }) => (
                <form onSubmit={handleSubmit}>
                    <ErrorMessage message={submitError} />
                    <Field name="displayName">
                        {({ input }) => <TextField variant="outlined" required label="display name" className={classes.textField} {...input} />}
                    </Field>
                    <Field name="deleted" type="checkbox">
                        {({ input }) => {
                            return (
                                <Checkbox label="Deleted" color="primary" initial={(initial && initial.deleted) || false} onChange={input.onChange} />
                            );
                        }}
                    </Field>
                    <Field name="type" type="select">
                        {({ input }) => {
                            return (
                                <Select
                                    labelId="type-label"
                                    label="type"
                                    autoWidth
                                    displayEmpty
                                    value={theType}
                                    onChange={(event) => {
                                        input.onChange(event);
                                        handleChange(event);
                                    }}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {Object.keys(typeMappings).map((s: any) => {
                                        return (
                                            <MenuItem value={s} key={s}>
                                                {typeMappings[s]}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            );
                        }}
                    </Field>
                    <Button variant="contained" className={classes.button} onClick={onCancel} size="medium" disabled={submitting}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} size="medium" type="submit" disabled={submitting}>
                        {saveLabel}
                    </Button>
                    {submitting && <CircularProgress size={24} className={classes.progress} />}
                </form>
            )}
        </Form>
    );
};

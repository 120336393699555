import React, { Dispatch, SetStateAction, useEffect, useState, useCallback } from 'react';
import ErrorMessage from '../../../ui/ErrorMessage';
import * as api from './api';
import Release from './Release';

const ReleaseContainer = () => {
    const getReleaseInfo = async (
        platform: string,
        setErrorMessage: Dispatch<SetStateAction<string | undefined>>,
        setVersionString: Dispatch<SetStateAction<string | undefined>>,
        setVersionId: Dispatch<SetStateAction<string | undefined>>
    ) => {
        // eslint-disable-next-line no-console
        setVersionString('');
        setVersionId('');
        if (platform === 'web') {
            setVersionId('filler-web-id')
            return;
        }
        const { body, status } = await api.getReleaseInfo(platform);
        if (![200, 202, 204].includes(status)) {
            setErrorMessage(
                `received code ${status} at call "${body.failedStep}" while fetching ${platform} versions (${new Date().getTime()})`
            );
            return;
        }
        setVersionString(body.versionString);
        setVersionId(body.id);
    };

    const [processing, setProcessing] = useState<boolean>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [platform, setPlatform] = useState<'ios' | 'android' | 'web'>('ios');
    const [versionString, setVersionString] = useState<string>();
    const [versionId, setVersionId] = useState<string>();

    useEffect(() => {
        getReleaseInfo(platform, setErrorMessage, setVersionString, setVersionId);
    }, [platform]);

    const onSubmit = useCallback(
        async (versionTitle: string, releaseNotes: string) => {
            setProcessing(true);
            try {
                const { status, body } = await api.publishRelease(
                    versionId,
                    platform,
                    versionString!,
                    versionTitle,
                    releaseNotes
                );

                if (![201].includes(status)) {
                    setErrorMessage(
                        `received code ${body.failedStatus} at call "${body.failedStep}" while trying to submit ${platform} version; only completed steps "${body.successfulSteps}" (${new Date().getTime()})`
                    );
                }

                if ([201].includes(status)) {
                    setErrorMessage(
                        `Successfully submitted ${platform} version ${versionString}, completing steps "${body.successfulSteps}" (${new Date().getTime()})`
                    );
                    setProcessing(false);
                    setVersionString('');
                    return true;
                }
            } catch (err) {
                setErrorMessage(`${err} (${new Date().getTime()})`);
            }
            setProcessing(false);
            return false;
        },
        [platform, versionString, versionId]
    );

    return (
        <>
            <ErrorMessage message={errorMessage} />
            <Release
                processing={processing}
                onSubmit={onSubmit}
                versionString={versionString}
                platform={platform}
                handlePlatformChange={setPlatform}
                versionId={versionId}
                setVersionString={setVersionString}
            />
        </>
    );
};

export default ReleaseContainer;

import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import logo from '../../../assets/hiatus.jpg';
import ErrorMessage from '../../../ui/ErrorMessage';

const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    image: {
        width: '40%',
        margin: theme.spacing(2)
    },
    title: {
        marginBottom: theme.spacing(4)
    },
    form: {
        width: '100%' // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default ({ fetchLoginConfig, config, location, login, isFetching, error }: any) => {
    const classes = useStyles();
    React.useEffect(() => {
        fetchLoginConfig();
    }, [fetchLoginConfig]);

    const qs = new URLSearchParams(location.search);
    const code = qs.get('code');
    React.useEffect(() => {
        if (code) {
            login(code);
        }
    }, [login, code]);

    let googleUrl;
    const { urls } = config;
    if (urls) {
        const onLogin = qs.get('onLogin');
        if (onLogin) {
            googleUrl = urls.google.replace('/login', `/login&state=${onLogin}`);
        } else {
            googleUrl = urls.google;
        }
    }

    const inProgress = isFetching || (!!code && !error);
    const showLogin = !code || (!!code && error);
    return (
        <>
            <Helmet>
                <title>Hiatus Tools | Login</title>
            </Helmet>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={logo} alt="Hiatus" className={classes.image} />
                    <Typography component="h1" variant="h5" className={classes.title}>
                        Hiatus Tools
                    </Typography>
                    {inProgress ? (
                        // @ts-expect-error ts migration issue TS(2339): Property 'progress' does not exist on type 'ClassN... Remove this comment to see the full error message
                        <CircularProgress className={classes.progress} />
                    ) : (
                        <>
                            {urls && showLogin && (
                                <form className={classes.form} noValidate>
                                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} href={googleUrl}>
                                        Sign In With Google
                                    </Button>
                                </form>
                            )}
                        </>
                    )}
                </div>
                {error && <ErrorMessage message={error} />}
            </Container>
        </>
    );
};

/* eslint-disable no-restricted-globals */
export const toInt = (val: any) => {
    if (!val) return '';
    const parsed = parseInt(val, 10);
    if (isNaN(parsed)) return '';
    return parsed;
};

export const toFloat = (val: any) => {
    if (!val) return '';
    if (val.endsWith('.')) return val;
    const parsed = parseFloat(val);
    if (isNaN(parsed)) return '';
    return parsed;
};

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Avatar from './Avatar';
import { logout } from '../../Identity';

export default connect(
    (state: any) => {
        const { user, exp } = state.identity.current;
        return {
            user,
            exp
        };
    },
    (dispatch: any) => bindActionCreators({ logout }, dispatch)
)(Avatar);

import { api } from '../../../services';

const getEntry = (id: any) => {
    return api.get({
        path: `hiatus/content/entry/${id}`
    });
};

const getPreview = (id: any) => {
    return api.get({
        path: `hiatus/content/entry/${id}?preview=true`
    });
};

export { getEntry, getPreview };

/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import TextField from '@material-ui/core/TextField';

export default (props: any) => {
    const { input, meta } = props;
    const others = {};
    Object.keys(props).forEach((k) => {
        if (k !== 'input' && k !== 'meta') {
            others[k] = props[k];
        }
    });
    return (
        <TextField
            variant="outlined"
            error={meta && meta.error && meta.touched}
            helperText={meta && meta.touched && meta.error}
            {...input}
            {...others}
        />
    );
};

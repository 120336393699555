import { api } from '../../../services';

const getSampleForm = () => {
    return api.get({
        path: 'hiatus/forms/sample/form',
        headers: {
            Accept: 'text/html'
        }
    });
};

export default getSampleForm;

import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

export default ({ negotiation, onUpdate }: any = {}) => {
    const [cleanSelected, setCleanSelected] = React.useState(negotiation.badData || []);
    const [selected, setSelected] = React.useState(negotiation.badData || []);
    const [submitting, setSubmitting] = React.useState(false);
    const [touched, setTouched] = React.useState(false);
    const { firstName, lastName, address, data } = negotiation || {};

    const handleClick = (event: any, name: any) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: any = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        if (cleanSelected.length === newSelected.length && cleanSelected.every((value: any, index: any) => value === newSelected[index])) {
            setTouched(false);
        } else {
            setTouched(true);
        }
    };

    const handleSubmit = async () => {
        setSubmitting(true);
        const updated = {
            status: 'submitted',
            outcome: { result: 'badInfo' },
            badData: selected
        };
        await onUpdate(updated);
        setCleanSelected(selected);
        setTouched(false);
        setSubmitting(false);
    };

    const isSelected = (name: any) => selected.indexOf(name) !== -1;
    const tableData = {
        'First Name': {
            value: firstName,
            field: 'first_name'
        },
        'Last Name': {
            value: lastName,
            field: 'last_name'
        },
        'Address Line1': {
            value: address.line1,
            field: 'street_address'
        },
        'Address Line2': {
            value: address.line2 || '',
            field: 'apt_number'
        },
        'Address ZipCode': {
            value: address.zipCode,
            field: 'zip_code'
        }
    };
    Object.assign(tableData, data);

    return (
        <>
            <TableContainer>
                <Table size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row" padding="none">
                                Field Name
                            </TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell size="small" align="right">
                                Bad data
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(tableData).map((k, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            const value = Object.prototype.hasOwnProperty.call(tableData[k], 'value') ? tableData[k].value : tableData[k];
                            const field = Object.prototype.hasOwnProperty.call(tableData[k], 'field') ? tableData[k].field : k;
                            const isItemSelected = isSelected(field);
                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, field)}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={k}
                                    selected={isItemSelected}
                                >
                                    <TableCell component="th" id={labelId} scope="row" padding="none">
                                        {k}
                                    </TableCell>
                                    <TableCell>{value}</TableCell>
                                    <TableCell size="small" align="right">
                                        <Checkbox checked={isItemSelected} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell style={{ border: 'none' }} />
                            <TableCell style={{ border: 'none' }} />
                            <TableCell style={{ border: 'none' }} size="small" align="right">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={async () => {
                                        return handleSubmit();
                                    }}
                                    disabled={submitting || !touched}
                                >
                                    Update Bad Data
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};

import moment from 'moment';
import { decode as jwtDecode } from 'jsonwebtoken';
import { api, session } from '../../services';

export const actionTypes = {
    IDENTITY_CONFIG_REQUEST: 'IDENTITY/CONFIG_REQUEST',
    IDENTITY_CONFIG_SUCCESS: 'IDENTITY/CONFIG_SUCCESS',
    IDENTITY_CONFIG_ERROR: 'IDENTITY/CONFIG_ERROR',

    IDENTITY_LOADSESSION_REQUEST: 'IDENTITY/LOADSESSSION_REQUEST',
    IDENTITY_LOADSESSSION_SUCCESS: 'IDENTITY/LOADSESSSION_SUCCESS',
    IDENTITY_LOADSESSSION_ERROR: 'IDENTITY/LOADSESSSION_ERROR',

    IDENTITY_LOGIN_REQUEST: 'IDENTITY/LOGIN_REQUEST',
    IDENTITY_LOGIN_FAILURE: 'IDENTITY/LOGIN_FAILURE',
    IDENTITY_LOGIN_SUCCESS: 'IDENTITY/LOGIN_SUCCESS',
    IDENTITY_LOGIN_ERROR: 'IDENTITY/LOGIN_ERROR',

    IDENTITY_REFRESH_REQUEST: 'IDENTITY/REFRESH_REQUEST',
    IDENTITY_REFRESH_FAILURE: 'IDENTITY/REFRESH_FAILURE',
    IDENTITY_REFRESH_SUCCESS: 'IDENTITY/REFRESH_SUCCESS',
    IDENTITY_REFRESH_ERROR: 'IDENTITY/REFRESH_ERROR',

    IDENTITY_LOGOUT_SUCCESS: 'IDENTITY/LOGOUT_SUCCESS'
};

async function getHiatusToken({ cognitoTokens }: any = {}) {
    const { status, body } = await api.post({
        path: 'hiatus/identity-employees/token',
        body: {
            token: cognitoTokens.id
        }
    });
    if (status !== 200) {
        throw new Error(`hiatus token exchange failed with status ${status}`);
    }

    const { token } = body;
    //! FIXME type issue
    // @ts-expect-error ts migration issue
    const { sub, displayName, claims, exp, iat } = jwtDecode(token, { complete: true }).payload;
    return {
        user: {
            id: sub,
            displayName,
            claims
        },
        tokens: {
            hiatus: token,
            cognito: {
                refresh: cognitoTokens.refresh
            }
        },
        iat,
        exp
    };
}

export const fetchLoginConfig = () => async (dispatch: any) => {
    dispatch({
        type: actionTypes.IDENTITY_CONFIG_REQUEST
    });

    try {
        const { body } = await api.get({
            path: 'cognito/config'
        });

        dispatch({
            type: actionTypes.IDENTITY_CONFIG_SUCCESS,
            payload: body
        });
    } catch (error) {
        dispatch({
            type: actionTypes.IDENTITY_CONFIG_ERROR,
            payload: { error }
        });
    }
};

export const loadFromSession = () => async (dispatch: any) => {
    dispatch({
        type: actionTypes.IDENTITY_LOADSESSION_REQUEST
    });

    try {
        const current = session.get();
        if (current) {
            dispatch({
                type: actionTypes.IDENTITY_LOADSESSSION_SUCCESS,
                payload: current
            });
        }
    } catch (error) {
        dispatch({
            type: actionTypes.IDENTITY_LOADSESSSION_ERROR,
            payload: { error }
        });
    }
};

export const login = (authCode: any) => async (dispatch: any) => {
    dispatch({
        type: actionTypes.IDENTITY_LOGIN_REQUEST
    });

    try {
        // cognito token
        const cognitoResp = await api.post({
            path: 'cognito/token',
            body: { code: authCode }
        });
        const { id, refresh } = cognitoResp.body || {};
        if (!id) {
            dispatch({
                type: actionTypes.IDENTITY_LOGIN_FAILURE,
                payload: {
                    error: 'cognito token exchange failed'
                }
            });
            return;
        }

        // hiatus token
        let user;
        try {
            user = await getHiatusToken({
                cognitoTokens: {
                    id,
                    refresh
                }
            });
        } catch (e: any) {
            dispatch({
                type: actionTypes.IDENTITY_LOGIN_FAILURE,
                payload: {
                    error: e.message
                }
            });
            return;
        }

        // login success
        dispatch({
            type: actionTypes.IDENTITY_LOGIN_SUCCESS,
            payload: user
        });
    } catch (error) {
        dispatch({
            type: actionTypes.IDENTITY_LOGIN_ERROR,
            payload: { error }
        });
    }
};

const monitors: any = [];
export const monitor = (current: any) => async (dispatch: any) => {
    if (!current) return;

    const checkForRefresh = async () => {
        // check if token will expire soon
        const remaining = moment.unix(current.exp).diff(moment(), 'minutes');
        if (remaining > 5) return; //  to do: config

        dispatch({
            type: actionTypes.IDENTITY_REFRESH_REQUEST
        });
        const { refresh } = current.tokens.cognito;
        try {
            // expired refresh token returns http 400 and invalid_grant
            // https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
            const cognitoResp = await api.post({
                path: 'cognito/token',
                body: { token: refresh }
            });
            const { id } = cognitoResp.body || {};
            if (!id) {
                dispatch({
                    type: actionTypes.IDENTITY_REFRESH_FAILURE,
                    payload: {
                        error: 'session expired'
                    }
                });
                return;
            }

            // hiatus token
            let user;
            try {
                user = await getHiatusToken({
                    cognitoTokens: {
                        id,
                        refresh
                    }
                });
            } catch (e) {
                dispatch({
                    type: actionTypes.IDENTITY_REFRESH_FAILURE,
                    payload: {
                        error: 'session expired'
                    }
                });
                return;
            }

            // refresh success
            dispatch({
                type: actionTypes.IDENTITY_REFRESH_SUCCESS,
                payload: user
            });
        } catch (error) {
            dispatch({
                type: actionTypes.IDENTITY_REFRESH_ERROR,
                payload: { error }
            });
        }
    };

    // initial check to catch stale tokens
    await checkForRefresh();

    // monitor on interval
    while (monitors.length > 0) {
        clearInterval(monitors.pop());
    }
    monitors.push(setInterval(checkForRefresh, 1000 * 60 * 2)); // to do: config
};

export const logout = () => (dispatch: any) => {
    dispatch({
        type: actionTypes.IDENTITY_LOGOUT_SUCCESS
    });
};

import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import MUITableRow from '@material-ui/core/TableRow';
import { fade, makeStyles } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';

import TableCellContent from './TableCellContent';

const useRowStyles = makeStyles((theme) => ({
    tableRow: ({ hasOnRowClick }: { hasOnRowClick: boolean }) => ({
        ...(hasOnRowClick && {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.light, 0.1)
            }
        })
    })
}));

const TableRow = ({ row, columns, onRowClick, hasOnRowClick, detailPanel }: any) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const classes = useRowStyles({ hasOnRowClick });

    return (
        <>
            <MUITableRow
                className={classes.tableRow}
                {...(hasOnRowClick && {
                    onClick: (e: any) => onRowClick(e, row)
                })}
            >
                {detailPanel && (
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setIsOpen(!isOpen)}>
                            {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                    </TableCell>
                )}
                {columns.map((col: any) => (
                    <TableCell key={JSON.stringify(col)}>
                        <TableCellContent row={row} col={col} />
                    </TableCell>
                ))}
            </MUITableRow>
            {detailPanel && isOpen && (
                <MUITableRow>
                    <TableCell colSpan={columns.length + 1}>{detailPanel(row)}</TableCell>
                </MUITableRow>
            )}
        </>
    );
};

export default TableRow;

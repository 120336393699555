import React, { useState, createContext } from 'react';
import { Route } from '../../router/utils';
import List from './List';
import Detail from './Detail';
import Create from './Create';
import { InputContext } from './context';

export default ({ path }: any = {}) => {
    const [defaultFilter, setDefaultFilter] = useState('');
    return (
        <>
            <InputContext.Provider value={{ input: defaultFilter, setInput: setDefaultFilter }}>
                <Route path={path} exact component={List} />
                <Route
                    path={`${path}/:id`}
                    exact
                    render={({ match }: any) => {
                        const { id } = match.params;
                        return id === 'new' ? <Create /> : <Detail vendorId={id} />;
                    }}
                />
            </InputContext.Provider>
        </>
    );
};

import {
    CircularProgress as MuiCircularProgress,
    Grid,
    Typography,
    fade,
    Button as MuiButton,
    FormControl as MuiFormControl,
    TextField as MuiTextField,
    styled,
    Theme
} from '@material-ui/core';

const getInputStyles = (theme: Theme) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: theme.shape.borderRadius,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.light, 0.05)
        },
        '&:hover fieldset': {
            borderColor: theme.palette.divider
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.divider,
            borderWidth: '1px'
        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider
    },
    '@media(max-width: 749px)': {
        marginTop: '20px',
        maxWidth: '100%'
    }
});

export const BoxContainer = styled(Grid)({
    flexGrow: 1
});

export const Title = styled(Typography)({
    flexGrow: 1
});

export const FormControl = styled(MuiFormControl)({
    verticalAlign: 'top',
    display: 'flex',
    flexDirection: 'row',
    padding: '24px',
    justifyContent: 'space-between',
    '@media(max-width: 749px)': {
        flexDirection: 'column',
        alignItems: 'center'
    }
});

export const TextField = styled(MuiTextField)(({ theme }) => ({
    width: '100%',
    maxWidth: '25%',
    ...getInputStyles(theme)
}));

export const ReleaseTextField = styled(TextField)({
    maxWidth: '45%'
});

export const ReleaseTextArea = styled(TextField)({
    maxWidth: '100%'
});

export const StyledAutocomplete = styled(MuiTextField)(({ theme }) => getInputStyles(theme));

export const AutocompleteContainer = styled('div')({
    width: '100%',
    maxWidth: '25%',
    '@media(max-width: 749px)': {
        maxWidth: '100%'
    }
});

export const Button = styled(MuiButton)({
    width: '15%',
    '@media(max-width: 749px)': {
        marginTop: '20px',
        width: '100%',
        maxWidth: '350px'
    }
});

export const ReleaseButton = styled(Button)({
    width: '24%'
});

export const CircularProgress = styled(MuiCircularProgress)({
    width: '20px !important',
    height: '20px !important'
});

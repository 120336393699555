import React from 'react';
import {
    getNegotiation,
    getCognitoUser,
    getUser,
    getEmployees,
    updateNegotiation,
    getProductSubscriptions,
    getVendors,
    UpdateNegotiationParams
} from './api';
import { getSubscriptionHistory } from '../../BillHistory/api';
import Detail from './Detail';
import ErrorMessage from '../../../ui/ErrorMessage';

const DetailContainer = ({ userId, negotiationId }: any = {}) => {
    const [negotiation, setNegotiation] = React.useState();
    const [subscription, setSubscription] = React.useState<{ subscriptionId: any; transactions: any } | undefined>();
    const [processing, setProcessing] = React.useState<boolean | undefined>();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
    const [vendors, setVendors] = React.useState<any>();
    const [user, setUser] = React.useState<any>();

    async function fetchNegotiation() {
        setProcessing(true);

        const {
            body: { vendors: vends }
        } = await getVendors();

        setVendors(vends);

        // get negotiation
        const { status: nStatus, body: nBody } = await getNegotiation(userId, negotiationId);
        switch (nStatus) {
            case 200:
            case 404:
                break;
            default:
                setErrorMessage(`received code ${nStatus} while retrieving ${negotiationId} (${new Date().getTime()})`);
        }
        if (!nBody) {
            setProcessing(false);
            return;
        }

        // get user
        const { status: cStatus, body: cBody } = await getCognitoUser(userId);
        switch (cStatus) {
            case 200:
            case 404:
                break;
            default:
                setErrorMessage(`received code ${cStatus} while retrieving user ${userId} (${new Date().getTime()})`);
        }

        // get user
        const { status: uStatus, body: uBody = {} } = await getUser(userId);
        switch (uStatus) {
            case 200:
            case 404:
                break;
            default:
                setErrorMessage(`received code ${uStatus} while retrieving user ${userId} (${new Date().getTime()})`);
        }

        const { status: subStatus, body: subBody = [] } = await getProductSubscriptions(userId);
        switch (subStatus) {
            case 200:
            case 404:
                break;
            default:
                setErrorMessage(`received code ${uStatus} while retrieving user ${userId} (${new Date().getTime()})`);
        }

        const premiumSub =
            Array.isArray(subBody) &&
            subBody
                .filter((sub) => sub.status === 'ok')
                .sort((a, b) => {
                    //! FIXME typing issue
                    // @ts-expect-error ts migration issue
                    return new Date(b.date) - new Date(a.date);
                })[0];

        setUser({ profile: uBody, cognitoUser: cBody, premiumSub });
        // get hiatus employees (for logs)
        const { logs = [] } = nBody;
        const subs = new Set();
        logs.forEach((l: any) => {
            subs.add(l.userId);
        });
        const { status: eStatus, body: eBody } = await getEmployees(Array.from(subs));
        switch (eStatus) {
            case 200: {
                const employees = new Map(eBody.map((e: any) => [e.sub, e]));
                logs.forEach((l: any) => {
                    Object.assign(l, { user: employees.get(l.userId) || {} });
                });
                break;
            }
            default:
                setErrorMessage(`received code ${eStatus} while retrieving employee profiles`);
        }

        // set negotiation
        setNegotiation({ ...nBody });

        //  get subscription
        const { subscriptionId } = nBody;
        if (subscriptionId) {
            const { status: sStatus, body: sBody } = await getSubscriptionHistory(userId, subscriptionId);
            switch (sStatus) {
                case 200: {
                    setSubscription({
                        subscriptionId,
                        transactions: sBody.bills
                    });
                    break;
                }
                case 404:
                    break;
                default:
                    setErrorMessage(`received code ${sStatus} while retrieving ${subscriptionId} (${new Date().getTime()})`);
            }
        }

        setProcessing(false);
    }

    React.useState(() => {
        fetchNegotiation();
        //! FIXME bad signature
        // @ts-expect-error ts migration issue
    }, [negotiationId]);

    const onUpdate = async ({ status, outcome, badData }: any = {}) => {
        let apiStatus;
        let success = false;

        setProcessing(true);
        try {
            const params: UpdateNegotiationParams = { userId, negotiationId, status, outcome };
            if (Array.isArray(badData)) {
                params.badData = badData;
            }
            ({ status: apiStatus } = await updateNegotiation(params));
            if (apiStatus === 200) {
                await fetchNegotiation();
                success = true;
            } else {
                setErrorMessage(`api returned status code ${apiStatus} (${new Date().getTime()})`);
            }
        } catch (err) {
            setErrorMessage(`${err} (${new Date().getTime()})`);
        }
        setProcessing(false);
        return success;
    };

    // api defines this enum
    const results = {
        closedWirelessAccount: 'Vendor Account Closed',
        closedHiatusAccount: 'Hiatus Account Closed',
        notPremiumUser: 'Not Premium User',
        noSavings: 'No Savings',
        savings: 'Savings'
    };

    const reasons = {
        pastDue: 'Past Due',
        newVendorAccount: 'New vendor account',
        recievingPromo: 'Already receiving promo',
        vendorPolicy: 'Vendor Policy (Please include details)',
        other: 'Other (Please include details)'
    };

    return (
        <>
            <Detail
                negotiationId={negotiationId}
                negotiation={negotiation}
                subscription={subscription}
                results={results}
                reasons={reasons}
                onUpdate={onUpdate}
                processing={processing}
                vendors={vendors}
                user={user}
            />
            <ErrorMessage message={errorMessage} />
        </>
    );
};

export default DetailContainer;

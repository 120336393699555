import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    section: {
        '& h4': {
            color: 'gray',
            marginBottom: 0,
            fontWeight: 'normal'
        }
    }
}));

export default (props: any) => {
    const { children } = props;
    const classes = useStyles();
    return <div className={classes.section}>{children}</div>;
};

import React from 'react';
import moment from 'moment';
import { formatter } from '../../utils/formatter';
import Table from '../Table';

export default ({ transactions }: any = {}) => {
    return (
        <Table
            columns={[
                {
                    label: 'transacted',
                    render: (t: any) => moment(t.transactedAt).format('M/D/Y'),
                },
                {
                    label: 'amount',
                    render: (t: any) => formatter.format(t.value),
                },
            ]}
            rows={transactions.sort((a: any, b: any) => {
                return a.transactedAt > b.transactedAt ? -1 : 1;
            })}
            rowsPerPageOptions={[5, 10, 20]}
            options={{
                showSearch: false,
                showTitle: false,
                allowSorting: false,
            }}
        />
    );
};

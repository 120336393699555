import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import contentfulBtn from '../assets/contentfulPreviewBtn.png';
import { Tip } from '../../Help';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2)
    }
}));

export default () => {
    const classes = useStyles();
    return (
        <>
            <Helmet>
                <title>Hiatus Tools | Contentful</title>
            </Helmet>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} sm={12} md={9}>
                    <Paper className={classes.paper}>
                        <h1>Contentful Preview</h1>
                        <p>This module integrated with Contentful to display preview of unpublished content.</p>
                        <p>To preview a content item, you first must navigate to it in Contentful and then tap this button on the right menu:</p>
                        {
                            //! FIXME type issue
                        }
                        {/* @ts-expect-error ts migration issue */}
                        <img src={contentfulBtn} alt="Contenful preview ui" className={classes.image} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Tip>
                        <Typography variant="body2" component="p">
                            Contentful is a 3rd party tool that we use for content management. Login information is{' '}
                            <a href="https://be.contentful.com/login" target="_blank" rel="noreferrer noopener">
                                here
                            </a>
                        </Typography>
                    </Tip>
                </Grid>
            </Grid>
        </>
    );
};

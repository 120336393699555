import React from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import Html from '../../../ui/Html';

// this tool is for previewing html fields from Contentful

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    progress: {
        textAlign: 'center'
    },

    button: {
        textAlign: 'right'
    },
    container: {
        // adjust to match styles on ios, web, etc
        paddingRight: theme.spacing(3),
        fontFamily: 'Avenir',
        fontSize: '12px',
        '& h2': {
            marginTop: '40px',
            fontSize: '14px',
            fontStyle: 'bold'
        },
        '& a': {
            display: 'inline',
            color: 'darkgray',
            textDecoration: 'underline'
        },
        '& ul': {
            padding: '8px',
            backgroundColor: 'lightgray',
            borderRadius: '12px'
        },
        '& li': {
            marginLeft: '30px',
            marginRight: '30px'
        }
    }
}));

export default ({ entryId, entry, preview, refresh, processing }: any = {}) => {
    const classes = useStyles();

    const getUpdatedAt = (sys: any) => {
        return moment(sys.updatedAt).from(moment());
    };

    // render first html field in content
    let fieldId;
    const loadedEntity = entry || preview;
    if (loadedEntity) {
        fieldId = Object.keys(loadedEntity.fields).find((f) => {
            return loadedEntity.fields[f].nodeType === 'document';
        });
    }
    return (
        <>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} sm={12} md={12}>
                    <p>Contentful #{entryId}</p>
                    {loadedEntity && (
                        <>
                            <Helmet>
                                <title>Hiatus Tools | {loadedEntity.fields.name || loadedEntity.fields.title || ''} Preview</title>
                            </Helmet>
                            <h1>{loadedEntity.fields.name || loadedEntity.fields.title}</h1>
                            <p>{loadedEntity.sys.contentType.sys.id}</p>
                        </>
                    )}
                    {processing ? (
                        <div className={classes.progress}>{processing && <CircularProgress />}</div>
                    ) : (
                        <div className={classes.button}>
                            <Button onClick={refresh}>
                                <RefreshIcon />
                            </Button>
                        </div>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    {!(entry || processing) && <p>Item not found or no published version</p>}
                    {entry && !processing && (
                        <>
                            <h2>Published ({getUpdatedAt(entry.sys)})</h2>
                            <div className={classes.container}>
                                <Html html={documentToHtmlString(entry.fields[fieldId as string])} />
                            </div>
                        </>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    {preview && !processing && (
                        <>
                            <h2>Preview ({getUpdatedAt(preview.sys)})</h2>
                            <div className={classes.container}>
                                <Html html={documentToHtmlString(preview.fields[fieldId as string])} />
                            </div>
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

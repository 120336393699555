import React from 'react';
import search from './api';
import Search from './Search';
import ErrorMessage from '../../../ui/ErrorMessage';

const SearchContainer = () => {
    const [processing, setProcessing] = React.useState<boolean | undefined>();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
    const [data, setData] = React.useState<any>({
        users: [],
        paginationToken: null
    });

    const onSearch = async (email: any, paginationToken: any) => {
        let status;
        let body;
        setData({
            users: []
        });
        setProcessing(true);
        try {
            //! FIXME type issue
            // @ts-expect-error ts migration issue
            ({ status, body } = await search(email, paginationToken));
        } catch (err) {
            setErrorMessage(`${err} (${new Date().getTime()})`);
        }

        setProcessing(false);
        switch (status) {
            case 200: {
                const { users, paginationToken: newToken } = body;
                setData({
                    users,
                    paginationToken: newToken
                });
                break;
            }
            case 400: {
                // to do: why is validation msg not in body for email=%^$^%
                if (body) {
                    const [e] = body;
                    setErrorMessage(`${e.message} (${new Date().getTime()})`);
                }
                break;
            }
            default:
                setErrorMessage(`api returned status code ${status} (${new Date().getTime()})`);
        }
    };

    return (
        <>
            <Search users={data.users} onSearch={onSearch} processing={processing} />
            <ErrorMessage message={errorMessage} />
        </>
    );
};

export default SearchContainer;

import { api } from '../../../services';

const getUser = async (userId: any) => {
    const { status, body: cognitoUser } = await api.get({
        path: `hiatus/identity-users-manage/user/${userId}`,
    });
    let returnStatus = status;
    const user = cognitoUser ?? { email: userId, firstName: 'deleted', lastName: 'account' };
    const { status: profileStatus, body: profile } = await api.get({
        path: `hiatus/profile/user/${userId}/profile`,
    });
    if (profileStatus === 200) {
        Object.assign(user ?? {}, { ...profile });
        returnStatus = 200;
    }
    return { status: returnStatus, body: user };
};

const verifyEmail = (userId: any) => {
    return api.put({
        path: `hiatus/identity-users-manage/user/${userId}/verifyemail`,
    });
};

const deleteUser = (userId: any) => {
    return api.del({ path: `hiatus/identity-users-api/user/${userId}` });
};

export { getUser, verifyEmail, deleteUser };

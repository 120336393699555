import React from 'react';
import getCancellation from './api';
import Search from './Search';
import ErrorMessage from '../../../ui/ErrorMessage';
import { useFetchCancelInfoQuery } from '../../../types/gql';

const SearchContainer = () => {
    const [cancellations, setCancellations] = React.useState<any>();
    const [processing, setProcessing] = React.useState<boolean | undefined>();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

    const { refetch } = useFetchCancelInfoQuery({ skip: true });

    async function retrieveCancellation(searchId: string) {
        setCancellations([]);
        setProcessing(true);
        if (searchId) {
            const { data, error } = await refetch({ userId: searchId });
            if (error) {
                setErrorMessage(`Error fetching billing info: ${error.message}`);
                return;
            }
            setCancellations(data?.getCancellations);
            setProcessing(false);
        }
        const { status, body } = await getCancellation(searchId);
        switch (status) {
            case 200:
                setCancellations([body]);
                break;
            case 404:
                // sets to []
                break;
            default:
                setErrorMessage(`received code ${status} while retrieving ${searchId} (${new Date().getTime()})`);
        }
        setProcessing(false);
    }

    return (
        <>
            <Search onSearch={retrieveCancellation} cancellations={cancellations} processing={processing} />
            <ErrorMessage message={errorMessage} />
        </>
    );
};

export default SearchContainer;

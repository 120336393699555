import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Login } from '../components/Identity';
import Dashboard from '../components/Dashboard';

export default ({ current }: any = {}) => {
    return (
        <BrowserRouter>
            {current ? (
                <Switch>
                    <Route path="/dashboard" component={Dashboard} />
                    <Route
                        render={() => {
                            // state is a cognito calback param
                            const qs = new URLSearchParams(window.location.search);
                            const redirectTo = qs.get('state') || '/dashboard/users';
                            return (
                                <Redirect
                                    to={{
                                        pathname: redirectTo
                                    }}
                                />
                            );
                        }}
                    />
                </Switch>
            ) : (
                <Switch>
                    <Login path="/login" />
                    <Route
                        render={({ location }: any) => {
                            const { pathname } = location;
                            const onLogin = !pathname || pathname === '/' ? '' : `onLogin=${location.pathname}`;
                            return (
                                <Redirect
                                    to={{
                                        pathname: '/login',
                                        search: onLogin
                                    }}
                                />
                            );
                        }}
                    />
                </Switch>
            )}
        </BrowserRouter>
    );
};

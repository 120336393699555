import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAll } from '../actions';
import List from './List';

export default connect(
    (state: any) => {
        const { user } = state.identity.current;
        const { all } = state.vendors;
        return {
            claims: user.claims,
            vendors: all
        };
    },
    (dispatch: any) => bindActionCreators({ getAll }, dispatch)
)(List);

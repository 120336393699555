import React from 'react';
import getSampleForm from './api';
import Sample from './Sample';
import ErrorMessage from '../../../ui/ErrorMessage';

const SampleContainer = () => {
    const [form, setForm] = React.useState<string | undefined>();
    const [processing, setProcessing] = React.useState<boolean | undefined>();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

    async function getForm() {
        setForm('');
        setProcessing(true);
        const { status, body } = await getSampleForm();
        if (status === 200) {
            setForm(body);
        } else {
            setErrorMessage(`received code ${status} while retrieving sample form. (${new Date().getTime()})`);
        }
        setProcessing(false);
    }

    React.useEffect(() => {
        getForm();
    }, []);

    return (
        <>
            <Sample form={form} refresh={getForm} processing={processing} />
            <ErrorMessage message={errorMessage} />
        </>
    );
};

export default SampleContainer;

import React, { useCallback, useMemo, useState, useContext } from 'react';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { fade, makeStyles } from '@material-ui/core/styles';

import noop from 'lodash/noop';
import TablePaginationActions from './TablePaginationActions';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TableSearch from './TableSearch';
import { InputContext } from '../Vendors/context';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.divider}`,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.light, 0.05)
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '18ch',
            '&:focus': {
                width: '24ch'
            }
        }
    }
}));

const descendingComparator = (a: any, b: any, orderBy: any) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
};

const getComparator = (order: any, orderBy: any) =>
    order === 'desc' ? (a: any, b: any) => descendingComparator(a, b, orderBy) : (a: any, b: any) => -descendingComparator(a, b, orderBy);
type FilterValue = Record<string, boolean | number | string> | boolean | number | string;
const hasFilter = (value: FilterValue, filter: string) => {
    if (!value) return true;
    if (typeof value === 'object') {
        return Object.values(value).find((prop) => hasFilter(prop, filter));
    }

    if (typeof value === 'boolean' || typeof value === 'number') return value.toString() === filter;

    return value.toLowerCase().includes(filter.toLowerCase());
};

const getSortedItems = (array: any, comparator: any, filter: any) => {
    const sortedItems = array.filter((el: any) => hasFilter(el, filter)).map((el: any, index: any) => [el, index]);

    sortedItems.sort((a: any, b: any) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return sortedItems.map((el: any) => el[0]);
};
interface TableProps {
    columns: any;
    customToolbarContent?: any;
    detailPanel?: any;
    onChange?: any;
    onRowClick?: any;
    options?: any;
    rows?: any;
    rowsPerPage?: any;
    rowsPerPageOptions?: any;
    searchPlaceholder?: any;
    showLoader?: any;
    title?: any;
    defaultFilter?: string;
}
const Table = ({
    columns,
    customToolbarContent,
    detailPanel,
    onChange = noop,
    onRowClick = noop,
    options,
    rows = [],
    rowsPerPage: initialRowsPerPage,
    rowsPerPageOptions,
    searchPlaceholder,
    showLoader,
    title,
    defaultFilter = ''
}: TableProps) => {
    const classes = useStyles();
    const [filter, setFilter] = useState(defaultFilter);
    // @ts-expect-error ts migration issue TS(2554): Expected 2 arguments, but got 1.
    const config = useMemo(() => ({
        showSearch: true,
        showPagination: true,
        showTitle: true,
        allowSorting: true,
        ...options
    }));
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage || (rowsPerPageOptions && rowsPerPageOptions[0]) || 10);
    const [page, setPage] = useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');

    const sortedItems = useMemo(() => rows && getSortedItems(rows, getComparator(order, orderBy), filter), [rows, order, orderBy, filter]);
    const input = useContext(InputContext);

    const onFilterChange = useCallback(
        (event) => {
            if (page > 0) {
                setPage(0);
            }

            onChange(event);
            setFilter(event.target.value);
            if (input.setInput) {
                input.setInput(event.target.value);
            }
        },
        [onChange, page, input]
    );

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event: any, property: any): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <Paper>
            <TableContainer>
                {(config.showTitle || config.showSearch) && (
                    <Toolbar>
                        {config.showTitle && (
                            <Typography className={classes.title} variant="h6">
                                {title}
                            </Typography>
                        )}
                        {config.showSearch && (
                            <TableSearch
                                searchPlaceholder={searchPlaceholder}
                                onChange={onFilterChange}
                                value={filter}
                                classes={classes}
                                showLoader={showLoader}
                            />
                        )}
                    </Toolbar>
                )}
                {customToolbarContent}
                <MUITable>
                    <TableHead
                        detailPanel={detailPanel}
                        allowSorting={config.allowSorting}
                        columns={columns}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {sortedItems
                            .slice(
                                config.showPagination ? page * rowsPerPage : 0,
                                config.showPagination ? page * rowsPerPage + rowsPerPage : sortedItems.length
                            )
                            .map((row: any) => (
                                <TableRow
                                    row={row}
                                    key={JSON.stringify(row)}
                                    columns={columns}
                                    detailPanel={detailPanel}
                                    onRowClick={onRowClick}
                                    hasOnRowClick={onRowClick !== noop}
                                />
                            ))}
                    </TableBody>
                </MUITable>
            </TableContainer>
            {config.showPagination && (
                // @ts-expect-error peer dependency issue with mui table
                <TablePagination
                    component="div"
                    count={sortedItems.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    // onPageChange={handleChangePage}
                    onChangePage={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                />
            )}
        </Paper>
    );
};

export default Table;

import React from 'react';
import MaterialTable from 'material-table';
import { Container } from '@material-ui/core';

const validateDescriptionAndRegex = (rowData: any) => {
    if ((rowData.description && !rowData.regex) || (!rowData.description && rowData.regex)) {
        return { isValid: false, helperText: 'if description or regex is filled the other must be as well' };
    }
    return true;
};

const columns = [
    {
        title: 'Id',
        field: 'id'
    },
    { title: 'Display Name', field: 'displayName' },
    { title: 'Tool Tip Text', field: 'tooltipText' },
    { title: 'Tool Tip Url', field: 'tooltipUrl' },
    { title: 'Required', field: 'required', type: 'boolean' },
    { title: 'Description', field: 'description', validate: validateDescriptionAndRegex },
    { title: 'RegEx', field: 'regex', validate: validateDescriptionAndRegex }
];

export default ({ formFields, onChange }: any = {}) => {
    // map to form fields
    const mapped = formFields.map((f: any) => {
        const field = { id: f.id, required: false, displayName: f.displayName, tooltipText: f.tooltipText, tooltipUrl: f.tooltipUrl };
        if (f.validation) {
            const { required: req, description, regex } = f.validation;
            if (req) {
                field.required = req;
            }
            if (description) {
                //! FIXME type issue
                // @ts-expect-error ts migration issue
                field.description = description;
            }
            if (regex) {
                //! FIXME type issue
                // @ts-expect-error ts migration issue
                field.regex = regex;
            }
        }

        return field;
    });
    const [rows, setRows] = React.useState(mapped);

    const handleChange = (current: any) => {
        onChange(
            // map back to api schema
            current.map((c: any) => {
                const { id, displayName, required, description, regex, tooltipUrl, tooltipText } = c;
                return {
                    id,
                    displayName,
                    ...(tooltipUrl !== '' && { tooltipUrl }),
                    ...(tooltipText !== '' && { tooltipText }),
                    validation: {
                        required,
                        // not setting if empty string
                        ...(description !== '' && { description }),
                        ...(regex !== '' && { regex })
                    }
                };
            })
        );
    };
    const addItem = (newItem: any) => {
        const tmp = [...rows, newItem];

        setRows(tmp);
        handleChange(tmp);
    };
    const updateItem = (newItem: any, oldItem: any) => {
        const tmp = [...rows];
        const index = tmp.indexOf(oldItem);
        tmp[index] = newItem;

        setRows(tmp);
        handleChange(tmp);
    };
    const removeItem = (oldItem: any) => {
        const tmp = [...rows];
        const index = tmp.indexOf(oldItem);
        tmp.splice(index, 1);

        setRows(tmp);
        handleChange(tmp);
    };
    const getValidationErrs = (newItem: any) => {
        const errors: any = {};
        if (newItem.required === undefined) {
            errors.required = 'required field cannot be empty';
        }
        const fields = ['id'];

        fields.forEach((f) => {
            if (!newItem[f] || newItem[f] === '') {
                errors[f] = `${f} is a required field`;
            }
        });

        return Object.keys(errors).length > 0 ? errors : null;
    };

    return (
        <Container>
            <span>Common IDs: phone_number, account_number, account_pin, ssn_last_four</span>
            <MaterialTable
                title="Form Fields"
                // @ts-expect-error ts migration issue
                columns={columns}
                data={rows}
                options={{
                    search: false,
                    paging: false
                }}
                style={{
                    marginTop: '16px', // to do?
                    marginBottom: '8px'
                }}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise<void>((resolve, reject) => {
                            const tmpData = { ...newData };
                            if (tmpData.required === undefined) tmpData.required = false;
                            const validation = getValidationErrs(tmpData);
                            if (!validation) {
                                addItem(tmpData);
                                resolve();
                            } else {
                                reject(new Error('blah'));
                            }
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise<void>((resolve, reject) => {
                            const validation = getValidationErrs(newData);
                            if (!validation) {
                                updateItem(newData, oldData);
                                resolve();
                            } else {
                                reject(new Error('blah'));
                            }
                        }),
                    onRowDelete: (oldData) =>
                        new Promise<void>((resolve) => {
                            removeItem(oldData);
                            resolve();
                        })
                }}
            />
        </Container>
    );
};

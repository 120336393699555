import React from 'react'
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import CircularProgress from '@material-ui/core/CircularProgress';

const TableSearch = ({
  showLoader,
  classes,
  onChange,
  value,
  searchPlaceholder
}: any) => (
  <>
    {showLoader && <CircularProgress style={{ width: 20, height: 20 }} />}
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        onChange={onChange}
        value={value}
        placeholder={searchPlaceholder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  </>
)

export default TableSearch

import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { FORM_ERROR } from 'final-form';

import { saveVendor, uploadLogo } from './api';
import VendorForm from '../Form';
import RegexView from './RegEx';
import BillNegotiation from './BillNegotiation';
import ErrorMessage from '../../../ui/ErrorMessage';

import { useFetchCancellationConfigQuery } from '../../../types/gql';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    table: {
        minWidth: 650
    },
    box: {
        padding: theme.spacing(2),
        color: theme.palette.text.primary
    },
    tabs: {
        marginBottom: theme.spacing(2)
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    button: {},
    progress: {}
}));

export default ({ vendorId, vendorDetails, getVendor, claims }: any = {}) => {
    const classes = useStyles();
    const vendor = vendorDetails[vendorId];
    const regex = vendor && vendor.regex ? vendor.regex : [];
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const canQueryRegex = claims.act.includes('qvr');
    const refresh = () => {
        getVendor(vendorId, canQueryRegex);
    };
    useEffect(() => {
        refresh();
    }, [vendorId, getVendor]);

    const canEditVendors = claims.act.includes('uv');
    const [editOpen, setEditOpen] = useState(false);
    const onEdit = () => {
        setEditOpen(true);
    };
    const onEditCancel = () => {
        setEditOpen(false);
    };
    const onEditSave = async (values: { displayName: string; deleted: string; type: string }) => {
        const { displayName, deleted, type = '' } = values;
        try {
            const { status, body } = await saveVendor(vendor.vendorId, {
                displayName,
                deleted,
                type
            });

            // so refresh accurately reflects empty type
            vendor.type = type;

            switch (status) {
                case 200: {
                    setEditOpen(false);
                    refresh();
                    break;
                }
                case 400: {
                    const [e] = body;
                    setErrorMessage(`${e.message} (${new Date().getTime()})`);
                    break;
                }
                default:
                    setErrorMessage(`api returned status code ${status} (${new Date().getTime()})`);
            }

            return { [FORM_ERROR]: undefined };
        } catch (err) {
            return { [FORM_ERROR]: err };
        }
    };
    const onLogoSelected: ChangeEventHandler<HTMLInputElement> = async (e) => {
        if (!e.target.files) return setErrorMessage(`no file selected`);
        const file = e.target.files[0];
        try {
            const { status } = await uploadLogo(vendor.vendorId, file);

            switch (status) {
                case 200: {
                    setEditOpen(false);
                    refresh();
                    break;
                }
                default:
                    setErrorMessage(`api returned status code ${status} (${new Date().getTime()})`);
            }

            return { [FORM_ERROR]: undefined };
        } catch (err) {
            return { [FORM_ERROR]: err };
        }
    };

    const [currentTab, setcurrentTab] = useState(0);
    function handleTabChange(event: any, newValue: any) {
        setcurrentTab(newValue);
    }

    const { data, loading, error } = useFetchCancellationConfigQuery({ variables: { vendorId } });
    const cancelType = data?.getCancellationConfig.type;

    if (error && !loading) setErrorMessage(`Error fetching cancellation category: ${error.message}`);

    if (!vendor) {
        return <CircularProgress className={classes.progress} />;
    }
    return (
        <>
            <Helmet>
                <title>Hiatus Tools | {vendor.displayName}</title>
            </Helmet>
            <Grid container spacing={3} className={classes.root}>
                {!editOpen && (
                    <Grid item xs={9}>
                        <Typography variant="h4">{vendor.displayName}</Typography>
                        <img
                            width={150}
                            height={150}
                            src={`https://hiatus.delivery/accounts/vendors/${vendor.vendorId}/logo.png`}
                            alt={vendor.displayName}
                        />
                        <label htmlFor="outlined-button-logo">
                            <input
                                accept="image/png"
                                hidden
                                style={{ display: 'none' }}
                                id="outlined-button-logo"
                                type="file"
                                onChange={onLogoSelected}
                            />
                            <Button variant="outlined" component="span" className={classes.button}>
                                Upload Vendor Logo
                            </Button>
                        </label>
                        <Typography variant="subtitle1">{vendor.vendorId}</Typography>
                        <Typography variant="subtitle1">Type: {vendor.type}</Typography>
                        <Typography variant="subtitle1" color={error ? 'error' : 'initial'}>
                            {loading ? 'Loading...' : 'Cancellation Category:'} {error && !loading ? '[ ERROR ]' : cancelType}
                        </Typography>
                    </Grid>
                    // to do: once the project is reworked to primarily use apollo, move this logic into Detail.container.tsx
                    // i know it's not standard practice to leave fetching logic in Detail.tsx but apollo and redux don't work well together
                )}
                {canEditVendors && (
                    <>
                        {!editOpen && (
                            <Grid item xs={2}>
                                <Button variant="contained" className={classes.button}>
                                    <EditIcon onClick={onEdit} />
                                </Button>
                            </Grid>
                        )}
                        {editOpen && (
                            <Grid item xs={12}>
                                <VendorForm mode="edit" initial={vendor} onCancel={onEditCancel} onSave={onEditSave} />
                            </Grid>
                        )}
                        <Grid item xs={10}>
                            <Tabs value={currentTab} indicatorColor="primary" textColor="primary" onChange={handleTabChange} className={classes.tabs}>
                                <Tab label="Regular Expressions" />
                                <Tab label="Bill Negotiation" />
                            </Tabs>
                            {currentTab === 0 && (
                                <Box>
                                    <RegexView expressions={regex} vendorId={vendor.vendorId} onSave={refresh} />
                                </Box>
                            )}
                            {currentTab === 1 && (
                                <Box>
                                    <BillNegotiation vendor={vendor} onSave={refresh} />
                                </Box>
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
            <ErrorMessage message={errorMessage} />
        </>
    );
};

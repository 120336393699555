import React from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from '../../../router/utils';
import Section from '../../../ui/DataDisplay';

import History from '../../BillHistory/History';
import Update from './Update';
import Submission from './Submission';
import Logs from './Logs';
import { Tip } from '../../Help';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2)
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    sectionHead: {
        marginTop: theme.spacing(3)
    },
    progress: {
        marginTop: theme.spacing(3),
        textAlign: 'center'
    },
    helpKey: {
        fontWeight: 'bold'
    }
}));

const helpInfo = {
    requested: 'cancellation was requested',
    awaitingVendor: 'needs vendor response',
    actionRequired: 'needs user action',
    aborted: 'cancellation was aborted',
    closed: 'cancellation request was completed',
    'Already Cancelled': 'Account was already cancelled'
};

export default ({ cancellationId, cancellation, onUpdate, subscription, processing, premStuff }: any = {}) => {
    const classes = useStyles();

    const { status, userId, user, vendorId, vendor, resolution, logs } = cancellation || {};
    const { premiumSub, cognitoUser, profile } = premStuff || {};

    return (
        <>
            <Helmet>
                <title>Hiatus Tools | Cancellation {cancellationId}</title>
            </Helmet>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} sm={12} md={9}>
                    <Paper className={classes.paper}>
                        <h1 className={classes.title}>Subscription Cancellation</h1>
                        <p>{cancellationId}</p>

                        {cancellation && (
                            <>
                                {premiumSub && (
                                    <>
                                        <h4 style={{ color: 'black', fontWeight: 'bold' }}>Premium Billing</h4>
                                        <div style={{ fontWeight: 'bold' }}>Auto Renew Status: {premiumSub.billing.status}</div>
                                        {premiumSub.billing.expiration && (
                                            <div style={{ fontWeight: 'bold' }}>
                                                Expiration Date: {moment(premiumSub.billing.expiration).format('M/D/Y')}
                                            </div>
                                        )}
                                    </>
                                )}
                                {(!premiumSub || premiumSub.status !== 'ok' || profile.status === 'closed' || !cognitoUser) && (
                                    <>
                                        <h4 style={{ color: 'red', fontWeight: 'bold' }}>Not eligible for cancellation </h4>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                                            {(profile && profile.status === 'closed') || !cognitoUser ? 'Hiatus Account Closed' : ''}
                                        </span>
                                        <span style={{ color: 'red', fontWeight: 'bold', display: 'block' }}>
                                            {!premiumSub || premiumSub.status !== 'ok' ? 'Not Premium User' : ''}
                                        </span>
                                    </>
                                )}

                                <h3 className={classes.sectionHead}>Details</h3>
                                <Section>
                                    <h4>user</h4>
                                    <span>
                                        {user.displayName || user.email} | <Link to={`/dashboard/users/${userId}`}>{userId}</Link>
                                    </span>
                                    <h4>vendor</h4>
                                    <span>
                                        {vendor && vendor.displayName} | {vendorId}
                                    </span>
                                    <h4>status</h4>
                                    <span>{status}</span>
                                </Section>

                                <h3 className={classes.sectionHead}>Resolution</h3>
                                <Section>
                                    <h4>result</h4>
                                    <span>{resolution.status}</span>
                                    <h4>effective on</h4>
                                    <span>{moment(resolution.effectiveAt).format('M/D/Y')}</span>
                                    <h4>refund</h4>
                                    <span>${resolution.refund}</span>
                                </Section>

                                <h3 className={classes.sectionHead}>Update</h3>
                                <Update
                                    // to do: api enums
                                    statuses={['awaitingVendor', 'actionRequired', 'aborted', 'closed', 'previouslyClosed']}
                                    resolutionStatuses={['cancelled', 'active', 'subscriptionExpired', 'closedHiatusAccount']}
                                    onUpdate={onUpdate}
                                    processing={processing}
                                    cancellation={cancellation}
                                />

                                <h3 className={classes.sectionHead}>Submission</h3>
                                {subscription && <Submission cancellation={cancellation} submission={cancellation.submission} onUpdate={onUpdate} />}

                                <h3 className={classes.sectionHead}>Bills</h3>
                                {subscription && <History transactions={subscription.transactions || []} />}

                                <h3 className={classes.sectionHead}>History</h3>
                                <Logs logs={logs} />
                            </>
                        )}
                        {!cancellation && processing && (
                            <div className={classes.progress}>
                                <CircularProgress />
                            </div>
                        )}
                        {!cancellation && !processing && <span>No cancellation with this id exists.</span>}
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Tip>
                        <Typography variant="body2" component="h2">
                            Cancellation Statuses
                        </Typography>
                        <table>
                            <tbody>
                                {Object.entries(helpInfo).map(([key, value]) => (
                                    <tr key={key}>
                                        <td>
                                            <span className={classes.helpKey}>{key}: </span>
                                            {value}
                                        </td>
                                    </tr>
                                ))}

                                <tr>
                                    <td>
                                        <Typography variant="body2" component="h2">
                                            Short cut for Successful-
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Rep (NAME): Cancelled effective (DATE).</td>
                                </tr>

                                <tr>
                                    <td>Short cut for Previously Cancelled-</td>
                                </tr>

                                <tr>
                                    <td>Rep (NAME): Confirmed Account was cancelled previously.</td>
                                </tr>

                                <tr>
                                    <td>
                                        <Typography variant="body2" component="h2">
                                            Unable to Verify/Cancel
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Rep (NAME): Email on account is different than one provided. Unable to verify account.</td>
                                </tr>

                                <tr>
                                    <td>Rep (NAME): Name on account is different than one provided. Unable to verify account.</td>
                                </tr>

                                <tr>
                                    <td>Rep (NAME): Street Address on account is different than one provided. Unable to verify account.</td>
                                </tr>

                                <tr>
                                    <td>Rep (NAME): Account billed via 3rd Party - unable to verify or cancel account.</td>
                                </tr>

                                <tr>
                                    <td>
                                        <Typography variant="body2" component="h2">
                                            Shortcut for Unable to locate-
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Rep (NAME): Unable to locate an account.</td>
                                </tr>

                                <tr>
                                    <td>Provider short cuts - Successful outcome</td>
                                </tr>

                                <tr>
                                    <td>Been Verified - Automated system - cancelled effective today. Confirmation#:</td>
                                </tr>

                                <tr>
                                    <td>ABCMouse - Automated system - cancelled effective (DATE)</td>
                                </tr>

                                <tr>
                                    <td>Starz - Rep (NAME): </td>
                                </tr>

                                <tr>
                                    <td>
                                        Request forwarded to Cancellations dept @ Starz - user will be notified of cancellation via email on account
                                        within 72 hrs.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Tip>
                </Grid>
            </Grid>
        </>
    );
};

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ErrorMessage from '../../../ui/ErrorMessage';
import * as api from './api';
import Recurring from './Recurring';

const DataContainer = () => {
    const [processing, setProcessing] = useState<boolean>();
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {}, []);

    const onSubmit = async (userId: string) => {
        setProcessing(true);
        try {
            const { body, status } = await api.getRecurring(userId);
            if (status >= 400) {
                setErrorMessage(
                    `received code ${status} while retrieving user ${userId} (${new Date().getTime()})`
                );
                setProcessing(false);
                return [];
            }
            setProcessing(false);
            return body;
        } catch (err) {
            setErrorMessage(`${err} (${new Date().getTime()})`);
        }

        setProcessing(false);
        return [];
    };

    return (
        <>
            <ErrorMessage message={errorMessage} />
            <Recurring processing={processing} onSubmit={onSubmit} />
        </>
    );
};

export default DataContainer;

import React from 'react'
import MUITableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  unsortableSortLabel: {
    cursor: 'default',
    '&:hover, &:focus, &:active': {
      color: 'initial',
    }
  },
}));

const TableHead = ({
  detailPanel,
  columns,
  order,
  orderBy,
  onRequestSort,
  allowSorting = true
}: any) => {
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  const classes = useStyles();

  return (
    <MUITableHead>
      <TableRow>
        {detailPanel && <TableCell />}
        {columns.map((headCell: any) => <TableCell
          key={headCell.id || headCell.label}
          align={headCell.numeric ? 'right' : 'left'}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <TableSortLabel
            classes={{
              root: !allowSorting ? classes.unsortableSortLabel : null
            }}
            {...allowSorting && {
              active: orderBy === headCell.id,
              direction: orderBy === headCell.id ? order : 'asc',
              onClick: createSortHandler(headCell.id),
            }}
            {...!allowSorting && {
              hideSortIcon: true,
            }}
          >
            {headCell.label[0].toUpperCase()}{headCell.label.slice(1)}
          </TableSortLabel>
        </TableCell>)}
      </TableRow>
    </MUITableHead>
  );
}

export default TableHead
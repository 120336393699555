import { api } from '../../services';

export const actionTypes = {
    VENDORS_GETALL_REQUEST: 'VENDORS/GETALL_REQUEST',
    VENDORS_GETALL_SUCCESS: 'VENDORS/GETALL_SUCCESS',
    VENDORS_GETALL_ERROR: 'VENDORS/GETALL_ERROR',

    VENDORS_GETVENDOR_REQUEST: 'VENDORS/GETVENDOR_REQUEST',
    VENDORS_GETVENDOR_SUCCESS: 'VENDORS/GETVENDOR_SUCCESS',
    VENDORS_GETVENDOR_ERROR: 'VENDORS/GETVENDOR_ERROR'
};

export const getAll = () => async (dispatch: any) => {
    dispatch({
        type: actionTypes.VENDORS_GETALL_REQUEST
    });
    try {
        const getVendors = api.get({ path: 'hiatus/vendors/vendor' });
        const getBillNegotiation = api.get({ path: 'hiatus/subscription-vendors/v2/vendor' });

        const result = await Promise.all([getVendors, getBillNegotiation]);
        const { body: vendors } = result[0];
        const { body: billNegotiation } = result[1];
        dispatch({
            type: actionTypes.VENDORS_GETALL_SUCCESS,
            payload: {
                vendors,
                billNegotiation
            }
        });
    } catch (error) {
        dispatch({
            type: actionTypes.VENDORS_GETALL_ERROR,
            payload: { error }
        });
    }
};

export const getVendor = (id: any, includeRegex: any) => async (dispatch: any) => {
    dispatch({
        type: actionTypes.VENDORS_GETVENDOR_REQUEST
    });
    try {
        const vendorApi = api.get({ path: `hiatus/vendors/vendor/${id}` });

        // add dummy response if user is not authorized to view regex
        const regExApi = includeRegex ?
            api.get({ path: `hiatus/vendors/vendor/${id}/regex` }) : Promise.resolve({ body: null });
        const bnApi = api.get({ path: `hiatus/subscription-vendors/v2/vendor/${id}` });

        const results = await Promise.all([vendorApi, regExApi, bnApi]);
        const vendor = { ...results[0].body, regex: results[1].body, ...results[2].body };
        dispatch({
            type: actionTypes.VENDORS_GETVENDOR_SUCCESS,
            payload: {
                vendor
            }
        });
    } catch (error) {
        dispatch({
            type: actionTypes.VENDORS_GETVENDOR_ERROR,
            payload: { error }
        });
    }
};

import React from 'react';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { TextareaAutosize, styled } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    box: {
        marginBottom: theme.spacing(2),
    },
    control: {
        marginRight: theme.spacing(2),
        verticalAlign: 'top',
    },
    progress: {
        verticalAlign: 'middle',
        marginLeft: theme.spacing(1),
    },
    subSectionHead: {
        marginTop: theme.spacing(1),
    },
    updateButton: {
        marginTop: theme.spacing(1),
    },
}));

const StyledTextareaAutosize = styled(TextareaAutosize)({
    width: '100%',
    maxWidth: '100%',
    padding: '18.5px 14px',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 400,
    minWidth: '100%',
    borderColor: 'rgba(0, 0, 0, 0.23)',
});

const statusMappings = {
    awaitingVendor: 'Awaiting Vendor',
    actionRequired: 'Action Required',
    aborted: 'Request Aborted',
    closed: 'Request Closed',
    previouslyClosed: 'Already Cancelled',
};

export default ({
    statuses,
    resolutionStatuses,
    onUpdate,
    processing,
    cancellation,
}: any = {}) => {
    const classes = useStyles();
    const [data, setData] = React.useState({
        status: cancellation.status,
        resolutionStatus: cancellation.resolution.status || '',
        notes: cancellation ? cancellation.notes : '',
        effectiveAt: new Date(),
        refund: 0,
    });
    const update = async () => {
        const { status, resolutionStatus, effectiveAt, refund, notes } = data;
        const payload = { status, notes };
        if (status === 'closed') {
            // @ts-expect-error ts migration issue TS(2339): Property 'resolution' does not exist on type '{ st... Remove this comment to see the full error message
            payload.resolution = {
                status: resolutionStatus,
                effectiveAt: effectiveAt.toISOString(),
                refund,
            };
        }

        const success = await onUpdate(payload);
        if (success) {
            setData({
                status: 'awaitingVendor',
                resolutionStatus: 'cancelled',
                effectiveAt: new Date(),
                refund: 0,
                notes,
            });
        }
    };

    return (
        <>
            <Box className={classes.box}>
                <FormControl variant='outlined' className={classes.control}>
                    <InputLabel id='status-label'>status</InputLabel>
                    <Select
                        labelId='status-label'
                        label='status'
                        autoWidth
                        value={data.status}
                        disabled={processing}
                        onChange={(e) => {
                            setData({ ...data, status: e.target.value });
                        }}
                    >
                        {statuses.map((s: any) => {
                            return (
                                <MenuItem value={s} key={s}>
                                    {statusMappings[s]}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
            <Box
                className={classes.box}
                display={data.status === 'closed' ? 'block' : 'none'}
            >
                <FormControl variant='outlined' className={classes.control}>
                    <InputLabel id='resolution-status-label'>result</InputLabel>
                    <Select
                        labelId='resolution-status-label'
                        label='result'
                        value={data.resolutionStatus}
                        disabled={processing}
                        onChange={(e) => {
                            setData({
                                ...data,
                                resolutionStatus: e.target.value,
                            });
                        }}
                    >
                        {resolutionStatuses.map((s: any) => {
                            return (
                                <MenuItem value={s} key={s}>
                                    {s}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        label='effective on'
                        variant='inline'
                        value={data.effectiveAt}
                        format='MM/DD/yyyy'
                        disableToolbar
                        disabled={processing}
                        className={classes.control}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        onChange={(dt) => {
                            // @ts-expect-error ts migration issue TS(2322): Type 'MaterialUiPickersDate' is not assignable to ... Remove this comment to see the full error message
                            setData({ ...data, effectiveAt: dt });
                        }}
                    />
                </MuiPickersUtilsProvider>
                <TextField
                    label='refund'
                    type='number'
                    variant='outlined'
                    value={data.refund}
                    className={classes.control}
                    disabled={processing}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>$</InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        // @ts-expect-error ts migration issue TS(2322): Type 'string' is not assignable to type 'number'.
                        setData({ ...data, refund: e.target.value });
                    }}
                />
            </Box>
            <h4 className={classes.subSectionHead}>Notes</h4>
            <StyledTextareaAutosize
                defaultValue={cancellation.notes}
                rows={4}
                minRows={4}
                onChange={(e) => {
                    setData({ ...data, notes: e.target.value });
                }}
            />
            <Button
                variant='contained'
                color='primary'
                className={classes.updateButton}
                size='medium'
                onClick={update}
                disabled={processing}
            >
                Update
            </Button>
            {processing && (
                <CircularProgress size={24} className={classes.progress} />
            )}
        </>
    );
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import letmein from '../../../assets/let_me_in.jpg';

const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    image: {
        width: '80%',
        margin: theme.spacing(2)
    },
    title: {
        marginBottom: theme.spacing(4)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default () => {
    const classes = useStyles();
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={letmein} alt="Access Denied" className={classes.image} />
                <Typography component="h1" variant="h5" className={classes.title}>
                    Access Denied{' '}
                    <span role="img" aria-label="lock">
                        🔒
                    </span>
                </Typography>
            </div>
        </Container>
    );
};

import { api } from '../../../../services';

const saveRegex = (vendorId: any, expressions: any) => {
    return api.put({
        path: `hiatus/vendors-admin/vendor/${vendorId}/regex`,
        body: { txnRegExps: expressions }
    });
};

export default saveRegex;

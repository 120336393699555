import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: 'LemonChiffon',
        '& p': {
            paddingBottom: theme.spacing(2)
        }
    },
    title: {
        fontSize: 14
    }
}));

export default (props: any) => {
    const classes = useStyles();
    const [visible, setVisible] = React.useState(true);

    const { title = 'Help', children } = props;
    return visible ? (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom component="h5">
                    {title}
                </Typography>
                {children}
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    onClick={() => {
                        setVisible(false);
                    }}
                >
                    Dismiss
                </Button>
            </CardActions>
        </Card>
    ) : (
        <></>
    );
};

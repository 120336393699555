import React from 'react';
import { Route } from '../../router/utils';
import Search from './Search';
import Detail from './Detail';

export default ({
    path
}: any = {}) => {
    return <>
        <Route path={path} exact component={Search} />
        <Route
            path={`${path}/:id`}
            exact
            render={({
                match
            }: any) => {
                const { id } = match.params;
                return <Detail userId={id} />;
            }}
        />
    </>;
};

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const useStyles = makeStyles(theme => ({
    error: {
        backgroundColor: theme.palette.error.dark
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export default ({
    message,
    duration = 2000
}: any = {}) => {
    const classes = useStyles();
    // const [displayMsg, setDisplayMsg] = React.useState(message);
    const [closed, setClosed] = React.useState(false);

    React.useEffect(() => {
        setClosed(false);
    }, [message]);

    function onClose(event: any, reason: any) {
        if (reason === 'clickaway') {
            return;
        }
        // setDisplayMsg('');
        setClosed(true);
    }

    const open = message && !closed;

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open={open}
            autoHideDuration={duration}
            onClose={onClose}
        >
            <SnackbarContent
                className={classes.error}
                message={
                    <span className={classes.message}>
                        <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
                        {message}
                    </span>
                }
            />
        </Snackbar>
    );
};

import React from 'react';
import { Helmet } from 'react-helmet';
import { debounce } from 'throttle-debounce';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Link, useHistory } from '../../../router/utils';
import Section from '../../../ui/DataDisplay';
import { Tip } from '../../Help';
import Table from '../../Table';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    userDetails: {
        paddingLeft: theme.spacing(2)
    }
}));

export default ({ user, negotiations, onSearch, processing }: any = {}) => {
    const classes = useStyles();
    //! FIXME type issue
    // @ts-expect-error ts migration issue
    const doSearch = debounce(500, false, (text: any) => {
        if (text) {
            onSearch(text);
        }
    });

    const history = useHistory();
    return (
        <>
            <Helmet>
                <title>Hiatus Tools | Bill Negotiations</title>
            </Helmet>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} sm={12} md={9}>
                    <Table
                        title="Search Bill Negotiations"
                        columns={[
                            { label: 'id', id: 'negotiationId' },
                            {
                                label: 'vendor',
                                render: (r: any) => {
                                    return r.vendor ? r.vendor.displayName : r.vendorId;
                                }
                            },
                            { label: 'submitted', render: (r: any) => moment(r.createdAt).fromNow() },
                            { label: 'status', id: 'status' }
                        ]}
                        rows={negotiations}
                        options={{
                            showPagination: false
                        }}
                        onRowClick={(evt: any, selectedRow: any) => {
                            history.push(`/dashboard/billnegotiation/${selectedRow.userId}/${selectedRow.negotiationId}`);
                        }}
                        showLoader={processing}
                        onChange={(e: any) => doSearch(e.target.value.trim())}
                        searchPlaceholder="Find by user id"
                        customToolbarContent={
                            <>
                                <div className={classes.userDetails}>
                                    {user && (
                                        <>
                                            <h4>user</h4>
                                            <Section>
                                                <span>
                                                    {user.displayName || user.email} | <Link to={`/dashboard/users/${user.id}`}>{user.id}</Link>
                                                </span>
                                            </Section>
                                        </>
                                    )}
                                    <h4>negotiations</h4>
                                    <div style={{ textAlign: 'center' }}>{processing && <CircularProgress />}</div>
                                </div>
                            </>
                        }
                    />
                </Grid>
                {
                    //! FIXME type issue
                }
                {/* @ts-expect-error ts migration issue */}
                <Grid item x2={12} sm={12} md={3}>
                    <Tip>
                        <Typography variant="body2" component="p">
                            Use this screen to search for bill negotiations by user id.
                        </Typography>
                        <Typography variant="body2" component="p">
                            Click on a row to view that negotiation.
                        </Typography>
                    </Tip>
                </Grid>
            </Grid>
        </>
    );
};

import { api } from '../../../services';

export const saveVendor = (vendorId: any, vendor: any) => {
    return api.put({
        path: `hiatus/vendors-admin/vendor/${vendorId}`,
        body: vendor
    });
};

export const uploadLogo = async (vendorId: string, logo: File) => {
    if (logo.type !== 'image/png') throw new Error(`can only upload PNG logos`);
    const { status, body } = await api.get({
        path: `hiatus/vendors-admin/vendor/${vendorId}/upload-url`
    });
    if (!body.signedUrl) throw new Error(`unable to fetch presigned logo url: ${status} ${body}`);

    return fetch(body.signedUrl, {
        method: 'put',
        body: logo
    });
};

import React from 'react';
import { Helmet } from 'react-helmet';
import { debounce } from 'throttle-debounce';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useHistory } from '../../../router/utils';
import { Tip } from '../../Help';
import Table from '../../Table';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    }
}));

export default ({ onSearch, cancellations, processing }: any = {}) => {
    const classes = useStyles();
    const doSearch = debounce(500, (text: any) => {
        if (text) {
            onSearch(text);
        }
    });

    const history = useHistory();

    return (
        <>
            <Helmet>
                <title>Hiatus Tools | Subscription Cancellations</title>
            </Helmet>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} sm={12} md={9}>
                    <Table
                        title="Subscription Cancellations"
                        columns={[
                            { label: 'cancellationId', id: 'cancellationId' },
                            { label: 'userId', id: 'userId' },
                            { label: 'status', id: 'status' },
                            { label: 'created', id: 'createdAt', render: (r: any) => moment(r.createdAt).fromNow() },
                            { label: 'updated', id: 'updatedAt', render: (r: any) => moment(r.updatedAt).fromNow() }
                        ]}
                        rows={cancellations}
                        options={{
                            showPagination: false,
                            allowSort: false
                        }}
                        onRowClick={(evt: any, selectedRow: any) => {
                            history.push(`/dashboard/subscriptioncancel/${selectedRow.cancellationId}`);
                        }}
                        showLoader={processing}
                        onChange={(e: any) => doSearch(e.target.value.trim())}
                        searchPlaceholder="Find by cancellation or user id"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Tip>
                        <Typography variant="body2" component="p">
                            Use this screen to search for subscription cancellations by id.
                        </Typography>
                        <Typography variant="body2" component="p">
                            Click on a row to view that cancellation.
                        </Typography>
                    </Tip>
                </Grid>
            </Grid>
        </>
    );
};

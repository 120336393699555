import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Section from '../../../ui/DataDisplay';

const useStyles = makeStyles((theme) => ({
    box: {
        marginBottom: theme.spacing(2)
    },
    control: {
        marginRight: theme.spacing(2),
        verticalAlign: 'top'
    },
    progress: {
        verticalAlign: 'middle',
        marginLeft: theme.spacing(1)
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    formControlLabel: {
        marginTop: theme.spacing(3)
    }
}));

interface OutcomeState {
    status: string;
    outcome: {
        result: string;
        savings: {
            oneTime: number;
            recurring: {
                amount: number;
                periods: number;
                interval: string;
            };
        };
        notes?: string;
        reason?: string;
    };
}

export default ({ outcome = {}, results, reasons, onUpdate, processing, profile, cognitoUser, premiumSub }: any = {}) => {
    const classes = useStyles();

    function initialState(): OutcomeState {
        return {
            status: 'complete',
            outcome: {
                result: 'savings',
                savings: {
                    oneTime: 0,
                    recurring: {
                        amount: 0,
                        periods: 0,
                        interval: 'month'
                    }
                }
            }
        };
    }
    const [started, setStarted] = React.useState(false);
    const [monthsError, setMonthsError] = React.useState(false);
    const [data, setData] = React.useState<OutcomeState>(initialState());
    const [savingsConfirmationChecked, setSavingsConfirmationChecked] = React.useState(false);
    const [savingsConfirmationError, setSavingsConfirmationError] = React.useState(false);
    const [reasonError, setReasonError] = React.useState(false);
    const savingsConfirmationText =
        'Do you confirm that no alterations were made to the users plan, services, or ' +
        "equipment in accordance with the Hiatus' UA best practices?";

    const update = async () => {
        if (data.outcome.result === 'savings' && !savingsConfirmationChecked) {
            setSavingsConfirmationError(true);
            return;
        }

        if (data.outcome.result === 'noSavings' && !('reason' in data.outcome)) {
            setReasonError(true);
            return;
        }

        const success = await onUpdate(data);
        if (success) {
            setStarted(false);
            setData(initialState());
            setSavingsConfirmationChecked(false);
        }
    };

    const handleSavingsConfirmationCheck = (event: any) => {
        setSavingsConfirmationError(!event.target.checked);
        setSavingsConfirmationChecked(event.target.checked);
    };

    const { result, savings = {} } = outcome;
    const { oneTime, recurring } = savings;
    const startClicked = async () => {
        setStarted(true);
        if (!cognitoUser || profile.status === 'closed') {
            data.outcome.result = 'closedHiatusAccount';
            setData(data);
            await update();
        } else if (!premiumSub || premiumSub.status !== 'ok') {
            data.outcome.result = 'notPremiumUser';
            setData(data);
            await update();
        }
    };
    return (
        <>
            {!started && (
                <>
                    <Section>
                        {result && (
                            <>
                                <h4>result</h4>
                                <span>{results[result]}</span>
                            </>
                        )}
                        {oneTime !== undefined && (
                            <>
                                <h4>one time</h4>
                                <span>${oneTime}</span>
                            </>
                        )}
                        {recurring && (
                            <>
                                <h4>recurring</h4>
                                <span>
                                    ${recurring.amount} for {recurring.periods} months
                                </span>
                            </>
                        )}
                    </Section>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        size="medium"
                        onClick={async () => {
                            await startClicked();
                        }}
                    >
                        Start
                    </Button>
                </>
            )}
            {started && (
                <>
                    <Box className={classes.box} display={data.status === 'complete' ? 'block' : 'none'}>
                        <FormControl variant="outlined" className={classes.control}>
                            <InputLabel id="outcome-result-label">result</InputLabel>
                            <Select
                                labelId="outcome-result-label"
                                label="result"
                                value={data.outcome.result}
                                disabled={processing}
                                onChange={(e) => {
                                    if (e.target.value !== 'noSavings') {
                                        delete data.outcome.reason;
                                        delete data.outcome.notes;
                                    }
                                    setData({ ...data, outcome: { ...data.outcome, result: e.target.value as string } });
                                }}
                            >
                                {Object.keys(results).map((s) => {
                                    return (
                                        <MenuItem value={s} key={results[s]}>
                                            {results[s]}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {data.outcome.result === 'noSavings' && (
                            <>
                                <h4>Notes</h4>
                                <Box className={classes.box}>
                                    <FormControl variant="outlined" className={classes.control} style={{ minWidth: 120 }}>
                                        <InputLabel id="nosaving-reason-label">reason</InputLabel>
                                        <Select
                                            labelId="nosaving-reason-label"
                                            label="reason"
                                            value={data.outcome.reason}
                                            disabled={processing}
                                            onChange={(e) => {
                                                setReasonError(false);
                                                setData({ ...data, outcome: { ...data.outcome, reason: e.target.value as string } });
                                            }}
                                        >
                                            {Object.keys(reasons).map((s) => {
                                                return (
                                                    <MenuItem value={s} key={reasons[s]}>
                                                        {reasons[s]}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {reasonError && <FormHelperText error>The reason is required</FormHelperText>}
                                    </FormControl>
                                    <TextField
                                        label="notes"
                                        multiline
                                        variant="outlined"
                                        defaultValue={data.outcome.notes}
                                        className={classes.control}
                                        disabled={processing}
                                        onChange={(e) => {
                                            data.outcome.notes = e.target.value as string;
                                            setData(data);
                                        }}
                                    />
                                </Box>
                            </>
                        )}
                        <Box className={classes.box} display={data.outcome.result === 'savings' ? 'block' : 'none'}>
                            <h4>one time</h4>
                            <TextField
                                label="amount"
                                type="number"
                                variant="outlined"
                                defaultValue={data.outcome.savings.oneTime}
                                className={classes.control}
                                disabled={processing}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                onChange={(e) => {
                                    // !FIXME possible type conversion issue?
                                    // @ts-expect-error conversion from string to number
                                    data.outcome.savings.oneTime = e.target.value as number;
                                    setData(data);
                                }}
                            />
                            <h4>recurring</h4>
                            <TextField
                                label="amount"
                                type="number"
                                variant="outlined"
                                defaultValue={data.outcome.savings.recurring.amount}
                                className={classes.control}
                                disabled={processing}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                onChange={(e) => {
                                    // !FIXME possible type conversion issue?
                                    // @ts-expect-error conversion from string to number
                                    data.outcome.savings.recurring.amount = e.target.value;
                                    setData(data);
                                }}
                            />
                            <TextField
                                label="months"
                                type="number"
                                error={monthsError}
                                helperText={monthsError && 'Months must be a positive whole number'}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                defaultValue={data.outcome.savings.recurring.periods}
                                className={classes.control}
                                disabled={processing}
                                onChange={(e) => {
                                    const numMonths = Number.parseFloat(e.target.value);
                                    if (Number.isInteger(numMonths) && numMonths >= 0) {
                                        setMonthsError(false);
                                        // !FIXME possible type conversion issue?
                                        // @ts-expect-error string conversion to number
                                        data.outcome.savings.recurring.periods = e.target.value as number;
                                        setData(data);
                                    } else {
                                        setMonthsError(true);
                                    }
                                }}
                            />
                            <FormControlLabel
                                className={classes.formControlLabel}
                                control={<Checkbox onChange={handleSavingsConfirmationCheck} checked={savingsConfirmationChecked} />}
                                label={savingsConfirmationText}
                            />
                            {savingsConfirmationError && <FormHelperText error>You must confirm</FormHelperText>}
                        </Box>
                    </Box>
                    <Button
                        variant="contained"
                        color="inherit"
                        className={classes.button}
                        size="medium"
                        onClick={() => {
                            setStarted(false);
                            setSavingsConfirmationChecked(false);
                            setSavingsConfirmationError(false);
                        }}
                        disabled={processing}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        size="medium"
                        onClick={update}
                        disabled={processing || monthsError}
                    >
                        Done
                    </Button>
                </>
            )}
            {processing && <CircularProgress size={24} className={classes.progress} />}
        </>
    );
};

import * as api from '../../../services/api';

export const getReleaseInfo = async platform => {
    const result = await api.get({
        // want to change the path to something more restful
        path: `releases/${platform}`,
    });
    return result;
};

export const publishRelease = async (
    id: string | undefined,
    platform: 'ios' | 'android' | 'web',
    versionNumber: string,
    versionTitle: string,
    releaseNotes: string
) => {
    const result = await api.post({
        path: `releases/${platform}`,
        body: {
            platform,
            id,
            versionNumber,
            versionTitle,
            releaseNotes,
        },
    });
    return result;
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
    progress: {
        marginTop: '100px',
        textAlign: 'center'
    }
}));

export default ({
    loadFromSession,
    current,
    monitor,
    children
}: any = {}) => {
    const classes = useStyles();
    const [sessionLoaded, setSessionLoaded] = React.useState(false);

    React.useEffect(() => {
        async function fetchSession() {
            await loadFromSession();
            setSessionLoaded(true);
        }
        fetchSession();
    }, [loadFromSession]);

    React.useEffect(() => {
        if (current) {
            monitor(current);
        }
    }, [monitor, current]);

    // do not render until session load attempt has completed
    // avoid unnecessary redirect from /login to /dashboard if user has active session
    return !sessionLoaded ? (
        <div className={classes.progress}>
            <CircularProgress />
        </div>
    ) : (
        children
    );
};

/* eslint-disable import/no-named-as-default-member */
import React from 'react';
// import DescriptionIcon from '@material-ui/icons/Description';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import BallotIcon from '@material-ui/icons/Ballot';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';
import StoreIcon from '@material-ui/icons/Store';
import { Link } from '../../../router/utils';
import components from './components';

export default ({ claims = {} } = {}) => {
    const getColor = (path: any, exact: any) => {
        const current = window.location.pathname;
        const match = exact ? current === path : current.startsWith(path);
        return match ? 'primary' : 'inherit';
    };

    const navItems = {
        users: {
            ...components.users,
            title: 'Search Users',
            icon: PersonIcon,
            matchRouteExact: false,
        },
        billNegotiation: {
            ...components.billNegotiation,
            title: 'Bill Negotiation',
            icon: ReceiptIcon,
            matchRouteExact: false,
        },
        subscriptionCancellations: {
            ...components.subscriptionCancellations,
            title: 'Subscription Cancels',
            icon: CancelPresentationIcon,
            matchRouteExact: false,
        },
        content: {
            ...components.content,
            title: 'Contentful Preview',
            icon: LocalLibraryIcon,
            matchRouteExact: false,
        },
        forms: {
            ...components.forms,
            title: 'Custom Forms',
            icon: BallotIcon,
            matchRouteExact: false,
        },
        vendors: {
            ...components.vendors,
            title: 'Vendor Config',
            icon: StoreIcon,
            matchRouteExact: false,
        },
        data: {
            ...components.data,
            title: 'Test Data',
            icon: DataUsageIcon,
            matchRouteExact: true,
        },
        recurring: {
            ...components.recurring,
            title: 'Recurring Transactions',
            icon: PlaylistAdd,
            matchRouteExact: true,
        },
    };

    // only display items user has access to
    const userNavKeys = Object.keys(navItems).filter(k => {
        const { context, claim } = navItems[k].auth;
        return claims[context] && claims[context].includes(claim);
    });

    return (
        <List>
            {userNavKeys.map(k => {
                const { title, path, icon: Icon, matchRouteExact } = navItems[k];
                return (
                    <ListItem key={title} button>
                        <Link to={path}>
                            <ListItemIcon>
                                <Icon color={getColor(path, matchRouteExact)} />
                            </ListItemIcon>
                        </Link>
                        <ListItemText primary={title} />
                    </ListItem>
                );
            })}
        </List>
    );
};

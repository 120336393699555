import React from 'react';
import { getCancellation, getUser, getProfile, getCognitoUser, updateCancellation, getProductSubscriptions } from './api';
import { getSubscriptionHistory } from '../../BillHistory/api';
import Detail from './Detail';
import ErrorMessage from '../../../ui/ErrorMessage';

const DetailContainer = ({ cancellationId }: any = {}) => {
    const [cancellation, setCancellation] = React.useState();
    const [subscription, setSubscription] = React.useState();
    const [processing, setProcessing] = React.useState<boolean | undefined>();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
    const [user, setUser] = React.useState();

    async function fetchCancellation() {
        setProcessing(true);
        const { status, body: cBody } = await getCancellation(cancellationId);
        switch (status) {
            case 200: {
                const { body: uBody = {} } = await getUser(cBody.userId);
                setCancellation({ ...cBody, user: uBody });
                break;
            }
            case 404:
                break;
            default:
                setErrorMessage(`received code ${status} while retrieving ${cancellationId} (${new Date().getTime()})`);
        }
        const { userId } = cBody;
        // get user
        const { status: cStatus, body: cognitoBody } = await getCognitoUser(userId);
        switch (cStatus) {
            case 200:
            case 404:
                break;
            default:
                setErrorMessage(`received code ${cStatus} while retrieving user ${userId} (${new Date().getTime()})`);
        }

        // get user
        const { status: uStatus, body: profile = {} } = await getProfile(userId);
        switch (uStatus) {
            case 200:
            case 404:
                break;
            default:
                setErrorMessage(`received code ${uStatus} while retrieving user ${userId} (${new Date().getTime()})`);
        }
        // console.debug(profile);
        // console.debug(cognitoBody);
        const { status: subStatus, body: subBody = [] } = await getProductSubscriptions(userId);
        switch (subStatus) {
            case 200:
            case 404:
                break;
            default:
                setErrorMessage(`received code ${subStatus} while retrieving user ${userId} (${new Date().getTime()})`);
        }

        const theSub =
            Array.isArray(subBody) &&
            subBody
                .filter((sub) => sub.status === 'ok')
                .sort((a, b) => {
                    //! FIXME type issue
                    // @ts-expect-error ts migration issue
                    return new Date(b.date) - new Date(a.date);
                })[0];
        //! FIXME type issue
        // @ts-expect-error ts migration issue
        setUser({ profile, cognitoUser: cognitoBody, premiumSub: theSub });
        if (cBody && cBody.subscriptionId && cBody.userId) {
            const { status: sStatus, body: sBody } = await getSubscriptionHistory(cBody.userId, cBody.subscriptionId);
            switch (sStatus) {
                case 200: {
                    setSubscription({
                        //! FIXME type issue
                        // @ts-expect-error ts migration issue
                        subscriptionId: cBody.subscriptionId,
                        transactions: sBody.bills
                    });
                    break;
                }
                case 404:
                    setSubscription({
                        //! FIXME type issue
                        // @ts-expect-error ts migration issue
                        subscriptionId: cBody.subscriptionId,
                        transactions: []
                    });
                    break;
                default:
                    setErrorMessage(`received code ${sStatus} while retrieving ${cBody.subscriptionId} (${new Date().getTime()})`);
            }
        }
        setProcessing(false);
    }

    React.useState(() => {
        fetchCancellation();
        //! FIXME type issue
        // @ts-expect-error ts migration issue
    }, [cancellationId]);

    const onUpdate = async ({ status, resolution, notes, badData }: any = {}) => {
        let apiStatus;
        let success = false;
        setProcessing(true);
        try {
            ({ status: apiStatus } = await updateCancellation({ cancellationId, status, resolution, notes, badData }));
            if (apiStatus === 200) {
                await fetchCancellation();
                success = true;
            } else {
                setErrorMessage(`api returned status code ${apiStatus} (${new Date().getTime()})`);
            }
        } catch (err) {
            setErrorMessage(`${err} (${new Date().getTime()})`);
        }

        setProcessing(false);
        return success;
    };

    return (
        <>
            <Detail
                cancellationId={cancellationId}
                cancellation={cancellation}
                subscription={subscription}
                premStuff={user}
                onUpdate={onUpdate}
                processing={processing}
            />
            <ErrorMessage message={errorMessage} />
        </>
    );
};

export default DetailContainer;

import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, styled } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PremiumHistory from './PremiumHistory';
import ErrorMessage from '../../ui/ErrorMessage';
import { useFetchBillingInfoQuery } from '../../types/gql';

const StyledAccordionDetails = styled(AccordionDetails)({
    flexDirection: 'column'
});

const StatusText = styled('div')({
    textAlign: 'center'
});

const PremiumHistoryContainer = ({ userId }: any = {}) => {
    const { data, loading, error } = useFetchBillingInfoQuery({ variables: { userId } });
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

    if (error) setErrorMessage(`Error fetching billing info: ${error.message}`);

    return (
        <>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3>Subscription History</h3>
                </AccordionSummary>
                <StyledAccordionDetails>
                    {!data?.getProductSubscriptions.length ? (
                        <StatusText>{loading ? 'Loading Subscriptions' : 'No Subscriptions'}</StatusText>
                    ) : (
                        <PremiumHistory processing={loading} subscriptions={data?.getProductSubscriptions} />
                    )}
                </StyledAccordionDetails>
            </Accordion>
            <ErrorMessage message={errorMessage} />
        </>
    );
};

export default PremiumHistoryContainer;

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  /** Convert ISO string from dynamo data */
  ISODateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
};

export type AchAccount = {
  __typename?: 'ACHAccount';
  accountId: Scalars['ID']['output'];
  connectionStatus?: Maybe<Scalars['String']['output']>;
  currentBalance: Scalars['Float']['output'];
  mask: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Account = {
  __typename?: 'Account';
  accountId: Scalars['String']['output'];
  accountingCategory: AccountingCategory;
  connectionId: Scalars['String']['output'];
  /** @deprecated this is actually nuallable use accountsV2 query */
  currentBalance: Scalars['Float']['output'];
  institution?: Maybe<Institution>;
  institutionId: Scalars['String']['output'];
  institutionName?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  mask?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AccountStatus>;
  subtype?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export enum AccountStatus {
  actionRequired = 'actionRequired',
  closed = 'closed',
  deleted = 'deleted',
  deprecated = 'deprecated',
  initial = 'initial',
  ok = 'ok'
}

export type AccountV2 = {
  __typename?: 'AccountV2';
  accountId: Scalars['String']['output'];
  accountingCategory: AccountingCategory;
  availableBalance?: Maybe<Scalars['Float']['output']>;
  connectionId: Scalars['String']['output'];
  currentBalance?: Maybe<Scalars['Float']['output']>;
  institution?: Maybe<Institution>;
  institutionId: Scalars['String']['output'];
  institutionName?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  mask?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AccountStatus>;
  subtype?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type AccountValue = {
  __typename?: 'AccountValue';
  accountId: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export enum AccountingCategory {
  asset = 'asset',
  liability = 'liability'
}

export type AddSubscriptionInput = {
  transactionId: Scalars['String']['input'];
  vendorName: Scalars['String']['input'];
};

export type AmountByAccount = {
  __typename?: 'AmountByAccount';
  accounts?: Maybe<Array<AccountValue>>;
};

export type ApplicationForm = {
  __typename?: 'ApplicationForm';
  /** unit application form id */
  applicationFormId: Scalars['ID']['output'];
  stage?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type AssociateAppleProductSubscriptionInput = {
  receipt: Scalars['String']['input'];
};

export type AssociateGoogleProductSubscriptionInput = {
  purchaseToken: Scalars['String']['input'];
};

export type AssociateProductSubscriptionOutput = {
  __typename?: 'AssociateProductSubscriptionOutput';
  commandId: Scalars['ID']['output'];
};

/** All possible banking account types */
export enum BankingAccountType {
  checking = 'Checking',
  savings = 'Savings'
}

export type BankingCustomer = {
  __typename?: 'BankingCustomer';
  createdAt: Scalars['ISODateTime']['output'];
  source: BankingCustomerSource;
  sourceId: Scalars['ID']['output'];
  updatedAt: Scalars['ISODateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type BankingCustomerMeta = {
  __typename?: 'BankingCustomerMeta';
  customerId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type BankingCustomerSource = {
  __typename?: 'BankingCustomerSource';
  id: SourceId;
  meta: BankingCustomerMeta;
};

export type BankingPayment = {
  __typename?: 'BankingPayment';
  createdAt: Scalars['ISODateTime']['output'];
  paymentId: Scalars['ID']['output'];
  source: BankingPaymentSource;
  sourceId: Scalars['ID']['output'];
  status: PaymentStatus;
  updatedAt: Scalars['ISODateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type BankingPaymentCounterparty = {
  __typename?: 'BankingPaymentCounterparty';
  accountNumber: Scalars['String']['output'];
  accountType: BankingAccountType;
  name: Scalars['String']['output'];
  routingNumber: Scalars['String']['output'];
};

export type BankingPaymentMeta = {
  __typename?: 'BankingPaymentMeta';
  addenda?: Maybe<Scalars['String']['output']>;
  /** Amount in cents */
  amount: Scalars['Int']['output'];
  counterparty: BankingPaymentCounterparty;
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  direction: PaymentDirection;
  paymentId: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  settlementDate?: Maybe<Scalars['String']['output']>;
  status: PaymentStatus;
};

export type BankingPaymentSource = {
  __typename?: 'BankingPaymentSource';
  id: SourceId;
  meta: BankingPaymentMeta;
};

export type BankingTransaction = {
  __typename?: 'BankingTransaction';
  accountId: Scalars['ID']['output'];
  /** Amount in cents */
  amount: Scalars['Int']['output'];
  balance: Scalars['Float']['output'];
  cardLast4Digits?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISODateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  direction: TransactionDirection;
  merchantName?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<BankingTransactionPayment>;
  source: BankingTransactionSource;
  sourceId: Scalars['ID']['output'];
  summary: Scalars['String']['output'];
  transactionId: Scalars['ID']['output'];
  updatedAt: Scalars['ISODateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type BankingTransactionCounterparty = {
  __typename?: 'BankingTransactionCounterparty';
  accountNumber: Scalars['String']['output'];
  accountType: BankingAccountType;
  name: Scalars['String']['output'];
  routingNumber: Scalars['String']['output'];
};

export type BankingTransactionKey = {
  __typename?: 'BankingTransactionKey';
  createdAt: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type BankingTransactionKeyInput = {
  createdAt: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type BankingTransactionMeta = {
  __typename?: 'BankingTransactionMeta';
  accountId: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  counterparty?: Maybe<BankingTransactionCounterparty>;
  counterpartyRoutingNumber?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  secCode?: Maybe<Scalars['String']['output']>;
  traceNumber?: Maybe<Scalars['String']['output']>;
  transactionId: Scalars['String']['output'];
};

export type BankingTransactionPayment = {
  __typename?: 'BankingTransactionPayment';
  paymentId: Scalars['String']['output'];
  status: PaymentStatus;
};

export type BankingTransactionSource = {
  __typename?: 'BankingTransactionSource';
  id: SourceId;
  meta: BankingTransactionMeta;
};

export type BankingTransactionsResult = {
  __typename?: 'BankingTransactionsResult';
  lastEvaluatedKey?: Maybe<BankingTransactionKey>;
  transactions: Array<BankingTransaction>;
};

export type Bill = {
  __typename?: 'Bill';
  transactedAt: Scalars['Date']['output'];
  transaction?: Maybe<Transaction>;
  transactionId: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
  value: Scalars['Float']['output'];
};

export type BillNegotiationForm = {
  __typename?: 'BillNegotiationForm';
  fields: Array<BillNegotiationFormField>;
  vendorId: Scalars['ID']['output'];
};

export type BillNegotiationFormField = {
  __typename?: 'BillNegotiationFormField';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tooltipText?: Maybe<Scalars['String']['output']>;
  tooltipUrl?: Maybe<Scalars['String']['output']>;
  type: BillNegotiationFormFieldType;
  validation: BillNegotiationFormFieldValidation;
};

export enum BillNegotiationFormFieldType {
  boolean = 'boolean',
  date = 'date',
  number = 'number',
  string = 'string'
}

export type BillNegotiationFormFieldValidation = {
  __typename?: 'BillNegotiationFormFieldValidation';
  description?: Maybe<Scalars['String']['output']>;
  regex?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
  valid?: Maybe<Array<Scalars['String']['output']>>;
};

export type BrazeToken = {
  __typename?: 'BrazeToken';
  token: Scalars['String']['output'];
};

export type BrightBackResponse = {
  __typename?: 'BrightBackResponse';
  url?: Maybe<Scalars['String']['output']>;
  valid: Scalars['Boolean']['output'];
};

export type Budget = {
  __typename?: 'Budget';
  budgetLineId: Scalars['ID']['output'];
  categoryIds: Array<Scalars['String']['output']>;
  excludedMemos: Array<Scalars['String']['output']>;
  goal: Scalars['Float']['output'];
  memos: Array<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  period: Period;
  userId: Scalars['ID']['output'];
};

export type BudgetInput = {
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  excludedMemos?: InputMaybe<Array<Scalars['String']['input']>>;
  goal: Scalars['Float']['input'];
  memos?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  period?: InputMaybe<Period>;
};

export type BudgetQuery = {
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeMemos?: InputMaybe<Array<Scalars['String']['input']>>;
  memos?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BudgetSpend = {
  __typename?: 'BudgetSpend';
  budgetLineId: Scalars['ID']['output'];
  goal: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  period: BudgetSpendPeriod;
  spent: Scalars['Float']['output'];
};

export enum BudgetSpendPeriod {
  month = 'month',
  week = 'week',
  year = 'year'
}

export enum CalendarInterval {
  day = 'day',
  hour = 'hour',
  minute = 'minute',
  month = 'month',
  quarter = 'quarter',
  week = 'week',
  year = 'year'
}

export type CancelProductSubscriptionOutput = {
  __typename?: 'CancelProductSubscriptionOutput';
  commandId: Scalars['ID']['output'];
};

export type CancellationConfig = {
  __typename?: 'CancellationConfig';
  form?: Maybe<FormConfig>;
  instructions?: Maybe<CancellationInstructions>;
  type: CancellationConfigType;
};

export enum CancellationConfigType {
  emailPrePopulated = 'emailPrePopulated',
  emailVendor = 'emailVendor',
  instructions = 'instructions',
  notAvailable = 'notAvailable'
}

export type CancellationEmail = {
  __typename?: 'CancellationEmail';
  emailSubject?: Maybe<Scalars['String']['output']>;
  prepopulatedEmail: Scalars['String']['output'];
  vendorEmail?: Maybe<Scalars['String']['output']>;
};

export type CancellationInfo = {
  __typename?: 'CancellationInfo';
  instructions: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CancellationInstructions = {
  __typename?: 'CancellationInstructions';
  html: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Car = {
  __typename?: 'Car';
  status?: Maybe<CarStatus>;
};

export type CarInput = {
  status?: InputMaybe<CarStatus>;
};

export enum CarStatus {
  lease = 'LEASE',
  notApplicable = 'NOT_APPLICABLE',
  own = 'OWN',
  ownFinance = 'OWN_FINANCE'
}

export type CashFlow = {
  __typename?: 'CashFlow';
  months?: Maybe<Array<MonthSum>>;
  totalTransactions: Scalars['Int']['output'];
};

export type Category = {
  __typename?: 'Category';
  categoryId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CategoryResult = {
  __typename?: 'CategoryResult';
  categoryId: Scalars['ID']['output'];
  categoryName?: Maybe<Scalars['String']['output']>;
  ranges?: Maybe<Array<TimeRangeSum>>;
  sum: Scalars['Float']['output'];
  totalTransactions: Scalars['Int']['output'];
};

export type CategorySum = {
  __typename?: 'CategorySum';
  categoryId: Scalars['ID']['output'];
  categoryName?: Maybe<Scalars['String']['output']>;
  sum: Scalars['Float']['output'];
  totalTransactions: Scalars['Int']['output'];
};

export type ChargeMetadataInput = {
  negotiationId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
  vendorId: Scalars['String']['input'];
};

export enum ChargeType {
  instantNegotiation = 'instantNegotiation'
}

export type ChargebeePayment = {
  __typename?: 'ChargebeePayment';
  amount: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  invoiceId: Scalars['String']['output'];
  last4: Scalars['String']['output'];
  paymentId: Scalars['ID']['output'];
  paymentMethod: Scalars['String']['output'];
  refund?: Maybe<PaymentRefund>;
  status: Scalars['String']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export enum Claim {
  qpa = 'QPA',
  qps = 'QPS',
  upa = 'UPA',
  ups = 'UPS'
}

export type Command = {
  __typename?: 'Command';
  commandId: Scalars['ID']['output'];
  result?: Maybe<CommandResult>;
  status: CommandStatus;
  userId: Scalars['ID']['output'];
};

export type CommandResult = {
  __typename?: 'CommandResult';
  message?: Maybe<Scalars['String']['output']>;
  processedAt: Scalars['String']['output'];
  status: CommandResultStatus;
};

/** The status of the result of the command */
export enum CommandResultStatus {
  invalid = 'Invalid',
  ok = 'Ok'
}

/** The status of the command */
export enum CommandStatus {
  processed = 'Processed',
  received = 'Received'
}

export type Connection = {
  __typename?: 'Connection';
  connectionId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  institution?: Maybe<Institution>;
  institutionId: Scalars['ID']['output'];
  status?: Maybe<ConnectionStatus>;
  userId: Scalars['ID']['output'];
};

export type ConnectionCreated = {
  __typename?: 'ConnectionCreated';
  connectionId: Scalars['ID']['output'];
};

export enum ConnectionStatus {
  actionRequired = 'actionRequired',
  deleted = 'deleted',
  deprecated = 'deprecated',
  initial = 'initial',
  ok = 'ok'
}

export type ConnectionToken = {
  __typename?: 'ConnectionToken';
  public_token: Scalars['String']['output'];
};

export type Consent = {
  __typename?: 'Consent';
  dataSalesConsent: Scalars['Boolean']['output'];
};

export type CouponOffers = {
  __typename?: 'CouponOffers';
  firstOffer: ProductCoupon;
  secondOffer: ProductCoupon;
};

export type CreateCancellationEmailInput = {
  formData: Scalars['JSONObject']['input'];
  subscriptionId: Scalars['ID']['input'];
  vendorId: Scalars['String']['input'];
};

export type CreateCancellationInput = {
  data: Scalars['JSONObject']['input'];
  formId: Scalars['String']['input'];
  subscriptionId: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};

export type CreateCancellationResponse = {
  __typename?: 'CreateCancellationResponse';
  cancellation?: Maybe<SubscriptionCancellation>;
  errors?: Maybe<Array<FormValidationErrors>>;
  isValid: Scalars['Boolean']['output'];
};

export type CreateChargeInput = {
  chargeType: ChargeType;
  metadata: ChargeMetadataInput;
  paymentMethodId: Scalars['String']['input'];
};

export type CreateChargeResponse = {
  __typename?: 'CreateChargeResponse';
  chargeId: Scalars['ID']['output'];
};

export type CreateConnectionInput = {
  metadata: CreateConnectionMetadataInput;
  public_token: Scalars['String']['input'];
};

export type CreateConnectionMetadataInput = {
  accountsMetadata?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  institution?: InputMaybe<CreateConnectionMetadataInstitutionInput>;
};

export type CreateConnectionMetadataInstitutionInput = {
  institution_id?: InputMaybe<Scalars['String']['input']>;
};

/** pass subscriptionId or vendorId not both */
export type CreateNegotiationInput = {
  data: Scalars['JSONObject']['input'];
  subscriptionId?: InputMaybe<Scalars['ID']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreatePaymentAndProductSubscriptionInput = {
  achAccountId?: InputMaybe<Scalars['String']['input']>;
  bankToken?: InputMaybe<Scalars['String']['input']>;
  cardToken?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['ID']['input'];
  processor: SubscriptionProcessor;
  productId: Scalars['ID']['input'];
};

export type CreatePaymentMethodInput = {
  stripePaymentMethodId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PaymentMethodType>;
};

export type CreatePaymentMethodInputV2 = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  processor: PaymentProcessor;
  token?: InputMaybe<Scalars['String']['input']>;
  type: PaymentMethodType;
};

export type CreatePaymentMethodResponse = {
  __typename?: 'CreatePaymentMethodResponse';
  id: Scalars['ID']['output'];
};

export type CreateProductSubscriptionInput = {
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  planId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};

export type CreateProductSubscriptionOutput = {
  __typename?: 'CreateProductSubscriptionOutput';
  commandId: Scalars['ID']['output'];
  subscriptionId: Scalars['ID']['output'];
};

export type CreateSubscriptionCancellationInput = {
  form: FormInput;
  subscriptionId: Scalars['ID']['input'];
};

export type CreditCardOffer = {
  __typename?: 'CreditCardOffer';
  disclosure: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  partner: Scalars['String']['output'];
  type: OpportunityType;
  url: Scalars['String']['output'];
};

export type CreditCardOffers = {
  __typename?: 'CreditCardOffers';
  accountId: Scalars['ID']['output'];
  offers: Array<CreditCardOffer>;
  potentialEarnings: Scalars['Float']['output'];
  userId: Scalars['ID']['output'];
};

export type CustomerToken = {
  __typename?: 'CustomerToken';
  expirationDate: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type CustomerTokenVerification = {
  __typename?: 'CustomerTokenVerification';
  verificationToken: Scalars['String']['output'];
};

export type DepositAccount = {
  __typename?: 'DepositAccount';
  available: Scalars['Float']['output'];
  balance: Scalars['Float']['output'];
  createdAt: Scalars['ISODateTime']['output'];
  currency: DepositAccountCurrency;
  depositAccountId: Scalars['ID']['output'];
  hold: Scalars['Float']['output'];
  mask: Scalars['String']['output'];
  name: Scalars['String']['output'];
  source: DepositAccountSource;
  sourceId: Scalars['ID']['output'];
  status: DepositAccountStatus;
  updatedAt: Scalars['ISODateTime']['output'];
  userId: Scalars['ID']['output'];
};

/** All possible deposit account currency */
export enum DepositAccountCurrency {
  usd = 'USD'
}

export type DepositAccountMeta = {
  __typename?: 'DepositAccountMeta';
  accountId: Scalars['String']['output'];
  accountNumber: Scalars['String']['output'];
  available: Scalars['Float']['output'];
  balance: Scalars['Float']['output'];
  closeReason?: Maybe<Scalars['String']['output']>;
  currency: DepositAccountCurrency;
  depositProduct: Scalars['String']['output'];
  freezeReason?: Maybe<Scalars['String']['output']>;
  hold: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  routingNumber: Scalars['String']['output'];
  status: DepositAccountStatus;
};

export type DepositAccountSource = {
  __typename?: 'DepositAccountSource';
  id: SourceId;
  meta: DepositAccountMeta;
};

/** All possible deposit account status */
export enum DepositAccountStatus {
  closed = 'Closed',
  frozen = 'Frozen',
  open = 'Open'
}

export enum Direction {
  asc = 'asc',
  desc = 'desc'
}

export type EarningsOffer = {
  __typename?: 'EarningsOffer';
  affiliate: Scalars['String']['output'];
  /** @deprecated Identify offer by name */
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum EducationLevel {
  college = 'COLLEGE',
  graduate = 'GRADUATE',
  highSchool = 'HIGH_SCHOOL',
  other = 'OTHER'
}

export type Experiment = {
  __typename?: 'Experiment';
  assignedAt: Scalars['DateTime']['output'];
  assignmentId: Scalars['ID']['output'];
  experimentId: Scalars['ID']['output'];
  groupId: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type FieldValueInput = {
  field: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type FilterQuery = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  field?: InputMaybe<SearchField>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type FormConfig = {
  __typename?: 'FormConfig';
  html?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type FormField = {
  __typename?: 'FormField';
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Array<Scalars['String']['output']>>;
  placeholder?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  validation?: Maybe<Scalars['String']['output']>;
};

export type FormInput = {
  submissionId: Scalars['String']['input'];
};

export type FormSubmission = {
  __typename?: 'FormSubmission';
  submissionId: Scalars['String']['output'];
};

export type FormValidationErrors = {
  __typename?: 'FormValidationErrors';
  error: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FundingSource = {
  __typename?: 'FundingSource';
  accountId: Scalars['ID']['output'];
  connectionId: Scalars['ID']['output'];
  connectionStatus: Scalars['String']['output'];
  counterpartyId?: Maybe<Scalars['String']['output']>;
  currentBalance: Scalars['Float']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  mask: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum Goals {
  bigPurchase = 'BIG_PURCHASE',
  budgeting = 'BUDGETING',
  cancelSubscriptions = 'CANCEL_SUBSCRIPTIONS',
  clearViewFinances = 'CLEAR_VIEW_FINANCES',
  controlDebt = 'CONTROL_DEBT',
  eliminateBankFees = 'ELIMINATE_BANK_FEES',
  financialAdvice = 'FINANCIAL_ADVICE',
  getBestRates = 'GET_BEST_RATES',
  getRewardedForSavingMoney = 'GET_REWARDED_FOR_SAVING_MONEY',
  other = 'OTHER',
  saveMoreMoney = 'SAVE_MORE_MONEY'
}

export type HiatusSubscription = {
  __typename?: 'HiatusSubscription';
  amount: SubscriptionAmount;
  billingInterval: Scalars['String']['output'];
  bills: Array<Bill>;
  cancelConfig?: Maybe<CancellationConfig>;
  cancelInfo?: Maybe<CancellationInfo>;
  cancellation?: Maybe<SubscriptionCancellation>;
  insurancePolicy?: Maybe<InsurancePolicy>;
  lastBillDate?: Maybe<Scalars['String']['output']>;
  lastUpdateAt?: Maybe<Scalars['String']['output']>;
  nextBillDate?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Source>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionId: Scalars['ID']['output'];
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars['ID']['output']>;
  vendorType?: Maybe<Scalars['String']['output']>;
  visible: Scalars['Boolean']['output'];
};

export type HistogramQuery = {
  calendarInterval: CalendarInterval;
};

export type HistogramSum = {
  __typename?: 'HistogramSum';
  key: Scalars['String']['output'];
  sum: Scalars['Float']['output'];
  totalTransactions: Scalars['Int']['output'];
};

export type Home = {
  __typename?: 'Home';
  status?: Maybe<HomeStatus>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type HomeInput = {
  status?: InputMaybe<HomeStatus>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export enum HomeStatus {
  notApplicable = 'NOT_APPLICABLE',
  own = 'OWN',
  rent = 'RENT'
}

export type Institution = {
  __typename?: 'Institution';
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sources?: Maybe<InstitutionSource>;
  website?: Maybe<Scalars['String']['output']>;
};

export type InstitutionSource = {
  __typename?: 'InstitutionSource';
  plaid?: Maybe<Plaid>;
};

export type InsurancePolicy = {
  __typename?: 'InsurancePolicy';
  bills?: Maybe<Array<Bill>>;
  lastUpdateAt?: Maybe<Scalars['DateTime']['output']>;
  policyId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  vendorId?: Maybe<Scalars['ID']['output']>;
};

export type InsurancePolicyAndVendor = {
  __typename?: 'InsurancePolicyAndVendor';
  subscription?: Maybe<InsurancePolicy>;
  vendor?: Maybe<Vendor>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amount: Scalars['Float']['output'];
  finalizedAt: Scalars['DateTime']['output'];
  payment?: Maybe<InvoicePaymentDetails>;
  periodEnd: Scalars['DateTime']['output'];
  periodStart: Scalars['DateTime']['output'];
  plan: Scalars['String']['output'];
  status: InvoiceStatus;
};

export type InvoicePaymentDetails = {
  __typename?: 'InvoicePaymentDetails';
  last4?: Maybe<Scalars['String']['output']>;
  subtype: Scalars['String']['output'];
  type: InvoicePaymentType;
};

export enum InvoicePaymentType {
  bank = 'bank',
  card = 'card'
}

export enum InvoiceStatus {
  canceled = 'canceled',
  paid = 'paid',
  unpaid = 'unpaid'
}

export type KustomerToken = {
  __typename?: 'KustomerToken';
  token: Scalars['String']['output'];
};

export type LinkToken = {
  __typename?: 'LinkToken';
  expiration: Scalars['DateTime']['output'];
  link_token: Scalars['String']['output'];
};

export type LinkTokenOptions = {
  androidPackageName?: InputMaybe<Scalars['String']['input']>;
  connectionId?: InputMaybe<Scalars['String']['input']>;
  institutionId?: InputMaybe<Scalars['String']['input']>;
  products?: InputMaybe<Array<Scalars['String']['input']>>;
  redirectUri?: InputMaybe<Scalars['String']['input']>;
};

export type LoanOffer = {
  __typename?: 'LoanOffer';
  disclosure: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  partner: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Location = {
  __typename?: 'Location';
  city?: Maybe<Scalars['String']['output']>;
  continentCode?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Int']['output']>;
  longitude?: Maybe<Scalars['Int']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type LocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  continentCode?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Int']['input']>;
  longitude?: InputMaybe<Scalars['Int']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type MarketingEvent = UserEvent & {
  __typename?: 'MarketingEvent';
  id: Scalars['ID']['output'];
  payload: Scalars['JSON']['output'];
  userId: Scalars['String']['output'];
};

export type MonthSum = {
  __typename?: 'MonthSum';
  /** month for the sum. the day is not relevant but present so it's a proper date */
  date: Scalars['Date']['output'];
  earnings?: Maybe<Array<AccountValue>>;
  earningsTotal: Scalars['Float']['output'];
  expenses?: Maybe<Array<AccountValue>>;
  expensesTotal: Scalars['Float']['output'];
  month: Scalars['String']['output'];
  monthName: Scalars['String']['output'];
  total: Scalars['Float']['output'];
  totalTransactions: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addSubscription?: Maybe<HiatusSubscription>;
  addSubscriptionLegacy?: Maybe<HiatusSubscription>;
  applyCoupon: UpdateProductSubscriptionOutput;
  associateAppleProductSubscription: AssociateProductSubscriptionOutput;
  associateGoogleProductSubscription: AssociateProductSubscriptionOutput;
  cancelProductSubscription: CancelProductSubscriptionOutput;
  changeUsername: Scalars['Boolean']['output'];
  createApplicationForm: ApplicationForm;
  createBudget: Budget;
  createCancellation?: Maybe<SubscriptionCancellation>;
  createCancellationEmail?: Maybe<CancellationEmail>;
  /** creates cancellation with validation on the backend */
  createCancellationWithValidation?: Maybe<CreateCancellationResponse>;
  createCharge: CreateChargeResponse;
  createConnection: ConnectionCreated;
  createCustomerToken: CustomerToken;
  createCustomerTokenVerification: CustomerTokenVerification;
  createNegotiation?: Maybe<Scalars['Boolean']['output']>;
  createPaymentAndProductSubscription: CreateProductSubscriptionOutput;
  createPaymentMethod: CreatePaymentMethodResponse;
  createPaymentMethodV2: PaymentMethod;
  createProductSubscription: CreateProductSubscriptionOutput;
  /** old iframed form method. use createCancellation instead */
  createSubscriptionCancellation?: Maybe<SubscriptionCancellation>;
  deleteBudget: Scalars['Boolean']['output'];
  deleteConnection: Scalars['Boolean']['output'];
  deleteSubscription?: Maybe<HiatusSubscription>;
  deleteUser: Scalars['Boolean']['output'];
  issueRefund: Scalars['Boolean']['output'];
  savePlanInfo: PlanDataResponse;
  saveTrellisData?: Maybe<PartnerData>;
  setNotificationPreferences: Scalars['Boolean']['output'];
  setTransactionVisibility: Scalars['Boolean']['output'];
  submitResponse?: Maybe<Scalars['Boolean']['output']>;
  triggerEvent: Scalars['Boolean']['output'];
  updateBudget: Budget;
  updateCancellation?: Maybe<Scalars['Boolean']['output']>;
  updateConnection: ConnectionToken;
  updateConnectionStatus: UpdateConnectionStatusResult;
  updateNegotiation?: Maybe<Scalars['Boolean']['output']>;
  updateProductSubscription: UpdateProductSubscriptionOutput;
  updateProfile?: Maybe<Scalars['Boolean']['output']>;
  updateProfileAll?: Maybe<Scalars['Boolean']['output']>;
  updateSavingsPlan: SavingsPlan;
  updateSavingsPlanStatus: SavingsPlan;
  updateSubscription?: Maybe<HiatusSubscription>;
  withdrawal: BankingPayment;
};


export type MutationAddSubscriptionArgs = {
  input: AddSubscriptionInput;
  userId: Scalars['ID']['input'];
};


export type MutationAddSubscriptionLegacyArgs = {
  input: AddSubscriptionInput;
  userId: Scalars['ID']['input'];
};


export type MutationApplyCouponArgs = {
  couponId: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAssociateAppleProductSubscriptionArgs = {
  input: AssociateAppleProductSubscriptionInput;
  userId: Scalars['ID']['input'];
};


export type MutationAssociateGoogleProductSubscriptionArgs = {
  input: AssociateGoogleProductSubscriptionInput;
  userId: Scalars['ID']['input'];
};


export type MutationCancelProductSubscriptionArgs = {
  subscriptionId: Scalars['ID']['input'];
};


export type MutationChangeUsernameArgs = {
  newUsername: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  userPoolId: Scalars['ID']['input'];
};


export type MutationCreateApplicationFormArgs = {
  userId: Scalars['String']['input'];
};


export type MutationCreateBudgetArgs = {
  input: BudgetInput;
  userId: Scalars['ID']['input'];
};


export type MutationCreateCancellationArgs = {
  input: CreateCancellationInput;
  userId: Scalars['ID']['input'];
};


export type MutationCreateCancellationEmailArgs = {
  input: CreateCancellationEmailInput;
  userId: Scalars['ID']['input'];
};


export type MutationCreateCancellationWithValidationArgs = {
  input: CreateCancellationInput;
  userId: Scalars['ID']['input'];
};


export type MutationCreateChargeArgs = {
  input: CreateChargeInput;
  userId: Scalars['ID']['input'];
};


export type MutationCreateConnectionArgs = {
  input?: InputMaybe<CreateConnectionInput>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateCustomerTokenArgs = {
  userId: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
  verificationToken: Scalars['String']['input'];
};


export type MutationCreateCustomerTokenVerificationArgs = {
  userId: Scalars['String']['input'];
};


export type MutationCreateNegotiationArgs = {
  input: CreateNegotiationInput;
  userId: Scalars['ID']['input'];
};


export type MutationCreatePaymentAndProductSubscriptionArgs = {
  input: CreatePaymentAndProductSubscriptionInput;
  userId: Scalars['ID']['input'];
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
  userId: Scalars['ID']['input'];
};


export type MutationCreatePaymentMethodV2Args = {
  input: CreatePaymentMethodInputV2;
  userId: Scalars['ID']['input'];
};


export type MutationCreateProductSubscriptionArgs = {
  input: CreateProductSubscriptionInput;
  userId: Scalars['ID']['input'];
};


export type MutationCreateSubscriptionCancellationArgs = {
  input?: InputMaybe<CreateSubscriptionCancellationInput>;
  userId: Scalars['ID']['input'];
};


export type MutationDeleteBudgetArgs = {
  budgetLineId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDeleteConnectionArgs = {
  connectionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDeleteSubscriptionArgs = {
  subscriptionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID']['input'];
  userPoolId: Scalars['ID']['input'];
};


export type MutationIssueRefundArgs = {
  paymentId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSavePlanInfoArgs = {
  input: PlanDataInput;
  userId: Scalars['ID']['input'];
};


export type MutationSaveTrellisDataArgs = {
  connectionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationSetNotificationPreferencesArgs = {
  input: Array<NotificationPreferenceInput>;
  userId: Scalars['ID']['input'];
};


export type MutationSetTransactionVisibilityArgs = {
  input: TransactionVisibilityInput;
  userId: Scalars['ID']['input'];
};


export type MutationSubmitResponseArgs = {
  input: QuestionResponse;
  userId: Scalars['ID']['input'];
};


export type MutationTriggerEventArgs = {
  type: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUpdateBudgetArgs = {
  budgetLineId: Scalars['ID']['input'];
  input: BudgetInput;
  userId: Scalars['ID']['input'];
};


export type MutationUpdateCancellationArgs = {
  cancellationId: Scalars['ID']['input'];
  input: UpdateCancellationInput;
  userId: Scalars['ID']['input'];
};


export type MutationUpdateConnectionArgs = {
  connectionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUpdateConnectionStatusArgs = {
  connectionId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateNegotiationArgs = {
  input: UpdateNegotiationInput;
  negotiationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUpdateProductSubscriptionArgs = {
  input: UpdateProductSubscriptionInput;
  userId: Scalars['ID']['input'];
};


export type MutationUpdateProfileArgs = {
  id: Scalars['ID']['input'];
  user?: InputMaybe<UserInput>;
};


export type MutationUpdateProfileAllArgs = {
  id: Scalars['ID']['input'];
  user?: InputMaybe<UserInputAll>;
};


export type MutationUpdateSavingsPlanArgs = {
  customerToken: Scalars['String']['input'];
  input: UpdateSavingsPlanInput;
};


export type MutationUpdateSavingsPlanStatusArgs = {
  status: SavingsPlanStatus;
  userId: Scalars['String']['input'];
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
  subscriptionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationWithdrawalArgs = {
  customerToken: Scalars['String']['input'];
  input: WithdrawalInput;
};

export type Negotiation = {
  __typename?: 'Negotiation';
  badData: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  negotiationId: Scalars['ID']['output'];
  outcome?: Maybe<NegotiationOutcome>;
  status: NegotiationStatus;
  subscriptionId?: Maybe<Scalars['ID']['output']>;
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars['ID']['output']>;
};

export type NegotiationOutcome = {
  __typename?: 'NegotiationOutcome';
  negotiationEnd: Scalars['DateTime']['output'];
  result?: Maybe<OutcomeResult>;
  savings?: Maybe<OutcomeSavings>;
  transactedAt: Scalars['DateTime']['output'];
};

export enum NegotiationStatus {
  actionRequired = 'actionRequired',
  complete = 'complete',
  submitted = 'submitted'
}

export type NetWorth = {
  __typename?: 'NetWorth';
  asset: Scalars['Float']['output'];
  liability: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type NetWorthOnDate = {
  __typename?: 'NetWorthOnDate';
  date: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type NetWorthOverTime = {
  __typename?: 'NetWorthOverTime';
  historical?: Maybe<Array<NetWorthOnDate>>;
  projected?: Maybe<Array<NetWorthOnDate>>;
};

export type NotificationChannel = {
  __typename?: 'NotificationChannel';
  enabled: Scalars['Boolean']['output'];
  type: NotificationChannelType;
};

export type NotificationChannelInput = {
  enabled: Scalars['Boolean']['input'];
  type: NotificationChannelType;
};

export enum NotificationChannelType {
  email = 'email',
  push = 'push'
}

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  category: Scalars['String']['output'];
  categoryDisplayName: Scalars['String']['output'];
  channels: Array<NotificationChannel>;
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type NotificationPreferenceInput = {
  channels: Array<NotificationChannelInput>;
  id: Scalars['String']['input'];
};

export type Opportunity = {
  __typename?: 'Opportunity';
  details?: Maybe<OpportunityDetails>;
  id: Scalars['ID']['output'];
  revenue?: Maybe<OpportunityRevenue>;
  source?: Maybe<Scalars['String']['output']>;
  status: OpportunityStatus;
  type: OpportunityType;
  userId: Scalars['ID']['output'];
};

export type OpportunityDetail = {
  __typename?: 'OpportunityDetail';
  offerId: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type OpportunityDetails = OpportunityGeneric | OpportunityInsurance | OpportunityLmbDetail;

export type OpportunityGeneric = {
  __typename?: 'OpportunityGeneric';
  offerId?: Maybe<Scalars['ID']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type OpportunityInsurance = {
  __typename?: 'OpportunityInsurance';
  policyId?: Maybe<Scalars['ID']['output']>;
  subscriptionId?: Maybe<Scalars['ID']['output']>;
};

export type OpportunityLmbDetail = {
  __typename?: 'OpportunityLmbDetail';
  subscriptionId?: Maybe<Scalars['ID']['output']>;
  vendorId?: Maybe<Scalars['ID']['output']>;
};

export type OpportunityRevenue = {
  __typename?: 'OpportunityRevenue';
  conversion: Scalars['Float']['output'];
  duration: Scalars['Float']['output'];
  estimated: Scalars['Float']['output'];
  period: Scalars['String']['output'];
};

export enum OpportunityStatus {
  available = 'Available',
  inProgress = 'InProgress'
}

export enum OpportunityType {
  creditCardBalanceTransfer = 'CreditCardBalanceTransfer',
  creditCardBuildCredit = 'CreditCardBuildCredit',
  creditCardCashBack = 'CreditCardCashBack',
  creditCardGeneric = 'CreditCardGeneric',
  creditCardTravel = 'CreditCardTravel',
  insuranceSwitchAuto = 'InsuranceSwitchAuto',
  lmb = 'Lmb',
  loanOffer = 'LoanOffer',
  savingsOffer = 'SavingsOffer',
  utility = 'Utility',
  wirelessService = 'WirelessService'
}

export type OpportunityV2 = {
  __typename?: 'OpportunityV2';
  details: OpportunityDetail;
  id: Scalars['ID']['output'];
  revenue: OpportunityRevenue;
  status: Scalars['String']['output'];
  type: OpportunityType;
  userId: Scalars['String']['output'];
};

export type OpportunityV3 = {
  __typename?: 'OpportunityV3';
  details?: Maybe<OpportunityDetails>;
  id: Scalars['ID']['output'];
  revenue?: Maybe<OpportunityRevenue>;
  source?: Maybe<Scalars['String']['output']>;
  status: OpportunityStatus;
  type: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export enum OutcomeResult {
  closedHiatusAccount = 'closedHiatusAccount',
  closedWirelessAccount = 'closedWirelessAccount',
  failed = 'failed',
  noSavings = 'noSavings',
  notPremiumUser = 'notPremiumUser',
  savings = 'savings'
}

export type OutcomeSavings = {
  __typename?: 'OutcomeSavings';
  isCurrent?: Maybe<Scalars['Boolean']['output']>;
  oneTime?: Maybe<Scalars['Float']['output']>;
  recurring?: Maybe<OutcomeSavingsRecurring>;
};

export type OutcomeSavingsRecurring = {
  __typename?: 'OutcomeSavingsRecurring';
  amount?: Maybe<Scalars['Float']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  interval?: Maybe<Scalars['String']['output']>;
  periods?: Maybe<Scalars['Int']['output']>;
};

export type PageInfoV2 = {
  __typename?: 'PageInfoV2';
  cursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
};

export type PartnerData = {
  __typename?: 'PartnerData';
  source: PartnerSource;
  sourceId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export enum PartnerId {
  trellis = 'trellis'
}

export type PartnerMeta = TrellisMeta;

export type PartnerSource = {
  __typename?: 'PartnerSource';
  id: PartnerId;
  meta?: Maybe<PartnerMeta>;
};

/** All possible payment directions */
export enum PaymentDirection {
  credit = 'Credit',
  debit = 'Debit'
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  ach?: Maybe<PaymentMethodAch>;
  card?: Maybe<PaymentMethodCreditCard>;
  id: Scalars['ID']['output'];
  type: PaymentMethodType;
  userId: Scalars['ID']['output'];
};

export type PaymentMethodAch = {
  __typename?: 'PaymentMethodAch';
  bankName?: Maybe<Scalars['String']['output']>;
  display: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type PaymentMethodCreditCard = {
  __typename?: 'PaymentMethodCreditCard';
  cardBrand: PaymentMethodCreditCardBrand;
  display: Scalars['String']['output'];
  expiration: PaymentMethodCreditCardExpiration;
  status: Scalars['String']['output'];
};

/** creditcard brand for payment method */
export enum PaymentMethodCreditCardBrand {
  amex = 'Amex',
  dinersClub = 'DinersClub',
  discover = 'Discover',
  jcb = 'Jcb',
  masterCard = 'MasterCard',
  unionPay = 'UnionPay',
  unknown = 'Unknown',
  visa = 'Visa'
}

export type PaymentMethodCreditCardExpiration = {
  __typename?: 'PaymentMethodCreditCardExpiration';
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

/** type of payment method */
export enum PaymentMethodType {
  ach = 'ach',
  creditCard = 'creditCard'
}

export enum PaymentProcessor {
  chargebee = 'chargebee',
  stripe = 'stripe'
}

export type PaymentRefund = {
  __typename?: 'PaymentRefund';
  message?: Maybe<Scalars['String']['output']>;
  settleDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** All possible payment status */
export enum PaymentStatus {
  canceled = 'Canceled',
  clearing = 'Clearing',
  pending = 'Pending',
  rejected = 'Rejected',
  returned = 'Returned',
  sent = 'Sent'
}

export enum Period {
  month = 'month',
  week = 'week',
  year = 'year'
}

export type Plaid = {
  __typename?: 'Plaid';
  id?: Maybe<Scalars['ID']['output']>;
};

export type PlaidAccountInput = {
  class_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mask?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subtype?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  verification_status?: InputMaybe<Scalars['String']['input']>;
};

export type PlanDataInput = {
  data: Scalars['JSONObject']['input'];
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  vendorId: Scalars['String']['input'];
};

export type PlanDataResponse = {
  __typename?: 'PlanDataResponse';
  cancellationExceptionMessage?: Maybe<Scalars['String']['output']>;
  shouldCancel: Scalars['Boolean']['output'];
};

export type Plans = {
  __typename?: 'Plans';
  regular: Array<ProductPlan>;
};

export type Privacy = {
  __typename?: 'Privacy';
  consent: Consent;
};

export type Product = {
  __typename?: 'Product';
  description: Scalars['String']['output'];
  plans: Plans;
  productId: Scalars['ID']['output'];
};

export type ProductCoupon = {
  __typename?: 'ProductCoupon';
  couponId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  percentOff: Scalars['Int']['output'];
};

export type ProductPlan = {
  __typename?: 'ProductPlan';
  billing: ProductPlanBilling;
  name: Scalars['String']['output'];
  planId: Scalars['ID']['output'];
};

export type ProductPlanBilling = {
  __typename?: 'ProductPlanBilling';
  amount: Scalars['Float']['output'];
  interval: Scalars['String']['output'];
  trialPeriod: Scalars['Int']['output'];
};

export enum ProductProviderId {
  apple = 'apple',
  chargebee = 'chargebee',
  default = 'default'
}

export type ProductSubscription = {
  __typename?: 'ProductSubscription';
  billing: ProductSubscriptionBilling;
  createdAt: Scalars['DateTime']['output'];
  discount?: Maybe<ProductSubscriptionDiscount>;
  planId: Scalars['ID']['output'];
  productId: Scalars['ID']['output'];
  source: ProductSubscriptionSource;
  status: Scalars['ID']['output'];
  subscriptionId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type ProductSubscriptionBilling = {
  __typename?: 'ProductSubscriptionBilling';
  amount: Scalars['Float']['output'];
  expiration?: Maybe<Scalars['String']['output']>;
  interval?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type ProductSubscriptionDiscount = {
  __typename?: 'ProductSubscriptionDiscount';
  percentage?: Maybe<Scalars['Int']['output']>;
};

export type ProductSubscriptionSource = {
  __typename?: 'ProductSubscriptionSource';
  customerId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
};

export type ProductV2 = {
  __typename?: 'ProductV2';
  description: Scalars['String']['output'];
  plans: Array<ProductPlan>;
  productId: Scalars['ID']['output'];
};

export type Products = {
  __typename?: 'Products';
  name: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  accountById: AccountV2;
  /** @deprecated use accountsV2 */
  accounts: Array<Account>;
  accountsV2: Array<AccountV2>;
  achAccounts: Array<AchAccount>;
  anonymousExperiments: Array<Experiment>;
  applicationForm?: Maybe<ApplicationForm>;
  bankingCustomer?: Maybe<BankingCustomer>;
  bankingTransactions?: Maybe<BankingTransactionsResult>;
  billNegotiationForm: BillNegotiationForm;
  billingHistory: Array<Invoice>;
  brazeToken: BrazeToken;
  brightbackUrl: BrightBackResponse;
  budgetSpends?: Maybe<Array<BudgetSpend>>;
  budgets: Array<Budget>;
  cashflow?: Maybe<CashFlow>;
  categories: Array<Category>;
  chargebeePayments: Array<ChargebeePayment>;
  connection: Connection;
  connections: Array<Connection>;
  couponOffers: CouponOffers;
  creditCardOffers: CreditCardOffers;
  creditCardOpportunities: Array<OpportunityV2>;
  depositAccount?: Maybe<DepositAccount>;
  earningsOffers: Array<EarningsOffer>;
  experiments: Array<Experiment>;
  form: Scalars['String']['output'];
  fundingSources?: Maybe<Array<FundingSource>>;
  /** @deprecated use accountById */
  getAccountById: Account;
  getCancellationConfig: CancellationConfig;
  getCancellations?: Maybe<Array<SubscriptionCancellation>>;
  getCommand: Command;
  getLinkToken: LinkToken;
  getProduct: ProductV2;
  getProductSubscriptions: Array<ProductSubscription>;
  getProducts?: Maybe<Array<Products>>;
  /** @deprecated Use getProduct */
  hiatusPremium: Product;
  hiatusToken: Scalars['String']['output'];
  institutions: Array<Institution>;
  insuranceOpportunities: InsurancePolicyAndVendor;
  insurancePolicy: InsurancePolicy;
  insurancePolicyAndVendor: InsurancePolicyAndVendor;
  kustomerToken: KustomerToken;
  kycSnippet: Scalars['String']['output'];
  loanOffers: Array<LoanOffer>;
  negotiation?: Maybe<Negotiation>;
  negotiations: Array<Negotiation>;
  networth: NetWorth;
  networthOverTime: NetWorthOverTime;
  notificationPreferences: Array<NotificationPreference>;
  notificationPreferencesLink: Scalars['String']['output'];
  /** @deprecated Use `opportunitiesV2` instead. */
  opportunities: Array<Opportunity>;
  opportunitiesV2: Array<OpportunityV3>;
  partnerDatum: PartnerData;
  paymentMethods: Array<PaymentMethod>;
  payments?: Maybe<Array<BankingPayment>>;
  savingsOffers: Array<SavingsOffer>;
  savingsPlan?: Maybe<SavingsPlan>;
  serverTime: Scalars['Float']['output'];
  singlePlans: SinglePlans;
  spendingCategories?: Maybe<SpendingCategoriesResult>;
  statement: Scalars['String']['output'];
  statements?: Maybe<Array<Statement>>;
  stripeToken: StripeTokenResponse;
  subscription: HiatusSubscription;
  subscriptionHistory: SubscriptionHistory;
  /** does not return vendors with types */
  subscriptions: Array<HiatusSubscription>;
  /** returns vendors with types */
  subscriptionsV2: Array<HiatusSubscription>;
  suggest?: Maybe<Terms>;
  taxOffers: Array<TaxOffer>;
  transactionVisibility: TransactionVisibility;
  transactions?: Maybe<TransactionsResult>;
  user?: Maybe<User>;
  vendorForm: VendorForm;
  wirelessVendors: Array<Vendor>;
};


export type QueryAccountByIdArgs = {
  accountId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryAccountsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryAccountsV2Args = {
  userId: Scalars['ID']['input'];
};


export type QueryAchAccountsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryAnonymousExperimentsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryApplicationFormArgs = {
  userId: Scalars['String']['input'];
};


export type QueryBankingCustomerArgs = {
  userId: Scalars['String']['input'];
};


export type QueryBankingTransactionsArgs = {
  lastEvaluatedKey?: InputMaybe<BankingTransactionKeyInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['String']['input'];
};


export type QueryBillNegotiationFormArgs = {
  vendorId: Scalars['ID']['input'];
};


export type QueryBillingHistoryArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryBrightbackUrlArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryBudgetSpendsArgs = {
  budgetLineId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryBudgetsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryCashflowArgs = {
  months?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryChargebeePaymentsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryConnectionArgs = {
  connectionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryConnectionsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryCouponOffersArgs = {
  processor?: InputMaybe<SubscriptionProcessor>;
};


export type QueryCreditCardOffersArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type QueryCreditCardOpportunitiesArgs = {
  userId: Scalars['String']['input'];
};


export type QueryDepositAccountArgs = {
  userId: Scalars['String']['input'];
};


export type QueryEarningsOffersArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryExperimentsArgs = {
  anonymousId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryFormArgs = {
  formId: Scalars['ID']['input'];
};


export type QueryFundingSourcesArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetAccountByIdArgs = {
  accountId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryGetCancellationConfigArgs = {
  vendorId: Scalars['ID']['input'];
};


export type QueryGetCancellationsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetCommandArgs = {
  commandId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryGetLinkTokenArgs = {
  options?: InputMaybe<LinkTokenOptions>;
  userId: Scalars['ID']['input'];
};


export type QueryGetProductArgs = {
  productId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetProductSubscriptionsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetProductsArgs = {
  provider?: InputMaybe<ProductProviderId>;
};


export type QueryHiatusTokenArgs = {
  idp: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};


export type QueryInstitutionsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type QueryInsuranceOpportunitiesArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryInsurancePolicyArgs = {
  userId: Scalars['ID']['input'];
  vendorId: Scalars['ID']['input'];
};


export type QueryInsurancePolicyAndVendorArgs = {
  policyId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryKustomerTokenArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryLoanOffersArgs = {
  userId: Scalars['String']['input'];
};


export type QueryNegotiationArgs = {
  subscriptionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryNegotiationsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryNetworthArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryNetworthOverTimeArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryNotificationPreferencesArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryNotificationPreferencesLinkArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryOpportunitiesArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryOpportunitiesV2Args = {
  userId: Scalars['ID']['input'];
};


export type QueryPartnerDatumArgs = {
  partnerId: PartnerId;
  userId: Scalars['ID']['input'];
};


export type QueryPaymentMethodsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryPaymentsArgs = {
  userId: Scalars['String']['input'];
};


export type QuerySavingsOffersArgs = {
  userId: Scalars['ID']['input'];
};


export type QuerySavingsPlanArgs = {
  userId: Scalars['String']['input'];
};


export type QuerySinglePlansArgs = {
  processor?: InputMaybe<SubscriptionProcessor>;
};


export type QuerySpendingCategoriesArgs = {
  budgetQuery?: InputMaybe<BudgetQuery>;
  filter?: InputMaybe<FilterQuery>;
  ranges: Array<TimeRange>;
  userId: Scalars['ID']['input'];
};


export type QueryStatementArgs = {
  statementId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QueryStatementsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryStripeTokenArgs = {
  accountId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QuerySubscriptionArgs = {
  subscriptionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QuerySubscriptionHistoryArgs = {
  subscriptionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QuerySubscriptionsArgs = {
  userId: Scalars['ID']['input'];
};


export type QuerySubscriptionsV2Args = {
  userId: Scalars['ID']['input'];
};


export type QuerySuggestArgs = {
  query: SuggestQuery;
  userId: Scalars['ID']['input'];
};


export type QueryTaxOffersArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryTransactionVisibilityArgs = {
  transactionId: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryTransactionsArgs = {
  budgetQuery?: InputMaybe<BudgetQuery>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FilterQuery>;
  histogramQuery?: InputMaybe<HistogramQuery>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  purpose?: InputMaybe<TransactionsPurpose>;
  ranges?: InputMaybe<Array<TimeRange>>;
  sort?: InputMaybe<Sort>;
  userId: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVendorFormArgs = {
  vendorId: Scalars['ID']['input'];
};

export type QuestionResponse = {
  answer: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type SavingsOffer = {
  __typename?: 'SavingsOffer';
  apr?: Maybe<Scalars['Float']['output']>;
  apy: Scalars['Float']['output'];
  checkWriting: SavingsOfferCheckWriting;
  description?: Maybe<Scalars['String']['output']>;
  disclaimer?: Maybe<Scalars['String']['output']>;
  disclosure?: Maybe<Scalars['String']['output']>;
  effectiveAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  institution: SavingsOfferInstitution;
  name: Scalars['String']['output'];
  terms: SavingsOfferTerms;
  type: Scalars['String']['output'];
  url: SavingsOfferUrl;
};

export type SavingsOfferCheckWriting = {
  __typename?: 'SavingsOfferCheckWriting';
  included?: Maybe<Scalars['Boolean']['output']>;
};

export type SavingsOfferInstitution = {
  __typename?: 'SavingsOfferInstitution';
  imageUrl?: Maybe<Scalars['String']['output']>;
  insuredMemberInfo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type SavingsOfferTerms = {
  __typename?: 'SavingsOfferTerms';
  introductoryPeriod?: Maybe<Scalars['Int']['output']>;
  minimumBalanceToEarnAPY?: Maybe<Scalars['Float']['output']>;
  minimumDeposit?: Maybe<Scalars['Float']['output']>;
  minimumDepositToEarnAPY?: Maybe<Scalars['Float']['output']>;
  monthlyFee?: Maybe<Scalars['Float']['output']>;
  ongoingRate?: Maybe<Scalars['Float']['output']>;
};

export type SavingsOfferUrl = {
  __typename?: 'SavingsOfferUrl';
  offer: Scalars['String']['output'];
  track?: Maybe<Scalars['String']['output']>;
};

export type SavingsPlan = {
  __typename?: 'SavingsPlan';
  /** Amount in cents */
  amount: Scalars['Int']['output'];
  counterpartyId: Scalars['String']['output'];
  createdAt: Scalars['ISODateTime']['output'];
  /** Unit Deposit Account Id */
  depositAccountId: Scalars['String']['output'];
  /** hiatus account Id */
  hiatusAccountId: Scalars['String']['output'];
  lastDepositDate?: Maybe<Scalars['ISODateTime']['output']>;
  nextDepositDate: Scalars['ISODateTime']['output'];
  /** Reason for failure */
  reason?: Maybe<Scalars['String']['output']>;
  sourceMask: Scalars['String']['output'];
  status: SavingsPlanStatus;
  timeInterval: TimeInterval;
  updatedAt: Scalars['ISODateTime']['output'];
  userId: Scalars['ID']['output'];
};

export enum SavingsPlanStatus {
  active = 'active',
  closed = 'closed',
  paused = 'paused'
}

export enum SearchField {
  accountId = 'accountId',
  categoryId = 'categoryId',
  connectionId = 'connectionId',
  memo = 'memo',
  memoByWord = 'memoByWord',
  transactionId = 'transactionId',
  vendorId = 'vendorId'
}

export type SinglePlans = {
  __typename?: 'SinglePlans';
  monthly: ProductPlan;
  yearly: ProductPlan;
};

export type Sort = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<TransactionSortField>;
};

export type Source = {
  __typename?: 'Source';
  id: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  vendorName?: Maybe<Scalars['String']['output']>;
};

/** All possible banking source ids */
export enum SourceId {
  unit = 'unit'
}

export type SpendingCategoriesResult = {
  __typename?: 'SpendingCategoriesResult';
  categories?: Maybe<Array<CategoryResult>>;
  everything: CategoryResult;
  ranges?: Maybe<Array<TimeRangeResult>>;
};


export type SpendingCategoriesResultRangesArgs = {
  ranges?: InputMaybe<Array<TimeRange>>;
};

export type Statement = {
  __typename?: 'Statement';
  /** unit account id */
  accountId: Scalars['String']['output'];
  /** unit customer id */
  customerId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  period: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type StripeTokenResponse = {
  __typename?: 'StripeTokenResponse';
  status: Scalars['String']['output'];
  stripeBankAccountToken?: Maybe<Scalars['String']['output']>;
};

export type SubAccountInput = {
  type: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  userEvents: UserEvent;
};

export type SubscriptionAmount = {
  __typename?: 'SubscriptionAmount';
  /** @deprecated Use billingInterval */
  period?: Maybe<SubscriptionPeriod>;
  value: Scalars['Float']['output'];
};

export type SubscriptionCancellation = {
  __typename?: 'SubscriptionCancellation';
  badData?: Maybe<Array<Scalars['String']['output']>>;
  cancellationId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  form?: Maybe<FormSubmission>;
  resolution: SubscriptionCancellationResolution;
  status: Scalars['String']['output'];
  subscriptionId: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
  vendorId: Scalars['ID']['output'];
};

export type SubscriptionCancellationResolution = {
  __typename?: 'SubscriptionCancellationResolution';
  effectiveAt?: Maybe<Scalars['String']['output']>;
  refund?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionHistory = {
  __typename?: 'SubscriptionHistory';
  bills: Array<Bill>;
};


export type SubscriptionHistoryBillsArgs = {
  months?: InputMaybe<Scalars['Int']['input']>;
};

export enum SubscriptionPeriod {
  biweekly = 'biweekly',
  monthly = 'monthly',
  quarterly = 'quarterly',
  semiannually = 'semiannually',
  unknown = 'unknown',
  weekly = 'weekly',
  yearly = 'yearly'
}

export enum SubscriptionProcessor {
  chargebee = 'chargebee',
  stripe = 'stripe'
}

export enum SubscriptionStatus {
  active = 'active',
  inactive = 'inactive'
}

export type SuggestQuery = {
  text: Scalars['String']['input'];
  top?: InputMaybe<Scalars['Int']['input']>;
};

export type TaxOffer = {
  __typename?: 'TaxOffer';
  affiliate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Terms = {
  __typename?: 'Terms';
  terms?: Maybe<Array<Scalars['String']['output']>>;
};

export enum TimeInterval {
  biweekly = 'biweekly',
  monthly = 'monthly',
  weekly = 'weekly'
}

export enum TimeRange {
  last7Days = 'last7Days',
  last30Days = 'last30Days',
  last365Days = 'last365Days',
  lastMonth = 'lastMonth',
  lastMonthToDate = 'lastMonthToDate',
  lastWeek = 'lastWeek',
  lastWeekToDate = 'lastWeekToDate',
  lastYear = 'lastYear',
  lastYearToDate = 'lastYearToDate',
  thisMonth = 'thisMonth',
  thisWeek = 'thisWeek',
  thisYear = 'thisYear'
}

export type TimeRangeResult = {
  __typename?: 'TimeRangeResult';
  categories?: Maybe<Array<CategorySum>>;
  gained?: Maybe<Scalars['Float']['output']>;
  spent?: Maybe<Scalars['Float']['output']>;
  sum: Scalars['Float']['output'];
  timeRange: TimeRange;
  totalTransactions: Scalars['Int']['output'];
};

export type TimeRangeSum = {
  __typename?: 'TimeRangeSum';
  neg: Scalars['Float']['output'];
  pos: Scalars['Float']['output'];
  sum: Scalars['Float']['output'];
  timeRange: TimeRange;
  totalTransactions: Scalars['Int']['output'];
};

export type Transaction = {
  __typename?: 'Transaction';
  account?: Maybe<Account>;
  accountId: Scalars['String']['output'];
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['String']['output']>;
  connectionId: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  transactedAt: Scalars['Date']['output'];
  transactionId: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  value: Scalars['Float']['output'];
  vendorId?: Maybe<Scalars['String']['output']>;
};

export enum TransactionDirection {
  credit = 'Credit',
  debit = 'Debit'
}

export enum TransactionSortField {
  transactedAt = 'transactedAt',
  value = 'value'
}

export enum TransactionVisibility {
  hidden = 'hidden',
  shown = 'shown'
}

export type TransactionVisibilityInput = {
  transactionId: Scalars['String']['input'];
  visibility: TransactionVisibility;
};

export enum TransactionsPurpose {
  cashflow = 'cashflow',
  transactions = 'transactions'
}

export type TransactionsResult = {
  __typename?: 'TransactionsResult';
  histogram?: Maybe<Array<HistogramSum>>;
  largest?: Maybe<Transaction>;
  pageInfo?: Maybe<PageInfoV2>;
  ranges?: Maybe<Array<TimeRangeSum>>;
  totalTransactions?: Maybe<Scalars['Int']['output']>;
  transactions?: Maybe<Array<Transaction>>;
};

export type TrellisMeta = {
  __typename?: 'TrellisMeta';
  connectionId: Scalars['String']['output'];
};

export type UpdateCancellationInput = {
  fieldValues?: InputMaybe<Array<InputMaybe<FieldValueInput>>>;
};

export type UpdateConnectionStatusResult = {
  __typename?: 'UpdateConnectionStatusResult';
  status: ConnectionStatus;
};

export type UpdateNegotiationInput = {
  data: Scalars['JSONObject']['input'];
};

export type UpdateProductSubscriptionInput = {
  planId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};

export type UpdateProductSubscriptionOutput = {
  __typename?: 'UpdateProductSubscriptionOutput';
  commandId: Scalars['ID']['output'];
  subscriptionId: Scalars['ID']['output'];
};

export type UpdateSavingsPlanInput = {
  /** hiatus accountId */
  accountId: Scalars['String']['input'];
  /** Amount in cents */
  amount: Scalars['Int']['input'];
  /** hiatus connectionId */
  connectionId: Scalars['String']['input'];
  status: SavingsPlanStatus;
  timeInterval: TimeInterval;
  userId: Scalars['String']['input'];
};

export type UpdateSubscriptionInput = {
  vendorName?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type User = {
  __typename?: 'User';
  anonymousId?: Maybe<Scalars['ID']['output']>;
  birthdate?: Maybe<Scalars['Date']['output']>;
  car?: Maybe<Car>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  education?: Maybe<EducationLevel>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  goals?: Maybe<Array<Goals>>;
  home?: Maybe<Home>;
  id: Scalars['ID']['output'];
  income?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  meta?: Maybe<UserMetadata>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  privacy: Privacy;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  wirelessVendor?: Maybe<Scalars['String']['output']>;
};

export type UserEvent = {
  id: Scalars['ID']['output'];
  userId: Scalars['String']['output'];
};

export type UserInput = {
  anonymousId?: InputMaybe<Scalars['ID']['input']>;
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  car?: InputMaybe<CarInput>;
  education?: InputMaybe<EducationLevel>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  goals?: InputMaybe<Array<Goals>>;
  home?: InputMaybe<HomeInput>;
  income?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  wirelessVendor?: InputMaybe<Scalars['String']['input']>;
};

export type UserInputAll = {
  anonymousId?: InputMaybe<Scalars['ID']['input']>;
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  car?: InputMaybe<CarInput>;
  education?: InputMaybe<EducationLevel>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  goals?: InputMaybe<Array<Goals>>;
  home?: InputMaybe<HomeInput>;
  income?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  wirelessVendor?: InputMaybe<Scalars['String']['input']>;
};

export type UserMetadata = {
  __typename?: 'UserMetadata';
  lastOpen?: Maybe<Scalars['DateTime']['output']>;
};

export type Vendor = {
  __typename?: 'Vendor';
  displayName: Scalars['String']['output'];
  images?: Maybe<VendorImages>;
  statistics?: Maybe<Array<VendorStatistics>>;
  vendorId: Scalars['ID']['output'];
};


export type VendorStatisticsArgs = {
  months?: InputMaybe<Scalars['Int']['input']>;
};

export type VendorForm = {
  __typename?: 'VendorForm';
  formId: Scalars['String']['output'];
  formJson: Array<FormField>;
  vendorId: Scalars['ID']['output'];
  version: Scalars['Int']['output'];
};

export type VendorImages = {
  __typename?: 'VendorImages';
  logo?: Maybe<VendorLogo>;
};

export type VendorLogo = {
  __typename?: 'VendorLogo';
  url: Scalars['String']['output'];
};

export type VendorStatistics = {
  __typename?: 'VendorStatistics';
  avg?: Maybe<Scalars['Float']['output']>;
  date: Scalars['Date']['output'];
};

export type WithdrawalInput = {
  /** hiatus accountId */
  accountId: Scalars['String']['input'];
  /** Amount in cents */
  amount: Scalars['Int']['input'];
  /** hiatus connectionId */
  connectionId: Scalars['String']['input'];
  /** generated identifier for withdrawal */
  idempotencyKey: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type FetchChargebeePaymentsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type FetchChargebeePaymentsQuery = { __typename?: 'Query', chargebeePayments: Array<{ __typename?: 'ChargebeePayment', paymentId: string, last4: string, amount: number, date: string, status: string, paymentMethod: string, type: string, refund?: { __typename?: 'PaymentRefund', status?: string | null, settleDate?: string | null } | null }> };

export type RefundChargebeePaymentMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  paymentId: Scalars['ID']['input'];
}>;


export type RefundChargebeePaymentMutation = { __typename?: 'Mutation', issueRefund: boolean };

export type FetchBillingInfoQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type FetchBillingInfoQuery = { __typename?: 'Query', getProductSubscriptions: Array<{ __typename?: 'ProductSubscription', subscriptionId: string, planId: string, status: string, productId: string, billing: { __typename?: 'ProductSubscriptionBilling', status: string, expiration?: string | null }, source: { __typename?: 'ProductSubscriptionSource', id: string, status?: string | null, link?: string | null } }> };

export type FetchCancelInfoQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type FetchCancelInfoQuery = { __typename?: 'Query', getCancellations?: Array<{ __typename?: 'SubscriptionCancellation', cancellationId: string, userId: string, status: string, createdAt?: string | null, updatedAt: string }> | null };

export type CancelProductSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
}>;


export type CancelProductSubscriptionMutation = { __typename?: 'Mutation', cancelProductSubscription: { __typename?: 'CancelProductSubscriptionOutput', commandId: string } };

export type FetchProductSubscriptionQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type FetchProductSubscriptionQuery = { __typename?: 'Query', getProductSubscriptions: Array<{ __typename?: 'ProductSubscription', subscriptionId: string, planId: string, status: string, productId: string, billing: { __typename?: 'ProductSubscriptionBilling', status: string, expiration?: string | null }, source: { __typename?: 'ProductSubscriptionSource', id: string } }> };

export type GetCommandQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  commandId: Scalars['ID']['input'];
}>;


export type GetCommandQuery = { __typename?: 'Query', getCommand: { __typename?: 'Command', commandId: string, status: CommandStatus, result?: { __typename?: 'CommandResult', status: CommandResultStatus, processedAt: string } | null } };

export type FetchCancellationConfigQueryVariables = Exact<{
  vendorId: Scalars['ID']['input'];
}>;


export type FetchCancellationConfigQuery = { __typename?: 'Query', getCancellationConfig: { __typename?: 'CancellationConfig', type: CancellationConfigType } };


export const FetchChargebeePaymentsDocument = gql`
    query FetchChargebeePayments($userId: ID!) {
  chargebeePayments(userId: $userId) {
    paymentId
    last4
    amount
    date
    status
    paymentMethod
    type
    refund {
      status
      settleDate
    }
  }
}
    `;

/**
 * __useFetchChargebeePaymentsQuery__
 *
 * To run a query within a React component, call `useFetchChargebeePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchChargebeePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchChargebeePaymentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFetchChargebeePaymentsQuery(baseOptions: Apollo.QueryHookOptions<FetchChargebeePaymentsQuery, FetchChargebeePaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchChargebeePaymentsQuery, FetchChargebeePaymentsQueryVariables>(FetchChargebeePaymentsDocument, options);
      }
export function useFetchChargebeePaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchChargebeePaymentsQuery, FetchChargebeePaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchChargebeePaymentsQuery, FetchChargebeePaymentsQueryVariables>(FetchChargebeePaymentsDocument, options);
        }
export type FetchChargebeePaymentsQueryHookResult = ReturnType<typeof useFetchChargebeePaymentsQuery>;
export type FetchChargebeePaymentsLazyQueryHookResult = ReturnType<typeof useFetchChargebeePaymentsLazyQuery>;
export type FetchChargebeePaymentsQueryResult = Apollo.QueryResult<FetchChargebeePaymentsQuery, FetchChargebeePaymentsQueryVariables>;
export const RefundChargebeePaymentDocument = gql`
    mutation RefundChargebeePayment($userId: ID!, $paymentId: ID!) {
  issueRefund(userId: $userId, paymentId: $paymentId)
}
    `;
export type RefundChargebeePaymentMutationFn = Apollo.MutationFunction<RefundChargebeePaymentMutation, RefundChargebeePaymentMutationVariables>;

/**
 * __useRefundChargebeePaymentMutation__
 *
 * To run a mutation, you first call `useRefundChargebeePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundChargebeePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundChargebeePaymentMutation, { data, loading, error }] = useRefundChargebeePaymentMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useRefundChargebeePaymentMutation(baseOptions?: Apollo.MutationHookOptions<RefundChargebeePaymentMutation, RefundChargebeePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundChargebeePaymentMutation, RefundChargebeePaymentMutationVariables>(RefundChargebeePaymentDocument, options);
      }
export type RefundChargebeePaymentMutationHookResult = ReturnType<typeof useRefundChargebeePaymentMutation>;
export type RefundChargebeePaymentMutationResult = Apollo.MutationResult<RefundChargebeePaymentMutation>;
export type RefundChargebeePaymentMutationOptions = Apollo.BaseMutationOptions<RefundChargebeePaymentMutation, RefundChargebeePaymentMutationVariables>;
export const FetchBillingInfoDocument = gql`
    query FetchBillingInfo($userId: ID!) {
  getProductSubscriptions(userId: $userId) {
    subscriptionId
    planId
    status
    productId
    billing {
      status
      expiration
    }
    source {
      id
      status
      link
    }
  }
}
    `;

/**
 * __useFetchBillingInfoQuery__
 *
 * To run a query within a React component, call `useFetchBillingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBillingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBillingInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFetchBillingInfoQuery(baseOptions: Apollo.QueryHookOptions<FetchBillingInfoQuery, FetchBillingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchBillingInfoQuery, FetchBillingInfoQueryVariables>(FetchBillingInfoDocument, options);
      }
export function useFetchBillingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchBillingInfoQuery, FetchBillingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchBillingInfoQuery, FetchBillingInfoQueryVariables>(FetchBillingInfoDocument, options);
        }
export type FetchBillingInfoQueryHookResult = ReturnType<typeof useFetchBillingInfoQuery>;
export type FetchBillingInfoLazyQueryHookResult = ReturnType<typeof useFetchBillingInfoLazyQuery>;
export type FetchBillingInfoQueryResult = Apollo.QueryResult<FetchBillingInfoQuery, FetchBillingInfoQueryVariables>;
export const FetchCancelInfoDocument = gql`
    query FetchCancelInfo($userId: ID!) {
  getCancellations(userId: $userId) {
    cancellationId
    userId
    status
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFetchCancelInfoQuery__
 *
 * To run a query within a React component, call `useFetchCancelInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCancelInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCancelInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFetchCancelInfoQuery(baseOptions: Apollo.QueryHookOptions<FetchCancelInfoQuery, FetchCancelInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCancelInfoQuery, FetchCancelInfoQueryVariables>(FetchCancelInfoDocument, options);
      }
export function useFetchCancelInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCancelInfoQuery, FetchCancelInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCancelInfoQuery, FetchCancelInfoQueryVariables>(FetchCancelInfoDocument, options);
        }
export type FetchCancelInfoQueryHookResult = ReturnType<typeof useFetchCancelInfoQuery>;
export type FetchCancelInfoLazyQueryHookResult = ReturnType<typeof useFetchCancelInfoLazyQuery>;
export type FetchCancelInfoQueryResult = Apollo.QueryResult<FetchCancelInfoQuery, FetchCancelInfoQueryVariables>;
export const CancelProductSubscriptionDocument = gql`
    mutation CancelProductSubscription($subscriptionId: ID!) {
  cancelProductSubscription(subscriptionId: $subscriptionId) {
    commandId
  }
}
    `;
export type CancelProductSubscriptionMutationFn = Apollo.MutationFunction<CancelProductSubscriptionMutation, CancelProductSubscriptionMutationVariables>;

/**
 * __useCancelProductSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelProductSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelProductSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelProductSubscriptionMutation, { data, loading, error }] = useCancelProductSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCancelProductSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelProductSubscriptionMutation, CancelProductSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelProductSubscriptionMutation, CancelProductSubscriptionMutationVariables>(CancelProductSubscriptionDocument, options);
      }
export type CancelProductSubscriptionMutationHookResult = ReturnType<typeof useCancelProductSubscriptionMutation>;
export type CancelProductSubscriptionMutationResult = Apollo.MutationResult<CancelProductSubscriptionMutation>;
export type CancelProductSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelProductSubscriptionMutation, CancelProductSubscriptionMutationVariables>;
export const FetchProductSubscriptionDocument = gql`
    query FetchProductSubscription($userId: ID!) {
  getProductSubscriptions(userId: $userId) {
    subscriptionId
    planId
    status
    productId
    billing {
      status
      expiration
    }
    source {
      id
    }
  }
}
    `;

/**
 * __useFetchProductSubscriptionQuery__
 *
 * To run a query within a React component, call `useFetchProductSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProductSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProductSubscriptionQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFetchProductSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<FetchProductSubscriptionQuery, FetchProductSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchProductSubscriptionQuery, FetchProductSubscriptionQueryVariables>(FetchProductSubscriptionDocument, options);
      }
export function useFetchProductSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchProductSubscriptionQuery, FetchProductSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchProductSubscriptionQuery, FetchProductSubscriptionQueryVariables>(FetchProductSubscriptionDocument, options);
        }
export type FetchProductSubscriptionQueryHookResult = ReturnType<typeof useFetchProductSubscriptionQuery>;
export type FetchProductSubscriptionLazyQueryHookResult = ReturnType<typeof useFetchProductSubscriptionLazyQuery>;
export type FetchProductSubscriptionQueryResult = Apollo.QueryResult<FetchProductSubscriptionQuery, FetchProductSubscriptionQueryVariables>;
export const GetCommandDocument = gql`
    query GetCommand($userId: ID!, $commandId: ID!) {
  getCommand(userId: $userId, commandId: $commandId) {
    commandId
    status
    result {
      status
      processedAt
    }
  }
}
    `;

/**
 * __useGetCommandQuery__
 *
 * To run a query within a React component, call `useGetCommandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommandQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      commandId: // value for 'commandId'
 *   },
 * });
 */
export function useGetCommandQuery(baseOptions: Apollo.QueryHookOptions<GetCommandQuery, GetCommandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommandQuery, GetCommandQueryVariables>(GetCommandDocument, options);
      }
export function useGetCommandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommandQuery, GetCommandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommandQuery, GetCommandQueryVariables>(GetCommandDocument, options);
        }
export type GetCommandQueryHookResult = ReturnType<typeof useGetCommandQuery>;
export type GetCommandLazyQueryHookResult = ReturnType<typeof useGetCommandLazyQuery>;
export type GetCommandQueryResult = Apollo.QueryResult<GetCommandQuery, GetCommandQueryVariables>;
export const FetchCancellationConfigDocument = gql`
    query FetchCancellationConfig($vendorId: ID!) {
  getCancellationConfig(vendorId: $vendorId) {
    type
  }
}
    `;

/**
 * __useFetchCancellationConfigQuery__
 *
 * To run a query within a React component, call `useFetchCancellationConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCancellationConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCancellationConfigQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useFetchCancellationConfigQuery(baseOptions: Apollo.QueryHookOptions<FetchCancellationConfigQuery, FetchCancellationConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCancellationConfigQuery, FetchCancellationConfigQueryVariables>(FetchCancellationConfigDocument, options);
      }
export function useFetchCancellationConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCancellationConfigQuery, FetchCancellationConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCancellationConfigQuery, FetchCancellationConfigQueryVariables>(FetchCancellationConfigDocument, options);
        }
export type FetchCancellationConfigQueryHookResult = ReturnType<typeof useFetchCancellationConfigQuery>;
export type FetchCancellationConfigLazyQueryHookResult = ReturnType<typeof useFetchCancellationConfigLazyQuery>;
export type FetchCancellationConfigQueryResult = Apollo.QueryResult<FetchCancellationConfigQuery, FetchCancellationConfigQueryVariables>;
import React from 'react';
import { Route } from '../../router/utils';
import Landing from './Landing';
import Preview from './Preview';

export default ({
    path
}: any = {}) => {
    return <>
        <Route path={path} exact component={Landing} />
        <Route
            path={`${path}/:id`}
            exact
            render={({
                match
            }: any) => {
                const { id } = match.params;
                return <Preview entryId={id} />;
            }}
        />
    </>;
};

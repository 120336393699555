import React from 'react';
import moment from 'moment';
import Table from '../../Table';

export default ({
    logs
}: any = {}) => {
    return <>
        <Table
            rows={logs.sort((a: any, b: any) => {
                return a.transactedAt > b.transactedAt ? -1 : 1;
            })}
            columns={[
                { label: 'transacted', id: 'transactedAt', render: (r: any) => moment(r.transactedAt).format('M/D/Y') },
                { label: 'status', id: 'status' },
                { label: 'result', id: 'resolution.status' },
                {
                    label: 'effective on',
                    id: 'effectiveAt',
                    render: (r: any) => r.resolution.effectiveAt && moment(r.resolution.effectiveAt).format('M/D/Y')
                },
                {
                    label: 'refund',
                    id: 'resolution.refund',
                    render: (r: any) => r.resolution.refund && `$${r.resolution.refund}`
                }
            ]}
            options={{
                showSearch: false,
                showTitle: false,
                allowSorting: false,
                showPagination: false
            }}
        />
    </>;
};

import React from 'react';
import saveVendor from './api';
import BillNegotiation from './BillNegotiation';
import ErrorMessage from '../../../../ui/ErrorMessage';

const BillNegotiationContainer = ({ vendor, onSave }: any) => {
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

    const save = async (billNegotiationConfig: any) => {
        let status;
        let body;
        try {
            ({ status, body } = await saveVendor(billNegotiationConfig.vendorId, billNegotiationConfig));
        } catch (err) {
            setErrorMessage(`${err} (${new Date().getTime()})`);
            return false;
        }

        switch (status) {
            case 200: {
                onSave();
                return true;
            }
            case 400: {
                const [e] = body;
                setErrorMessage(`${e.message} (${new Date().getTime()})`);
                return false;
            }
            default:
                setErrorMessage(`api returned status code ${status} (${new Date().getTime()})`);
                return false;
        }
    };

    return (
        <>
            <BillNegotiation vendor={vendor} save={save} />
            <ErrorMessage message={errorMessage} />
        </>
    );
};

export default BillNegotiationContainer;

import { ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';
import * as sessionManager from './services/session';

const httpLink = createHttpLink({
    uri: '/graphql'
});

const authLink = setContext(async (_, { headers }) => {
    const s = sessionManager.get();
    if (s) {
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${s.tokens.hiatus}`
            }
        };
    }

    return {};
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    name: 'hiatus.tools'
});

export default client;

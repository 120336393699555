import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ErrorMessage from '../../../ui/ErrorMessage';
import * as api from './api';
import { FilteredInstitution, FilteredVendor, Institution, Vendor } from '../types';
import Data from './Data';

const disallowedVendors = ['{{displayName}}', '{{displayName}}-updated'];
const disallowedInstitutions = [''];

const getFilteredVendors = async (
    setErrorMessage: Dispatch<SetStateAction<string | undefined>>,
    setVendors: Dispatch<SetStateAction<FilteredVendor[] | undefined>>
) => {
    try {
        const { body, status } = await api.getVendors();
        if (![200, 202].includes(status)) {
            setErrorMessage(`received code ${status} while retrieving vendors (${new Date().getTime()})`);
            setVendors([]);
            return;
        }
        const filteredVendors: Vendor[] = body
            .filter((vendor: Vendor) => vendor && !disallowedVendors.includes(vendor.displayName))
            .map((vendor: Vendor) => ({
                displayName: vendor.displayName,
                vendorId: vendor.vendorId
            }));
        setVendors(filteredVendors);
    } catch (error) {
        setErrorMessage(`Something went wrong fetching vendors (${new Date().getTime()})`);
        setVendors([]);
    }
};

const getFilteredInstitutions = async (
    setErrorMessage: Dispatch<SetStateAction<string | undefined>>,
    setInstitutions: Dispatch<SetStateAction<FilteredInstitution[] | undefined>>
) => {
    const isDevEnv = window.location.hostname.includes('dev') || process.env.NODE_ENV === 'development';

    await fetch(isDevEnv ? '/institutions/develop.json' : '/institutions/prod.json')
        .then((response) => {
            return response.json();
        })
        .then((data: Institution[]) => {
            const filteredData: FilteredInstitution[] = data
                .filter((institution) => institution && !disallowedInstitutions.includes(institution.name))
                .map((institution) => ({
                    name: institution.name,
                    institutionId: institution.institutionId
                }));
            setInstitutions(filteredData);
        })
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        .catch((error) => {
            setErrorMessage(`Something went wrong fetching institutions (${new Date().getTime()})`);
            setInstitutions([]);
        });
};

const DataContainer = () => {
    const [processing, setProcessing] = useState<boolean>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [vendors, setVendors] = useState<FilteredVendor[]>();
    const [institutions, setInstitutions] = useState<FilteredInstitution[]>();

    useEffect(() => {
        getFilteredVendors(setErrorMessage, setVendors);
        getFilteredInstitutions(setErrorMessage, setInstitutions);
    }, []);

    const onSubmit = async (userId: string, institutionId: string, vendorId: string) => {
        setProcessing(true);
        try {
            const { status: userStatus } = await api.getUser(userId);

            if (![200, 202].includes(userStatus)) {
                setErrorMessage(`received code ${userStatus} while retrieving user ${userId} (${new Date().getTime()})`);
                setProcessing(false);
                return false;
            }

            const { status } = await api.insertTestData(userId, institutionId, [vendorId]);

            if (![200, 202].includes(status)) {
                setErrorMessage(`received code ${status} while adding test data on user ${userId} (${new Date().getTime()})`);
            }

            if ([200].includes(status)) {
                setErrorMessage(`Successfully added data (${new Date().getTime()})`);
                setProcessing(false);
                return true;
            }
        } catch (err) {
            setErrorMessage(`${err} (${new Date().getTime()})`);
        }

        setProcessing(false);
        return false;
    };

    return (
        <>
            <ErrorMessage message={errorMessage} />
            <Data processing={processing} onSubmit={onSubmit} vendors={vendors} institutions={institutions} />
        </>
    );
};

export default DataContainer;

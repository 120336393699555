import * as api from '../../../services/api';
import { RecurringTransaction } from '../types';

export const getUser = async (userId: string) => {
    const { status, body } = await api.get({
        path: `hiatus/identity-users-manage/user/${userId}`,
    });
    return { status, body };
};

export const getRecurring = async (
    userId: string
): Promise<{ status: number; body: RecurringTransaction[] }> => {
    const { status, body } = await api.get({
        path: `hiatus/transactions/user/${userId}/recurring/admin`,
    });
    return { status, body };
};

import { api } from '../../../services';

export const getNegotiation = async (userId: any, negotiationId: any) => {
    const { status, body: negotiation } = await api.get({
        path: `hiatus/bills/v3/user/${userId}/negotiation/${negotiationId}/detail`
    });

    if (status !== 200) {
        return { status };
    }

    const { status: vendorStatus, body: vendor } = await api.get({
        path: `hiatus/vendors/vendor/${negotiation.vendorId}`
    });
    if (vendorStatus === 200) {
        Object.assign(negotiation, { vendor });
    }

    return { status, body: negotiation };
};

export const getCognitoUser = async (userId: any) => {
    const { status, body } = await api.get({
        path: `hiatus/identity-users-manage/user/${userId}`
    });
    if (status !== 200) {
        return { status };
    }
    return { status, body };
};

export const getUser = async (userId: any) => {
    const { status, body } = await api.get({
        path: `hiatus/profile/user/${userId}/profile`
    });
    if (status !== 200) {
        return { status };
    }
    return { status, body };
};

export const getProductSubscriptions = async (userId: any) => {
    const { status, body } = await api.get({
        path: `hiatus/product-subscriptions/user/${userId}/subscription`
    });

    if (status !== 200) {
        return { status };
    }

    return { status, body };
};

export const getEmployees = async (subs: any) => {
    const { status, body } = await api.get({
        path: `hiatus/identity-employees/user/batch?subs=${subs.join(',')}`
    });

    if (status !== 200) {
        return { status };
    }

    return { status, body };
};

export interface UpdateNegotiationParams {
    userId: string;
    negotiationId: string;
    status: string;
    outcome: any;
    badData?: any[];
}
export const updateNegotiation = ({ userId, negotiationId, status, outcome, badData }: UpdateNegotiationParams) => {
    return api.put({
        path: `hiatus/bills/v3/user/${userId}/negotiation/${negotiationId}`,
        body: { status, outcome, badData }
    });
};

export const getVendors = () => {
    return api.get({
        path: 'vendors'
    });
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HiatusLogo from '../../../assets/hiatus.jpg';
import FBLogo from '../../../assets/facebook.png';
import GoogleLogo from '../../../assets/google-logo.png';
import AppleLogo from '../../../assets/apple-logo.png';
import Question from '../../../assets/question.png';

const useStyles = makeStyles(() => ({
    logo: {
        width: '30px'
    }
}));

export default (props: any) => {
    const classes = useStyles();
    let src;
    let alt;

    const { source, identities } = props;
    if (source === 'hiatus') {
        src = HiatusLogo;
        alt = 'Hiatus';
    } else if (source === 'external' && identities && identities.length > 0) {
        // currently assuming only one idp although this
        // could change if we ever support merging accounts
        //
        const idp = identities[0].provider;
        switch (idp) {
            case 'Facebook':
                src = FBLogo;
                alt = 'Facebook';
                break;
            case 'Google':
                src = GoogleLogo;
                alt = 'Google';
                break;
            case 'SignInWithApple':
                src = AppleLogo;
                alt = 'Apple';
                break;
            default:
                src = Question;
                alt = 'Unknown source';
        }
    } else {
        src = Question;
        alt = 'Unknown source';
    }

    return <img src={src} alt={alt} className={classes.logo} />;
};

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, fetchLoginConfig } from '../actions';
import Login from './Login';

export default connect(
    (state: any) => {
        const { isFetching, error, config } = state.identity;
        return {
            config,
            location: window.location,
            isFetching,
            error
        };
    },
    (dispatch: any) => bindActionCreators({ fetchLoginConfig, login }, dispatch)
)(Login);

import { AccordionDetails, Button, styled } from '@material-ui/core';

export const StyledButton = styled(Button)({
    backgroundColor: '#37C97C',
    textTransform: 'none',
    color: 'white',

    '&:hover': {
        backgroundColor: 'rgba(55, 201, 124, 0.85)',
    },
    '&:disabled': {
        color: '#00000042',
        backgroundColor: '#0000001f',
    },
});

export const LoadingContainer = styled('div')({
    textAlign: 'center',
});

export const StyledAccordionDetails = styled(AccordionDetails)({
    flexDirection: 'column',
});

import React from 'react';
import { getNegotiations, getUser } from './api';
import Search from './Search';
import ErrorMessage from '../../../ui/ErrorMessage';

const DetailContainer = () => {
    const [negotiations, setNegotiations] = React.useState([]);
    const [user, setUser] = React.useState<any>();
    const [processing, setProcessing] = React.useState<boolean | undefined>();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

    async function fetchNegotiations(userId: any) {
        setNegotiations([]);
        setUser(undefined);
        setProcessing(true);

        // get user
        const { status: uStatus, body: uBody } = await getUser(userId);
        switch (uStatus) {
            case 200:
                setUser(uBody);
                break;
            case 404:
                setUser(undefined);
                break;
            default:
                setErrorMessage(`received code ${uStatus} while retrieving user ${userId} (${new Date().getTime()})`);
        }

        // get negotiations
        const { status: nStatus, body: nBody } = await getNegotiations(userId);
        switch (nStatus) {
            case 200:
                setNegotiations(nBody);
                break;
            default:
                setErrorMessage(`received code ${nStatus} while retrieving negotiations for ${userId} (${new Date().getTime()})`);
        }

        setProcessing(false);
    }

    return (
        <>
            <Search user={user} negotiations={negotiations} onSearch={fetchNegotiations} processing={processing} />
            <ErrorMessage message={errorMessage} />
        </>
    );
};

export default DetailContainer;

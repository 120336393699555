import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Paper, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';
import { Tip } from '../../Help';
import { FilteredInstitution, FilteredVendor } from '../types';
import { AutocompleteContainer, BoxContainer, Button, CircularProgress, FormControl, StyledAutocomplete, TextField } from '../styled';

import Release from '../Release';

interface DataProps {
    processing?: boolean;
    apiStatus?: number;
    onSubmit: (userId: string, institutionId: string, vendorId: string) => Promise<boolean>;
    vendors: FilteredVendor[] | undefined;
    institutions: FilteredInstitution[] | undefined;
}

export default ({ onSubmit, processing, vendors, institutions }: DataProps) => {
    const [userId, setUserId] = useState('');
    const [vendor, setVendor] = useState<FilteredVendor | null>();
    const [vendorInputValue, setVendorInputValue] = useState<string | null>('');
    const [institution, setInstitution] = useState<FilteredInstitution | null>();
    const [institutionInputValue, setInstitutionInputValue] = useState('');

    const resetForm = useCallback(() => {
        setUserId('');
        const clearButtons = document.getElementsByClassName('MuiAutocomplete-clearIndicator') as unknown as HTMLButtonElement[];
        clearButtons[0].click();
        clearButtons[1].click();
    }, []);

    const submit = useCallback(async () => {
        if (vendor && institution && userId) {
            const result = await onSubmit(userId, institution.institutionId, vendor.vendorId);
            if (result) {
                resetForm();
            }
        }
    }, [vendor, institution, userId, resetForm]);

    return (
        <>
            <Helmet>
                <title>Hiatus Tools | Insert Test Data</title>
            </Helmet>

            <BoxContainer container spacing={3}>
                <Grid item xs={12} sm={12} md={9}>
                    <Paper elevation={0}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography variant="h6">Insert test data</Typography>
                            </AccordionSummary>

                            <FormControl variant="outlined">
                                <TextField
                                    onChange={(e) => setUserId(e.target.value)}
                                    variant="outlined"
                                    disabled={processing}
                                    placeholder="User ID"
                                    size="small"
                                    value={userId}
                                />
                                <AutocompleteContainer>
                                    <Autocomplete
                                        filterSelectedOptions
                                        id="vendor-combo-box"
                                        options={
                                            vendors || [
                                                {
                                                    displayName: 'Loading vendors...',
                                                    vendorId: ''
                                                }
                                            ]
                                        }
                                        getOptionDisabled={(option) => option.displayName === 'Loading vendors...'}
                                        getOptionLabel={(option) => option.displayName}
                                        size="small"
                                        disabled={processing}
                                        value={vendor}
                                        noOptionsText={vendors ? 'Vendor not found' : 'Loading vendors...'}
                                        onChange={(_, newValue: FilteredVendor | null) => {
                                            setVendor(newValue);
                                        }}
                                        inputValue={vendorInputValue || ''}
                                        onInputChange={(_, newInputValue: string) => {
                                            setVendorInputValue(newInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <StyledAutocomplete
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                variant="outlined"
                                                placeholder="Vendor name"
                                            />
                                        )}
                                    />
                                </AutocompleteContainer>

                                <AutocompleteContainer>
                                    <Autocomplete
                                        id="institution-combo-box"
                                        options={
                                            institutions || [
                                                {
                                                    institutionId: '',
                                                    name: 'Loading institutions...'
                                                }
                                            ]
                                        }
                                        getOptionDisabled={(option) => option.name === 'Loading institutions...'}
                                        getOptionLabel={(option) => option.name}
                                        size="small"
                                        disabled={processing}
                                        value={institution}
                                        noOptionsText="Institution not found"
                                        onChange={(_, newValue: FilteredInstitution | null) => {
                                            setInstitution(newValue);
                                        }}
                                        inputValue={institutionInputValue}
                                        onInputChange={(_, newInputValue: string) => {
                                            setInstitutionInputValue(newInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <StyledAutocomplete
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                variant="outlined"
                                                placeholder="Institution name"
                                            />
                                        )}
                                    />
                                </AutocompleteContainer>

                                <Button color="primary" variant="contained" onClick={submit} disabled={!(!!vendor && !!userId && !!institution)}>
                                    {processing ? <CircularProgress color="inherit" /> : 'Submit'}
                                </Button>
                            </FormControl>
                        </Accordion>
                    </Paper>
                    <Release />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                    <Tip>
                        <Typography variant="body2" component="p">
                            Use this screen to write test data.
                        </Typography>

                        <Typography variant="body2" component="p">
                            Click on submit once all fields are filled.
                        </Typography>
                    </Tip>
                </Grid>
                
            </BoxContainer>

        </>
    );
};

import React from 'react';
import { getEntry, getPreview } from './api';
import Preview from './Preview';
import ErrorMessage from '../../../ui/ErrorMessage';

async function getEntries(entryId: any) {
    const { status, body } = await getEntry(entryId);
    if (status !== 200 && status !== 404) {
        throw new Error(`received code ${status} while retrieving content`);
    }

    const { status: previewStatus, body: previewBody } = await getPreview(entryId);
    if (previewStatus !== 200 && previewStatus !== 404) {
        throw new Error(`received code ${status} while retrieving preview`);
    }

    return { currentEntry: body, previewEntry: previewBody };
}

const PreviewContainer = ({ entryId }: any = {}) => {
    const [entry, setEntry] = React.useState();
    const [preview, setPreview] = React.useState();
    const [processing, setProcessing] = React.useState<boolean | undefined>();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

    async function refresh() {
        setProcessing(true);
        try {
            const { currentEntry, previewEntry } = await getEntries(entryId);
            setEntry(currentEntry);
            setPreview(previewEntry);
        } catch (err: any) {
            setErrorMessage(`received code ${err.message} while retrieving preview. (${new Date().getTime()})`);
        }
        setProcessing(false);
    }
    React.useEffect(() => {
        async function load() {
            setProcessing(true);
            try {
                const { currentEntry, previewEntry } = await getEntries(entryId);
                setEntry(currentEntry);
                setPreview(previewEntry);
            } catch (err: any) {
                setErrorMessage(`received code ${err.message} while retrieving preview. (${new Date().getTime()})`);
            }
            setProcessing(false);
        }
        load();
    }, [entryId]);

    return (
        <>
            <Preview entryId={entryId} entry={entry} preview={preview} refresh={refresh} processing={processing} />
            <ErrorMessage message={errorMessage} />
        </>
    );
};

export default PreviewContainer;

import * as api from '../../../services/api';

export const getUser = async (userId: string) => {
    const { status, body } = await api.get({
        path: `hiatus/identity-users-manage/user/${userId}`,
    });
    return { status, body };
};

export const getVendors = async () => {
    const result = await api.get({
        path: 'hiatus/vendors/vendor',
    });

    return result;
};

export const insertTestData = async (
    userId: string,
    institutionId: string,
    vendorIds?: string[]
) => {
    const result = await api.post({
        path: `hiatus/internal-data/user/${userId}/data`,
        body: {
            ...(institutionId ? { institutionId } : {}),
            ...(vendorIds ? { vendorIds } : {}),
        },
    });
    return result;
};

import { api } from '../../../services';

const getCancellation = async (id: any) => {
    const cancel = await api.get({
        path: `hiatus/subscription-cancel-admin/cancellation/${id}`
    });
    return cancel;
};

export default getCancellation;

import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../Table';


const useStyles = makeStyles((theme) => ({
    box: {
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        display: 'inline-block'
    },
    shortNote: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    }
}));


export default ({
    logs
}: any = {}) => {
    const classes = useStyles();

    return (
        <Table
            columns={[
                { label: 'transacted', id: 'transacted', render: (r: any) => moment(r.transactedAt).format('M/D/Y') },
                { label: 'employee', id: 'employee', field: 'user.name' },
                { label: 'status', id: 'status', field: 'status' },
                {
                    label: 'result',
                    id: 'result',
                    render: (r: any) => {
                        const { outcome = {} } = r;
                        const { result = '' } = outcome;
                        return result;
                    }
                },
                {
                    label: 'reason',
                    id: 'reason',
                    render: (r: any) => {
                        const { outcome = {} } = r;
                        const { reason = '' } = outcome;
                        return reason;
                    }
                },
                {
                    label: 'notes',
                    id: 'notes',
                    render: (r: any) => {
                        const { outcome = {} } = r;
                        const { notes = '' } = outcome;
                        return <div className={classes.shortNote}>{notes}</div>;
                    }
                }
            ]}
            rows={logs.sort((a: any, b: any) => {
                return a.transactedAt > b.transactedAt ? -1 : 1;
            })}
            options={{
                showTitle: false,
                showPagination: false,
                showSearch: false,
                allowSorting: false
            }}
            detailPanel={(rowData: any) => {
                const { outcome = {} } = rowData;
                const { notes = '' } = outcome;
                return notes || '-';
            }}
        />
    );
};

/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default (props: any) => {
    const { input, label, initial, onChange } = props;
    const others = {};
    Object.keys(props).forEach((k) => {
        // to do
        if (k !== 'input' && k !== 'label' && k !== 'initial' && k !== 'onChange') {
            others[k] = props[k];
        }
    });

    const [checkState, setCheckState] = React.useState(initial);
    const onCheckboxChange = (event: any) => {
        setCheckState(event.target.checked);
        onChange(event);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox checked={checkState} value={input && input.value} name={input && input.name} onChange={onCheckboxChange} {...others} />
            }
            label={label}
        />
    );
};

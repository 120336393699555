import React from 'react';
import moment from 'moment';
import { CircularProgress, Paper, TableContainer } from '@material-ui/core';
import Table from '../Table';

interface SubscriptionInfo {
    subscriptionId: string;
    planId: string;
    status: string;
    productId: string;
    billing: { status: string; expiration?: string | null };
    source: { id: string; status?: string | null; link?: string | null };
}

interface PremiumHistoryProps {
    subscriptions: SubscriptionInfo[] | undefined;
    processing: boolean | undefined;
}

export default ({ processing, subscriptions }: PremiumHistoryProps) => {
    return (
        <TableContainer component={Paper}>
            {!processing && (
                <Table
                    columns={[
                        { label: 'Subscription Id', render: (s: SubscriptionInfo) => s.subscriptionId },
                        { label: 'Plan', render: (s: SubscriptionInfo) => s.planId },
                        { label: 'Status', render: (s: SubscriptionInfo) => s.status },
                        {
                            label: 'Expires',
                            render: (s: SubscriptionInfo) => (s.billing.expiration ? moment(s.billing.expiration).format('L') : '')
                        },
                        { label: 'Billing Source', render: (s: SubscriptionInfo) => s.source.id },
                        { label: 'Billing Status', render: (s: SubscriptionInfo) => s.source.status || 'unknown' },
                        { label: 'Billing Link', render: (s: SubscriptionInfo) => (s.source.link ? <a href={s.source.link}>link</a> : '') }
                    ]}
                    rows={subscriptions}
                    rowsPerPageOptions={[5, 10, 20]}
                    options={{
                        showSearch: false,
                        showTitle: false,
                        allowSorting: false
                    }}
                />
            )}
        </TableContainer>
    );
};

import React from 'react';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Checkbox from '../../../../ui/finalform/Checkbox';
import TextField from '../../../../ui/finalform/TextField';
import { toFloat, toInt } from '../../../../ui/finalform/parsers';
import { composeValidators, mustBeNumber, minValue } from '../../../../ui/finalform/validators';
import FormFields from './FormFields';
import { toForm, fromForm } from '../../../../utils/regex';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    },
    box: {
        marginTop: theme.spacing(1)
    },
    textField: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    progress: {
        verticalAlign: 'middle',
        marginRight: theme.spacing(2)
    },
    button: {},
    alertBox: {
        marginTop: theme.spacing(1),
        color: 'red'
    }
}));

export default ({ vendor, save }: any = {}) => {
    const classes = useStyles();
    if (vendor.fields) {
        vendor.fields.forEach((f: any) => {
            const { validation } = f;
            if (validation.regex) {
                validation.regex = toForm(validation.regex);
            }
        });
    }
    const [formFields, setformFields] = React.useState({ data: vendor.fields || [], dirty: false });
    const onFormFieldChange = (data: any) => {
        setformFields({ data, dirty: true });
    };
    const submit = async (values: any) => {
        const { vendorId, isSubscribable, cancellationLink, isNegotiable, negotiationThreshold, estimatedRevenue, isHidden, requirements } = values;
        if (requirements && requirements.minimumBills === '') {
            delete requirements.minimumBills;
        }
        const success = await save({
            vendorId,
            isSubscribable,
            cancellationLink: cancellationLink || '',
            isNegotiable,
            negotiationThreshold,
            estimatedRevenue,
            isHidden,
            requirements,
            fields: formFields.data.map((f: any) => {
                const { id, validation, displayName, tooltipUrl, tooltipText } = f;
                if (validation.regex) {
                    validation.regex = fromForm(validation.regex);
                }
                return { id, validation, displayName, tooltipUrl, tooltipText };
            })
        });

        if (success) {
            setformFields({ ...formFields, dirty: false });
            return {};
        }
        return { [FORM_ERROR]: `an error occurred` }; // keep form dirty
    };

    return (
        <Form initialValues={vendor} onSubmit={submit}>
            {({ submitting, handleSubmit, dirty: formDirty }) => (
                <form onSubmit={handleSubmit}>
                    <Paper className={classes.paper}>
                        <span>Tracked Subscriptions = isSubscribable = transactions will be saved as bills in billnegotation.subscriptions</span>
                        <span>Hidden = isHidden = transactions will be saved in billnegotation.subscriptions but not returned </span>
                        <span>Bill Negotiations = isNegotiable = vendor is eligible for negotiations</span>
                        <Box className={classes.box}>
                            <Field name="isSubscribable" type="checkbox">
                                {({ input }) => {
                                    return (
                                        <Checkbox
                                            label="Tracked Subscriptions"
                                            color="primary"
                                            initial={vendor.isSubscribable || false}
                                            onChange={input.onChange}
                                        />
                                    );
                                }}
                            </Field>
                        </Box>
                        <Box className={classes.box}>
                            <Field name="isNegotiable" type="checkbox">
                                {({ input }) => (
                                    <Checkbox
                                        label="Bill Negotiations"
                                        color="primary"
                                        initial={vendor.isNegotiable || false}
                                        onChange={input.onChange}
                                    />
                                )}
                            </Field>
                            <div>
                                <Field name="negotiationThreshold" validate={composeValidators(mustBeNumber, minValue(0))} parse={toFloat}>
                                    {({ input, meta }) => <TextField label="$ threshold" input={input} meta={meta} className={classes.textField} />}
                                </Field>
                                <Field name="estimatedRevenue" validate={composeValidators(mustBeNumber, minValue(0))} parse={toFloat}>
                                    {({ input, meta }) => (
                                        <TextField label="$ estimated revenue" input={input} meta={meta} className={classes.textField} />
                                    )}
                                </Field>
                            </div>
                            <FormFields formFields={formFields.data} onChange={onFormFieldChange} />
                        </Box>
                        <Box className={classes.box}>
                            <h4>Extra Optional Requirements</h4>
                            <Field name="isHidden" type="checkbox">
                                {({ input }) => {
                                    return (
                                        <Checkbox
                                            label="Hidden (for data collection)"
                                            color="primary"
                                            initial={vendor.isHidden || false}
                                            onChange={input.onChange}
                                        />
                                    );
                                }}
                            </Field>
                            <div>
                                <Field name="requirements.minimumBills" parse={toInt}>
                                    {({ input, meta }) => {
                                        return <TextField label="Minimum Bills" input={input} meta={meta} className={classes.textField} />;
                                    }}
                                </Field>
                            </div>
                        </Box>
                    </Paper>
                    <Box className={classes.box} textAlign="right">
                        {submitting && <CircularProgress size={24} className={classes.progress} />}
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            size="medium"
                            type="submit"
                            disabled={!(formDirty || formFields.dirty) || submitting}
                        >
                            Save
                        </Button>
                    </Box>
                </form>
            )}
        </Form>
    );
};

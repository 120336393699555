import React from 'react';
import { Route } from '../../router/utils';
import Data from './Data';

export default input => {
    const { path } = input;

    if (path === '/recurring') {
        return <Route path={path} exact component={Data} />;
    }
    return <Route path={path} exact component={Data} />;
};

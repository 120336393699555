import { combineReducers } from 'redux';
import { reducer as identityReducer, actionTypes } from '../components/Identity';
import { reducer as vendorReducer } from '../components/Vendors';
import { connectionsReducer } from '../components/Users';

const appReducer = combineReducers({
    identity: identityReducer,
    vendors: vendorReducer,
    connections: connectionsReducer
});

export default (state: any, action: any) => {
    const loggedOut = action.type === actionTypes.IDENTITY_LOGOUT_SUCCESS;
    return appReducer(loggedOut ? {} : state, action);
};

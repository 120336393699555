import React from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Link } from '../../../router/utils';
import Section from '../../../ui/DataDisplay';
import AccountDetails from './AccountDetails';

import Outcome from './Outcome';
import History from '../../BillHistory/History';
import Logs from './Logs';
import { Tip } from '../../Help';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2)
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    sectionHead: {
        marginTop: theme.spacing(3)
    },
    progress: {
        marginTop: theme.spacing(3),
        textAlign: 'center'
    },
    helpKey: {
        fontWeight: 'bold'
    }
}));

export default ({ negotiationId, negotiation, subscription, results, reasons, onUpdate, processing, vendors, user }: any = {}) => {
    const classes = useStyles();
    const { status, userId, vendorId, vendor, outcome = {}, logs = [], createdAt } = negotiation || {};
    const { premiumSub, cognitoUser, profile } = user || {};
    return (
        <>
            <Helmet>
                <title>Hiatus Tools | Bill Negotiation {negotiationId}</title>
            </Helmet>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} sm={12} md={9}>
                    <Paper className={classes.paper}>
                        <h1 className={classes.title}>Bill Negotiation</h1>
                        <p>{negotiationId}</p>
                        {negotiation && (
                            <>
                                <h3 className={classes.sectionHead}>Details</h3>
                                <Section>
                                    <h4>user</h4>
                                    <span>
                                        {profile.displayName || profile.email} | <Link to={`/dashboard/users/${userId}`}>{userId}</Link>
                                    </span>
                                    {premiumSub && premiumSub.billing && premiumSub.billing.status === 'canceled' && (
                                        <>
                                            <h4 style={{ color: 'black', fontWeight: 'bold' }}>Premium Billing</h4>
                                            <div style={{ fontWeight: 'bold' }}>Auto Renew Status: {premiumSub.billing.status}</div>
                                            {premiumSub.billing.expiration && (
                                                <div style={{ fontWeight: 'bold' }}>
                                                    Expiration Date: {moment(premiumSub.billing.expiration).format('M/D/Y')}
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {(!premiumSub || premiumSub.status !== 'ok' || profile.status === 'closed' || !cognitoUser) && (
                                        <>
                                            <h4 style={{ color: 'red', fontWeight: 'bold' }}>Not eligible for negotiation</h4>
                                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                                                {profile.status === 'closed' || !cognitoUser ? 'Hiatus Account Closed' : ''}
                                            </span>
                                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                                                {!premiumSub || premiumSub.status !== 'ok' ? 'Not Premium User' : ''}
                                            </span>
                                        </>
                                    )}

                                    <h4>vendor</h4>
                                    <span>
                                        {vendor && vendor.displayName} | {vendorId}
                                    </span>
                                    <h4>submitted</h4>
                                    <span>{moment(createdAt).format('M/D/Y')}</span>
                                    <h4>status</h4>
                                    <span>{status}</span>
                                </Section>
                                <h3 className={classes.sectionHead}>Account Details</h3>
                                <AccountDetails negotiation={negotiation} onUpdate={onUpdate} />
                                <h3 className={classes.sectionHead}>Bills</h3>
                                {subscription && <History transactions={subscription.transactions || []} />}

                                <h3 className={classes.sectionHead}>Outcome</h3>
                                <Outcome
                                    outcome={outcome}
                                    results={results}
                                    reasons={reasons}
                                    onUpdate={onUpdate}
                                    processing={processing}
                                    premiumSub={premiumSub}
                                    profile={profile}
                                    cognitoUser={cognitoUser}
                                />

                                <h3 className={classes.sectionHead}>History</h3>
                                <Logs logs={logs} />
                            </>
                        )}
                        {!negotiation && processing && (
                            <div className={classes.progress}>
                                <CircularProgress />
                            </div>
                        )}
                        {!negotiation && !processing && <span>User or negotiation does not exist</span>}
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Tip>
                        <Typography variant="body2" component="h2">
                            {vendors &&
                                vendors[vendorId] &&
                                vendors[vendorId].map((s: any) => {
                                    return (
                                        <>
                                            <a href={`tel:${s.num}`}>{s.name}</a> {s.num}
                                        </>
                                    );
                                })}
                        </Typography>
                    </Tip>
                </Grid>
            </Grid>
        </>
    );
};

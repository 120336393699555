import React, { FC, useCallback, useEffect, useState } from 'react';

import { useFetchChargebeePaymentsQuery, useRefundChargebeePaymentMutation } from '../../types/gql';
import ErrorMessage from '../../ui/ErrorMessage';

import ChargebeePaymentHistory from './ChargebeePaymentHistory';

type Error = {
    message: string;
};

interface ChargebeePaymentHistoryContainerProps {
    userId: string;
}

const genericErrorMessage = 'An error occurred. Please try again later.';

const ChargebeePaymentHistoryContainer: FC<ChargebeePaymentHistoryContainerProps> = ({
    userId,
}) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { data, loading, error, refetch } = useFetchChargebeePaymentsQuery({
        variables: { userId },
    });
    const [refundChargebeePaymentMutation, { loading: refundLoading }] =
        useRefundChargebeePaymentMutation();

    useEffect(() => {
        if (!error) return;
        setErrorMessage(`Error fetching Chargebee payments: ${error.message}`);
    }, [error]);

    const refundPaymentHandler = useCallback(
        async (paymentId: string) => {
            try {
                const { data: refundMutationData, errors: refundMutationErrors } =
                    await refundChargebeePaymentMutation({
                        variables: { paymentId, userId },
                    });

                if (refundMutationErrors || !refundMutationData) {
                    setErrorMessage(refundMutationErrors?.[0].message ?? genericErrorMessage);
                }
            } catch (err) {
                setErrorMessage(`Error refunding payment: ${(err as Error).message}`);
            }
            await refetch();
        },
        [userId, refundChargebeePaymentMutation, setErrorMessage]
    );

    return (
        <>
            <ErrorMessage message={errorMessage} />
            <ChargebeePaymentHistory
                payments={data?.chargebeePayments}
                paymentsLoading={loading}
                refundLoading={refundLoading}
                refundPaymentHandler={refundPaymentHandler}
            />
        </>
    );
};

export default ChargebeePaymentHistoryContainer;

import { actionTypes } from './actions';

const initialState = { details: {} };

export default (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.VENDORS_GETALL_SUCCESS: {
            const { vendors, billNegotiation } = action.payload;

            vendors.forEach((v: any) => {
                const billDetails = billNegotiation.find((b: any) => {
                    return b.vendorId === v.vendorId;
                });
                if (billDetails) {
                    const { isSubscribable, isNegotiable, negotiationThreshold, estimatedRevenue } = billDetails;

                    Object.assign(v, {
                        isSubscribable,
                        isNegotiable,
                        negotiationThreshold,
                        estimatedRevenue
                    });
                }
            });
            return { ...state, all: vendors };
        }
        case actionTypes.VENDORS_GETVENDOR_SUCCESS: {
            const { vendor } = action.payload;
            const { vendorId } = vendor;
            const detail = { ...(state.details[vendorId] || {}), ...vendor };
            const wrapper = {};
            wrapper[vendorId] = detail;

            return { ...state, details: { ...state.details, ...wrapper } };
        }
        default: {
            return state;
        }
    }
};

import React from 'react';
import { Helmet } from 'react-helmet';
import { debounce } from 'throttle-debounce';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Grid from '@material-ui/core/Grid';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';

import { useHistory } from '../../../router/utils';
import { Tip } from '../../Help';
import Source from '../Source';
import Table from '../../Table';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    logo: {
        width: '30px',
    },
    verifiedIcon: {
        verticalAlign: 'middle',
        color: 'Green',
    },
    notVerifiedIcon: {
        verticalAlign: 'middle',
        color: 'Gold',
    },
    verificationNotNeededIcon: {
        verticalAlign: 'middle',
        color: 'Gray',
    },
}));

export default ({ users, onSearch, processing }: any = {}) => {
    const classes = useStyles();
    const doSearch = debounce(500, (text: any) => {
        if (text) {
            onSearch(text);
        }
    });

    const history = useHistory();
    return (
        <>
            <Helmet>
                <title>Hiatus Tools | Search Users</title>
            </Helmet>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} sm={12} md={9}>
                    <Table
                        title="Hiatus Users"
                        columns={[
                            {
                                label: 'Source',
                                id: 'source',
                                render: (rowData: any) => {
                                    return (
                                        <Source
                                            source={rowData.source}
                                            identities={rowData.identities}
                                        />
                                    );
                                },
                            },
                            { label: 'Email', id: 'email' },
                            {
                                label: 'Verified',
                                id: 'emailVerified',
                                render: (rowData: any) => {
                                    const { source, emailVerified } = rowData;
                                    if (source === 'external') {
                                        return (
                                            <MinimizeIcon
                                                className={classes.verificationNotNeededIcon}
                                            />
                                        );
                                    }
                                    if (emailVerified) {
                                        return <CheckCircleIcon className={classes.verifiedIcon} />;
                                    }

                                    return <WarningIcon className={classes.notVerifiedIcon} />;
                                },
                            },
                            { label: 'Id', id: 'id' },
                        ]}
                        rows={users}
                        options={{
                            // could support pagination in the future
                            showPagination: false,
                        }}
                        onRowClick={(evt: any, selectedRow: any) => {
                            history.push(`/dashboard/users/${selectedRow.id}`);
                        }}
                        showLoader={processing}
                        onChange={(e: any) => doSearch(e.target.value.trim())}
                        searchPlaceholder="Find by email"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Tip>
                        <Typography variant="body2" component="p">
                            Use this screen to search for users by email address.
                        </Typography>
                        <Typography variant="body2" component="p">
                            Click on a row to view that user.
                        </Typography>

                        <Typography variant="h5" component="p">
                            For deleted users see below.
                        </Typography>
                        <Typography variant="body1" component="p">
                            Go to the userId directly, use the URL:
                            <a href="/dashboard/users/the-user-id">/dashboard/users/the-user-id</a>.
                            Replace the-user-id with the actual userId.
                        </Typography>
                    </Tip>
                </Grid>
            </Grid>
        </>
    );
};

const set = (key: any, val: any) => {
    localStorage.setItem(key, val);
};

const setJson = (key: any, val: any) => {
    localStorage.setItem(key, JSON.stringify(val));
};

const get = (key: any) => {
    return localStorage.getItem(key);
};

const getJson = (key: any) => {
    let parsed;
    try {
        //! FIXME type issue
        // @ts-expect-error ts migration issue
        parsed = JSON.parse(localStorage.getItem(key));
    } catch (e) {
        parsed = null;
    }

    return parsed;
};

const clear = (key: any) => {
    localStorage.removeItem(key);
};

const clearAll = () => {
    localStorage.clear();
};

export { set, setJson, get, getJson, clear, clearAll };

import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Grid, Paper, Typography, styled } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
    BoxContainer,
    FormControl,
    ReleaseButton,
    ReleaseTextArea,
    ReleaseTextField,
    CircularProgress,
} from '../styled';

const StyledAccordionDetails = styled(AccordionDetails)({
    flexDirection: 'column'
});
const releasablePlatforms = ['ios','android','web'] as const;
type ReleasablePlatform = typeof releasablePlatforms[number];

interface ReleaseProps {
    processing?: boolean;
    onSubmit: (versionTitle: string, releaseNotes: string) => Promise<boolean>;
    versionString: string | undefined;
    platform: string;
    handlePlatformChange: (platformInput: React.SetStateAction<ReleasablePlatform>) => void;
    versionId: string | undefined;
    setVersionString: Dispatch<SetStateAction<string | undefined>>;
}

const Release = ({ processing, onSubmit, versionString, platform, handlePlatformChange, versionId, setVersionString }: ReleaseProps) => {
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        handlePlatformChange(event.target.value as ReleasablePlatform)
    };
    const [versionTitle, setVersionTitle] = useState('');
    const [releaseNotes, setReleaseNotes] = useState('');

    const resetForm = useCallback(() => {
        setVersionTitle('');
        setReleaseNotes('');
    }, []);
    
     const submit = useCallback(async () => {
        const targetPlatform = releasablePlatforms.find(p => p === platform)
        // Best practice would be display an error message or similar if platform isn't valid

        if (targetPlatform && versionString && versionTitle && releaseNotes) {
            const result = await onSubmit(versionTitle, releaseNotes);
            if (result === true) {
                resetForm();
            }
        }
    }, [platform, versionId, versionString, versionTitle, releaseNotes]); 
    return (
        <BoxContainer container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
                <Paper elevation={0}>
                    <Accordion style={{marginTop:20}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6">Release Client App</Typography>
                        </AccordionSummary>
                        <StyledAccordionDetails>
                        <FormControl variant="outlined">
                            <Select 
                            onChange={handleChange} 
                            value={platform}
                            >
                                <MenuItem value="ios">iOS</MenuItem>
                                <MenuItem value="android">Android</MenuItem>
                                <MenuItem value="web">Web</MenuItem>
                                {/* OnChange => tell parent container the target platform changed */}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <ReleaseTextField
                                onChange={platform === 'web' ? (e) => setVersionString(e.target.value) : (e) => handlePlatformChange(e.target.value as ReleasablePlatform)}
                                label="Version Number"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                placeholder={platform !== 'web' ? "NO AVAILABLE VERSIONS" : "INPUT WEB VERSION"}
                                size="medium"
                                disabled={platform !== 'web'}
                                value={versionString}
                            />

                            <ReleaseTextField
                                onChange={(e) => setVersionTitle(e.target.value)}
                                label="Version Title"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                placeholder=""
                                size="medium"
                                value={versionTitle}
                            />
                        </FormControl>
                        <FormControl>
                            <ReleaseTextArea
                                onChange={(e) => setReleaseNotes(e.target.value)}
                                multiline
                                label="Release Notes"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                rows={5}
                                placeholder="What's changed in this release"
                                size="medium"
                                value={releaseNotes}
                            />
                        </FormControl>
                        <FormControl>
                            <ReleaseButton 
                            color="primary" 
                            variant="contained" 
                            size="medium" 
                            onClick={submit} 
                            disabled={!(!!platform && !!versionString && !!versionTitle && !!releaseNotes)}
                            >
                                {processing ? <CircularProgress color="inherit" /> : 'Release This Version'}
                            </ReleaseButton>
                        </FormControl>
                        </StyledAccordionDetails>
                    </Accordion>
                </Paper>
            </Grid>
        </BoxContainer>
    );
};

export default Release;

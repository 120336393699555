import { Routes as UserRoutes } from '../../Users';
import { Routes as BillNegotiationRoutes } from '../../BillNegotiation';
import { Routes as SubscriptionCancellationRoutes } from '../../SubscriptionCancellations';
import { Routes as VendorRoutes } from '../../Vendors';
import { Routes as ContentRoutes } from '../../Content';
import { Routes as FormRoutes } from '../../Forms';
import { Routes as DataRoutes } from '../../Data';
import { Routes as RecurringRoutes } from '../../Recurring';

const exported = {
    users: {
        path: '/dashboard/users',
        routes: UserRoutes,
        auth: {
            context: 'id',
            claim: 'qu',
        },
    },

    billNegotiation: {
        path: '/dashboard/billnegotiation',
        routes: BillNegotiationRoutes,
        auth: {
            context: 'bln',
            claim: 'una',
        },
    },

    subscriptionCancellations: {
        path: '/dashboard/subscriptioncancel',
        routes: SubscriptionCancellationRoutes,
        auth: {
            context: 'bln',
            claim: 'usca',
        },
    },

    vendors: {
        path: '/dashboard/vendors',
        routes: VendorRoutes,
        auth: {
            context: 'act',
            claim: 'qv',
        },
    },

    content: {
        path: '/dashboard/content',
        routes: ContentRoutes,
        auth: {
            context: 'con',
            claim: 'qac',
        },
    },

    forms: {
        path: '/dashboard/forms',
        routes: FormRoutes,
        auth: {
            context: 'form',
            claim: 'qf',
        },
    },

    data: {
        path: '/dashboard/data',
        routes: DataRoutes,
        auth: {
            context: 'int',
            claim: 'cda',
        },
    },
    recurring: {
        path: '/dashboard/recurring',
        routes: RecurringRoutes,
        auth: {
            context: 'int',
            claim: 'cda',
        },
    },
};

export default exported;

export const { users, billNegotiation, subscriptionCancellations, vendors, content, forms } =
    exported;

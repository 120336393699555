import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';

import ChargebeePaymentHistoryContainer from '../../ChargebeePaymentHistory';
import Section from '../../../ui/DataDisplay';
import { Tip } from '../../Help';
import Source from '../Source';
import Connections from './Connections/Connections';
import PremiumHistoryContainer from '../../PremiumHistory/PremiumHistory.container';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },
    title: {
        marginBottom: theme.spacing(4)
    },
    titleIcon: {
        verticalAlign: 'middle',
        marginRight: theme.spacing(1)
    },
    verifiedIcon: {
        verticalAlign: 'middle',
        marginLeft: theme.spacing(1),
        color: 'Green'
    },
    notVerifiedIcon: {
        verticalAlign: 'middle',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: 'Gold'
    },
    progress: {
        verticalAlign: 'middle',
        marginLeft: theme.spacing(1)
    },
    button: {
        marginLeft: theme.spacing(2)
    },
    alertButton: {
        marginLeft: theme.spacing(2),
        backgroundColor: 'red'
    },
    tipWrapper: {
        marginTop: '15px'
    }
}));

interface UserDetailProps {
    user: any;
    onVerifyEmail: any;
    onDeactivateAccount: any;
    processing: boolean | undefined;
    hasPremium: boolean;
    onCancelPremium: () => Promise<boolean>;
}

export default ({ user, onVerifyEmail, onDeactivateAccount, processing, hasPremium, onCancelPremium }: UserDetailProps) => {
    const classes = useStyles();
    const [deactivateOpen, setDeactivateOpen] = React.useState(false);
    const [cancelOpen, setCancelOpen] = React.useState(false);

    const handleDeactivateOpen = () => {
        setDeactivateOpen(true);
    };

    const handleDeactivateConfirm = () => {
        setDeactivateOpen(false);
        onDeactivateAccount();
    };

    const handleDeactivateCancel = () => {
        setDeactivateOpen(false);
    };

    const handleCancelOpen = () => {
        setCancelOpen(true);
    };

    const handleCancelConfirm = () => {
        setCancelOpen(false);
        onCancelPremium();
    };

    const handleCancelCancel = () => {
        setCancelOpen(false);
    };

    if (!user) {
        return <CircularProgress />;
    }

    const { source, identities, firstName, lastName, email, emailVerified } = user;
    return (
        <>
            <Helmet>
                <title>Hiatus Tools | {email}</title>
            </Helmet>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} sm={12} md={9}>
                    <Paper className={classes.paper}>
                        <h1 className={classes.title}>
                            <span className={classes.titleIcon}>
                                <Source source={source} identities={identities} />
                            </span>
                            {firstName || 'FirstName'} {lastName || 'LastName'}
                        </h1>
                        <Section>
                            <h3>Account Security</h3>
                            <h4>email address</h4>
                            <span>{email}</span>
                            {source === 'hiatus' && (
                                <>
                                    {!emailVerified ? (
                                        <>
                                            <Tooltip title="email is not verified">
                                                <WarningIcon className={classes.notVerifiedIcon} />
                                            </Tooltip>
                                            <Button
                                                className={classes.button}
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                disabled={processing}
                                                onClick={onVerifyEmail}
                                            >
                                                Verify
                                            </Button>
                                            {processing && <CircularProgress size={16} className={classes.progress} />}
                                        </>
                                    ) : (
                                        <CheckCircleIcon className={classes.verifiedIcon} />
                                    )}
                                </>
                            )}
                            <Button
                                className={classes.alertButton}
                                variant="contained"
                                // @ts-expect-error some type issue
                                color="error"
                                size="medium"
                                disabled={processing}
                                onClick={handleDeactivateOpen}
                            >
                                Deactivate Account
                            </Button>
                            <Dialog
                                open={deactivateOpen}
                                onClose={handleDeactivateCancel}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">Deactivate user account?</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        This action is not reversible. Are you sure you want to proceed?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleDeactivateCancel}>Cancel</Button>
                                    <Button onClick={handleDeactivateConfirm}>Confirm</Button>
                                </DialogActions>
                            </Dialog>
                            {hasPremium && (
                                <Button
                                    className={classes.alertButton}
                                    variant="contained"
                                    // @ts-expect-error some type issue
                                    color="error"
                                    size="medium"
                                    disabled={processing}
                                    onClick={handleCancelOpen}
                                >
                                    Cancel User Premium
                                </Button>
                            )}
                            <Dialog
                                open={cancelOpen}
                                onClose={handleCancelCancel}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">Cancel user premium?</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">Are you sure you want to proceed?</DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCancelCancel}>Cancel</Button>
                                    <Button onClick={handleCancelConfirm}>Confirm</Button>
                                </DialogActions>
                            </Dialog>
                        </Section>
                    </Paper>
                    <Connections userId={user.id} />
                    <PremiumHistoryContainer userId={user.id} />
                    <ChargebeePaymentHistoryContainer userId={user.id} />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Tip>
                        <Typography variant="body2" component="p">
                            Users must verify their email address before they can reset their password.
                        </Typography>
                        <Typography variant="body2" component="p">
                            If their email address is not verified, you can do so on this screen.
                        </Typography>
                        <Typography variant="body2" component="p">
                            Users who login via Facebook do not need to verify their email.
                        </Typography>
                    </Tip>
                    <div className={classes.tipWrapper}>
                        <Tip title="Connection Status Descriptions">
                            <Typography variant="body2" component="p">
                                <b>ok:</b> Connection is successfully connected and user should be receiving transaction updates.
                            </Typography>
                            <Typography variant="body2" component="p">
                                <b>deleted:</b> User has removed this connection and it will not be shown to them.
                            </Typography>
                            <Typography variant="body2" component="p">
                                <b>initial:</b> User has initiated the connection but we haven&apos;t received any account data or there is no account
                                data on the connection.
                            </Typography>
                            <Typography variant="body2" component="p">
                                <b>deprecated:</b> Connection has been removed due to user inactivity. They will need to reconnect it in order to
                                continue to receive transaction updates.
                            </Typography>
                            <Typography variant="body2" component="p">
                                <b>actionRequired:</b> We&apos;ve lost access to this connection. User will need to reconnect it in order to continue
                                to receive transaction updates.
                            </Typography>
                        </Tip>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { getAllConnections, resetConnection } from '../../actions';

const StyledAccordionDetails = styled(AccordionDetails)({
    flexDirection: 'column'
});

const NoConnectionsText = styled('div')({
    textAlign: 'center'
});

const Institution = styled('div')({
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    marginRight: '15px'
});

const InstitutionName = styled('div')({
    width: '195px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});

const Status = styled('div')({
    fontSize: '15px'
});

const InstitutionIconWrapper = styled('div')({
    marginLeft: '4px',
    marginRight: '12px'
});

const InstitutionIcon = styled('img')({
    width: '36px',
    color: '#666'
});

const StyledButton = styled(Button)({
    backgroundColor: '#37C97C',
    textTransform: 'none',
    color: 'white',

    '&:hover': {
        backgroundColor: 'rgba(55, 201, 124, 0.85)'
    }
});

const Connections = ({ userId }: any) => {
    const institutions = useSelector((state: any) => state.connections.institutions);
    const connections = useSelector((state: any) => state.connections.connections);
    const resetStatus = useSelector((state: any) => state.connections.reset);
    const dispatch = useDispatch();
    const isDevEnv = window.location.hostname.includes('dev') || process.env.NODE_ENV === 'development';

    useEffect(() => {
        dispatch(getAllConnections(userId));
    }, [dispatch]);

    const handleResetConnection = (connectionId: any) => {
        dispatch(resetConnection(userId, connectionId));
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h3>Linked Accounts</h3>
            </AccordionSummary>
            <StyledAccordionDetails>
                {!institutions.length ? (
                    <NoConnectionsText>No connections</NoConnectionsText>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Status</TableCell>
                                    {isDevEnv && <TableCell>Reset</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {institutions.map((institution: any) => {
                                    const currentConnection = connections.find((connection: any) => connection.institutionId === institution.id);
                                    const { connectionId, status } = currentConnection;

                                    return (
                                        <TableRow key={institution.id}>
                                            <TableCell>
                                                <Institution>
                                                    <InstitutionIconWrapper>
                                                        {institution.logo ? (
                                                            <InstitutionIcon
                                                                src={`data:image/png;base64, ${institution.logo}`}
                                                                alt={`${institution.name} logo`}
                                                            />
                                                        ) : (
                                                            <AccountBalanceIcon
                                                                style={{
                                                                    width: '36px',
                                                                    color: '#666'
                                                                }}
                                                            />
                                                        )}
                                                    </InstitutionIconWrapper>
                                                    <InstitutionName>{institution.name}</InstitutionName>
                                                </Institution>
                                            </TableCell>
                                            <TableCell>
                                                <Status>{status}</Status>
                                            </TableCell>
                                            {isDevEnv && (
                                                <TableCell>
                                                    {status === 'ok' && (
                                                        <StyledButton
                                                            variant="contained"
                                                            onClick={() => handleResetConnection(connectionId)}
                                                            disabled={resetStatus.loading}
                                                        >
                                                            Reset Account
                                                        </StyledButton>
                                                    )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </StyledAccordionDetails>
        </Accordion>
    );
};

export default Connections;

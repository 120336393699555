import React from 'react';
import { Helmet } from 'react-helmet';
import { FORM_ERROR } from 'final-form';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { api } from '../../../services';
import { useHistory, Redirect } from '../../../router/utils';
import VendorForm from '../Form';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    form: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(3, 2)
    }
}));

export default () => {
    const history = useHistory();
    const classes = useStyles();
    const [newVendorId, setNewVendor] = React.useState();

    const saveVendor = async (values: any) => {
        let status;
        let body;
        const { displayName, type } = values;
        try {
            ({ status, body } = await api.post({ path: `hiatus/vendors-admin/vendor`, body: { displayName, type } }));
        } catch (err) {
            return { [FORM_ERROR]: err };
        }

        switch (status) {
            case 201: {
                setNewVendor(body.vendorId);
                return {};
            }
            case 400: {
                const [e] = body;
                return { [FORM_ERROR]: e.message };
            }
            default:
                return { [FORM_ERROR]: `api returned status code ${status}` };
        }
    };
    return newVendorId ? (
        <Redirect
            to={{
                pathname: `/dashboard/vendors/${newVendorId}`
            }}
        />
    ) : (
        <>
            <Helmet>
                <title>Hiatus Tools | Create Vendor</title>
            </Helmet>
            <Container className={classes.container} maxWidth="sm">
                <Typography variant="h4">New Vendor</Typography>
                <Paper className={classes.form}>
                    <VendorForm onSave={saveVendor} onCancel={history.goBack} />
                </Paper>
            </Container>
        </>
    );
};

import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PersonIcon from '@material-ui/icons/Person';
import Popover from '@material-ui/core/Popover';
import TimerIcon from '@material-ui/icons/Timer';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: 10
    },
    profile: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    logout: {
        color: 'Red'
    }
}));

export default ({ user, exp, logout }: any = {}) => {
    const classes = useStyles();

    // popover state
    const [anchorEl, setAnchorEl] = React.useState(null);
    function handleClick(event: any) {
        setAnchorEl(event.currentTarget);
    }
    function handleClose() {
        setAnchorEl(null);
    }
    const openPop = Boolean(anchorEl);
    const id = openPop ? 'simple-popover' : undefined;

    // session exp (until auto refresh)
    const [timeToRefresh, setTimeToRefresh] = React.useState<string | undefined>();
    React.useEffect(() => {
        setTimeToRefresh(moment().to(moment.unix(exp)));
    }, [exp]);

    return (
        <>
            <Avatar className={classes.avatar} onClick={handleClick}>
                {user.firstInitial}
            </Avatar>
            <Popover
                id={id}
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <List className={classes.profile}>
                    <ListItem>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary={user.displayName} />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                        <ListItemIcon>
                            <TimerIcon />
                        </ListItemIcon>
                        <ListItemText primary="Session" secondary={`auto refresh ${timeToRefresh}`} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="logout" className={classes.logout}>
                                <Tooltip title="logout">
                                    <ExitToAppIcon onClick={logout} />
                                </Tooltip>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Popover>
        </>
    );
};

import { api } from '../../../../services';

const saveVendor = (vendorId: any, billNegotiationConfig: any) => {
    return api.put({
        path: `hiatus/subscription-vendors/v2/vendor/${vendorId}`,
        body: billNegotiationConfig
    });
};

export default saveVendor;

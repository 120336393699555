import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getVendor } from '../actions';
import Detail from './Detail';

export default connect(
    (state: any, ownProps: any) => {
        const { user } = state.identity.current;
        const { details } = state.vendors;
        return {
            claims: user.claims,
            vendorId: ownProps.vendorId,
            vendorDetails: details
        };
    },
    (dispatch: any) => bindActionCreators({ getVendor }, dispatch)
)(Detail);

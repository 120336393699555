import { api } from '../../../services';

export const getNegotiations = async (userId: any) => {
    const { status, body: negotiations } = await api.get({
        path: `hiatus/bills/v3/user/${userId}/negotiation`
    });

    if (status !== 200) {
        return { status };
    }

    const vendorIds = new Set();
    negotiations.forEach((n: any) => {
        vendorIds.add(n.vendorId);
    });
    if (vendorIds.size > 0) {
        const { status: vendorStatus, body: vendors } = await api.get({
            path: `hiatus/vendors/batch?ids=${Array.from(vendorIds).join(',')}`
        });
        if (vendorStatus === 200) {
            negotiations.forEach((n: any) => {
                Object.assign(n, {
                    vendor:
                        vendors.find((v: any) => {
                            return v.vendorId === n.vendorId;
                        }) || {}
                });
            });
        }
    }

    return { status, body: negotiations };
};

export const getUser = async (userId: any) => {
    const { status, body } = await api.get({
        path: `hiatus/profile/user/${userId}/profile`
    });
    if (status !== 200) {
        return { status };
    }

    return { status, body };
};

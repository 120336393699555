import Joi from '@hapi/joi';
import * as storage from './storage';

const schema = Joi.object({
    user: Joi.object({
        id: Joi.string().required(),
        claims: Joi.object().required(),
        displayName: Joi.string().required(),
        firstInitial: Joi.string().required()
    }).required(),
    tokens: Joi.object({
        hiatus: Joi.string().required(),
        cognito: Joi.object({
            refresh: Joi.string().required()
        }).required()
    }).required(),
    iat: Joi.number().integer().required(),
    exp: Joi.number().integer().required()
});

let current: any;
const save = (session: any) => {
    current = session;

    const { user, tokens, exp, iat } = session;
    storage.setJson('user', user);
    storage.setJson('tokens', tokens);
    storage.set('exp', exp);
    storage.set('iat', iat);
};

const get = () => {
    if (current) return current;

    const fromSession = {
        user: storage.getJson('user'),
        tokens: storage.getJson('tokens'),
        iat: storage.get('iat'),
        exp: storage.get('exp')
    };

    const isValid = !schema.validate(fromSession).error;
    if (!isValid) {
        storage.clearAll();
        return null;
    }

    return fromSession;
};

const clear = () => {
    storage.clearAll();
    current = null;
};

export { save, get, clear };

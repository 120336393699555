import React from 'react';
import { Remove, Check } from '@material-ui/icons';
import get from 'lodash/get';

const TableCellContent = ({ row, col }: any) => {
    const { type, render, id, field } = col;

    if (render) {
        return render(row) || '-';
    }

    const info = get(row, field || id);

    if (type !== 'boolean') return info || '';
    if (info) return <Check />;
    return <Remove />;
};

export default TableCellContent;

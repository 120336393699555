import { actionTypes } from './actions';
import { session } from '../../services';

const initialState = {
    config: {},
    current: null,
    isFetching: false,
    error: ''
};
export default (state = initialState, action: any) => {
    switch (action.type) {
        // config
        case actionTypes.IDENTITY_CONFIG_REQUEST: {
            return { ...state, isFetching: true };
        }
        case actionTypes.IDENTITY_CONFIG_SUCCESS: {
            const { urls } = action.payload;
            return { ...state, isFetching: false, config: { ...state.config, urls } };
        }
        case actionTypes.IDENTITY_CONFIG_ERROR: {
            return { ...state, isFetching: false };
        }

        // login requests
        case actionTypes.IDENTITY_LOGIN_REQUEST: {
            session.clear();
            return { ...state, isFetching: true, current: null, error: '' };
        }

        // success
        case actionTypes.IDENTITY_LOADSESSSION_SUCCESS:
        case actionTypes.IDENTITY_LOGIN_SUCCESS:
        case actionTypes.IDENTITY_REFRESH_SUCCESS: {
            const { user, tokens, iat, exp } = action.payload;
            user.firstInitial = user.displayName.substr(0, 1);
            const current = { user, tokens, iat, exp };

            session.save(current);
            return { ...state, isFetching: false, current };
        }

        // error/fail
        case actionTypes.IDENTITY_LOADSESSSION_ERROR:
        case actionTypes.IDENTITY_LOGIN_FAILURE:
        case actionTypes.IDENTITY_LOGIN_ERROR:
        case actionTypes.IDENTITY_REFRESH_FAILURE:
        case actionTypes.IDENTITY_REFRESH_ERROR: {
            session.clear();
            return { ...state, isFetching: false, current: null, error: action.payload.error };
        }

        // logout
        case actionTypes.IDENTITY_LOGOUT_SUCCESS: {
            session.clear();
            return { ...state, isFetching: false, current: null, error: '' };
        }

        default: {
            return state;
        }
    }
};

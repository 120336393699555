import React from 'react';
import saveRegex from './api';
import RegEx from './RegEx';
import ErrorMessage from '../../../../ui/ErrorMessage';
import { toForm, fromForm } from '../../../../utils/regex';

const RegExContainer = ({ vendorId, expressions, onSave }: any) => {
    const [processing, setProcessing] = React.useState<boolean | undefined>();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

    const save = async (updatedExpressions: any) => {
        let status;
        let body;
        setProcessing(true);
        try {
            ({ status, body } = await saveRegex(
                vendorId,
                updatedExpressions.map((i: any) => fromForm(i.expression))
            ));
        } catch (err) {
            setErrorMessage(`${err} (${new Date().getTime()})`);
            return false;
        }

        setProcessing(false);
        switch (status) {
            case 200: {
                onSave();
                return true;
            }
            case 400: {
                const [e] = body;
                setErrorMessage(`${e.message} (${new Date().getTime()})`);
                return false;
            }
            default:
                setErrorMessage(`api returned status code ${status} (${new Date().getTime()})`);
                return false;
        }
    };

    const mapped = expressions
        ? expressions.map((e: any) => {
              return { expression: toForm(e) };
          })
        : [];

    return (
        <>
            <RegEx expressions={mapped} save={save} processing={processing} />
            <ErrorMessage message={errorMessage} />
        </>
    );
};

export default RegExContainer;

import React from 'react';
import { Helmet } from 'react-helmet';
import JSONPretty from 'react-json-pretty';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    progress: {
        textAlign: 'center'
    },
    refreshButton: {
        textAlign: 'right'
    },
    container: {
        marginRight: theme.spacing(2),
        '& iframe': {
            margin: 0,
            padding: 0,
            borderWidth: 0
        }
    }
}));

export default ({ form, refresh, processing }: any = {}) => {
    const classes = useStyles();
    const [formData, setFormData] = React.useState();

    const iframe = document.getElementById('formFrame');
    React.useEffect(() => {
        if (iframe && form) {
            // @ts-expect-error ts migration issue TS(2551): Property 'contentDocument' does not exist on type ... Remove this comment to see the full error message
            iframe.contentDocument.open();
            // @ts-expect-error ts migration issue TS(2551): Property 'contentDocument' does not exist on type ... Remove this comment to see the full error message
            iframe.contentDocument.write(form);
            // @ts-expect-error ts migration issue TS(2551): Property 'contentDocument' does not exist on type ... Remove this comment to see the full error message
            iframe.contentDocument.close();
            // @ts-expect-error ts migration issue TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
            setFormData({});
        }
    }, [form, iframe]);

    React.useEffect(() => {
        window.addEventListener(
            'message',
            // @ts-expect-error ts migration issue TS(2322): Type '{}' is not assignable to type 'WindowEventMa... Remove this comment to see the full error message
            ({ data } = {}) => {
                const { event, payload } = data;
                switch (event) {
                    case 'hiatus.forms.loaded':
                        // @ts-expect-error ts migration issue TS(2531): Object is possibly 'null'.
                        document.getElementById('formFrame').contentWindow.postMessage({
                            event: 'hiatus.forms.disableOnSubmit'
                        });
                        break;
                    case 'hiatus.forms.submitting':
                        setFormData(payload.values.values);
                        break;
                    default:
                        // eslint-disable-next-line no-console
                        console.log(event);
                }
            },
            false
        );
    }, []);

    return (
        <>
            <Helmet>
                <title>Hiatus Tools | Custom Forms UX</title>
            </Helmet>
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12} sm={12} md={12}>
                    <h1>Custom Form UX Preview</h1>
                    {processing ? (
                        <div className={classes.progress}>{processing && <CircularProgress />}</div>
                    ) : (
                        <div className={classes.refreshButton}>
                            <Button
                                onClick={() => {
                                    refresh();
                                }}
                            >
                                <RefreshIcon />
                            </Button>
                        </div>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Paper className={classes.container}>
                        <iframe id="formFrame" width="100%" height="1000" title="Sample Vendor Form" />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <JSONPretty id="json-pretty" data={formData} />
                </Grid>
            </Grid>
        </>
    );
};

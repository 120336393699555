import React, { useCallback } from 'react';

import { deleteUser, getUser, verifyEmail } from './api';
import {
    useCancelProductSubscriptionMutation,
    useFetchProductSubscriptionQuery,
    useGetCommandQuery,
    CommandStatus,
    CommandResultStatus
} from '../../../types/gql';
import Detail from './Detail';
import ErrorMessage from '../../../ui/ErrorMessage';

const maxRequests = 30;
const pollInterval = 2000;
const wait = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms));

const SearchContainer = ({ userId }: any = {}) => {
    const [user, setUser] = React.useState();
    const { data: subData, loading: subLoading } = useFetchProductSubscriptionQuery({ variables: { userId } });

    async function fetchUser() {
        const { status, body } = await getUser(userId);
        if (status === 200) {
            setUser(body);
        }
    }
    React.useState(() => {
        fetchUser();
        //! FIXME signature issue
        // @ts-expect-error ts migration issue
    }, [userId]);

    const [processing, setProcessing] = React.useState<boolean | undefined>();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

    const subscriptions = subData?.getProductSubscriptions;
    const activeSubscription = subscriptions?.filter((elem) => elem.status === 'ok' && elem.billing.status !== 'canceled')[0];

    const [cancelPremium] = useCancelProductSubscriptionMutation({
        variables: { subscriptionId: activeSubscription?.subscriptionId || '' }
    });

    // not able to use lazy query hook because it doesn't return a promise and can't wait for the response
    const { refetch } = useGetCommandQuery({
        // manually control fetching via polling logic
        skip: true
    });

    const onVerifyEmail = async () => {
        let status;
        setProcessing(true);
        try {
            ({ status } = await verifyEmail(userId));
        } catch (err) {
            setErrorMessage(`${err} (${new Date().getTime()})`);
            return false;
        }

        if (status !== 200) {
            setProcessing(false);
            setErrorMessage(`api returned status code ${status} (${new Date().getTime()})`);
            return false;
        }

        await fetchUser();
        setProcessing(false);
        return true;
    };

    const onDeactivateAccount = async () => {
        let status;
        setProcessing(true);
        try {
            ({ status } = await deleteUser(userId));
        } catch (err) {
            setErrorMessage(`${err} (${new Date().getTime()})`);
            return false;
        }

        if (status !== 200 && status !== 202) {
            setProcessing(false);
            setErrorMessage(`api returned status code ${status} (${new Date().getTime()})`);
            return false;
        }

        setErrorMessage('user deleted');
        setProcessing(false);
        return true;
    };

    const onCancelPremium = useCallback(async (): Promise<boolean> => {
        setProcessing(true);

        try {
            const { data } = await cancelPremium();
            const commandId = data?.cancelProductSubscription.commandId;

            if (!commandId) {
                throw new Error();
            }

            await ((requestsCount) =>
                async function pollForConnection(): Promise<void> {
                    if (requestsCount >= maxRequests) {
                        throw new Error();
                    }

                    const { data: pollData } = await refetch({
                        userId: userId || '',
                        commandId
                    });

                    if (pollData?.getCommand.status === CommandStatus.processed && pollData?.getCommand.result) {
                        switch (pollData.getCommand.result.status) {
                            case CommandResultStatus.invalid:
                                throw new Error();
                            default:
                                return;
                        }
                    }

                    // eslint-disable-next-line no-param-reassign
                    requestsCount += 1;
                    await wait(pollInterval);

                    // eslint-disable-next-line consistent-return
                    return pollForConnection();
                })(0)();
        } catch (error) {
            const genericError = 'Something went wrong while canceling premium';
            setErrorMessage(genericError);
            return false;
        }

        setErrorMessage('user premium canceled');
        setProcessing(false);
        return true;
    }, [cancelPremium, refetch, userId, activeSubscription]);

    return (
        <>
            <Detail
                user={user}
                onVerifyEmail={onVerifyEmail}
                onDeactivateAccount={onDeactivateAccount}
                processing={processing || subLoading}
                hasPremium={activeSubscription !== undefined}
                onCancelPremium={onCancelPremium}
            />
            <ErrorMessage message={errorMessage} />
        </>
    );
};

export default SearchContainer;

import { api } from '../../services';

export const actionTypes = {
    CONNECTIONS_GETALL_REQUEST: 'CONNECTIONS/GETALL_REQUEST',
    CONNECTIONS_GETALL_SUCCESS: 'CONNECTIONS/GETALL_SUCCESS',
    CONNECTIONS_GETALL_ERROR: 'CONNECTIONS/GETALL_ERROR',
    CONNECTIONS_RESET_REQUEST: 'CONNECTIONS/RESET_REQUEST',
    CONNECTIONS_RESET_SUCCESS: 'CONNECTIONS/RESET_SUCCESS',
    CONNECTIONS_RESET_ERROR: 'CONNECTIONS/RESET_ERROR',
};

const path = 'hiatus/connect';

export const getAllConnections = (userId: any) => async (dispatch: any) => {
    let institutions = [];
    await dispatch({ type: actionTypes.CONNECTIONS_GETALL_REQUEST });

    try {
        const { body: connections } = await api.get({
            path: `${path}/user/${userId}/connection`,
        });

        if (connections.length) {
            const institutionIds = connections.map(
                (connection: any) => connection.institutionId
            );

            const { body } = await api.get({
                path: `hiatus/institutions/institution/batch?ids=${institutionIds.join(
                    ','
                )}`,
            });
            institutions = body;
        }
        return await dispatch({
            type: actionTypes.CONNECTIONS_GETALL_SUCCESS,
            payload: { connections, institutions },
        });
    } catch (error) {
        return dispatch({
            type: actionTypes.CONNECTIONS_GETALL_ERROR,
            payload: { error },
        });
    }
};

export const resetConnection = (userId: any, connectionId: any) => async (dispatch: any) => {
    await dispatch({ type: actionTypes.CONNECTIONS_RESET_REQUEST });

    try {
        const { body: reset } = await api.post({
            path: `${path}/user/${userId}/connection/${connectionId}/reset`,
        });

        return await dispatch({
            type: actionTypes.CONNECTIONS_RESET_SUCCESS,
            payload: { reset },
        });
    } catch (error) {
        return dispatch({
            type: actionTypes.CONNECTIONS_RESET_ERROR,
            payload: { error },
        });
    }
};

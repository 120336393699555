import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Auth from './Auth';
import { loadFromSession, monitor } from '../actions';

export default connect(
    (state: any) => {
        const { current } = state.identity;
        return {
            current
        };
    },
    (dispatch: any) => bindActionCreators({ loadFromSession, monitor }, dispatch)
)(Auth);

import React from 'react';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: '100%'
    },
    warning: {
        color: 'Darkorange',
        backgroundColor: theme.palette.background.paper,
        textAlign: 'center',
        marginTop: theme.spacing(1),
        padding: theme.spacing(1)
    },
    box: {
        marginTop: theme.spacing(1)
    },
    progress: {
        verticalAlign: 'middle',
        marginRight: theme.spacing(2)
    },
    button: {
        marginLeft: theme.spacing(1)
    }
}));
const columns = [{ title: 'Regular Expression', field: 'expression' }];

export default ({ expressions = [], save, processing }: any = {}) => {
    const classes = useStyles();
    const [rows, setRows] = React.useState(expressions);
    const [dirty, setDirty] = React.useState(false);

    const addItem = (newItem: any) => {
        setRows([...rows, newItem]);
    };
    const updateItem = (newItem: any, oldItem: any) => {
        const tmp = [...rows];
        const index = tmp.indexOf(oldItem);
        tmp[index] = newItem;
        setRows(tmp);
    };
    const removeItem = (oldItem: any) => {
        const tmp = [...rows];
        const index = tmp.indexOf(oldItem);
        tmp.splice(index, 1);
        setRows(tmp);
    };
    const getValidationErrs = (newItem: any) => {
        // to do could validate reg ex here
        return newItem.expression ? null : { expression: 'expression is required' };
    };

    const sanitizedExpressions = rows.map((exp) => {
        return exp.expression.replaceAll('\\\\', '\\');
    });
    const regexString = sanitizedExpressions.join('|');
    const regexTestUrl = `https://regex101.com/?regex=${regexString}&flags=i`;

    return (
        <div>
            <MaterialTable
                title=""
                columns={columns}
                data={rows}
                options={{
                    search: false,
                    paging: false
                }}
                components={{
                    EditField: (props) => {
                        const { columnDef, rowData, value } = props;

                        let helperText = '';
                        let hasError = false;
                        const { validation } = rowData;
                        const { field } = columnDef;
                        if (validation && validation[field]) {
                            hasError = true;
                            helperText = validation[field];
                        }

                        return (
                            <TextField
                                defaultValue={value}
                                onChange={(e) => props.onChange(e.target.value)}
                                className={classes.textField}
                                autoFocus
                                error={hasError}
                                helperText={helperText}
                            />
                        );
                    }
                }}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise<void>((resolve, reject) => {
                            setDirty(true);
                            const errors = getValidationErrs(newData);
                            if (!errors) {
                                addItem(newData);
                                resolve();
                            } else {
                                Object.assign(newData, { validation: errors });
                                reject(new Error());
                            }
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise<void>((resolve, reject) => {
                            setDirty(true);
                            const errors = getValidationErrs(newData);
                            if (!errors) {
                                updateItem(newData, oldData);
                                resolve();
                            } else {
                                Object.assign(newData, { validation: errors });
                                reject(new Error());
                            }
                        }),
                    onRowDelete: (oldData) =>
                        new Promise<void>((resolve) => {
                            setDirty(true);
                            removeItem(oldData);
                            resolve();
                        })
                }}
            />
            <Box className={classes.box} textAlign="right">
                {processing && <CircularProgress size={24} className={classes.progress} />}
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    size="medium"
                    disabled={!dirty || processing}
                    onClick={() => {
                        const success = save(rows);
                        if (success) {
                            setDirty(false);
                        }
                    }}
                >
                    Save
                </Button>
            </Box>
            <div className={classes.warning}>
                <Box>All backslashes must be escaped (\b turns into \\b)</Box>
                <Box>
                    <a target="_blank" rel="noreferrer" href={regexTestUrl}>
                        Test Regex here
                    </a>
                </Box>
            </div>
        </div>
    );
};

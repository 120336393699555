import React from 'react';
import { Provider } from 'react-redux';
import store from '../../store';
import { Auth } from '../Identity';
import Router from '../../router';

function App() {
    return (
        <Provider store={store}>
            <Auth>
                <Router />
            </Auth>
        </Provider>
    );
}

export default App;

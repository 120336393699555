import { api } from '../../../services';

export const getCancellation = async (cancellationId: any) => {
    const { status, body: cancellation } = await api.get({
        path: `hiatus/subscription-cancel-admin/cancellation/${cancellationId}`
    });
    if (status !== 200) {
        return { status };
    }

    const { status: vendorStatus, body: vendor } = await api.get({
        path: `hiatus/vendors/vendor/${cancellation.vendorId}`
    });
    if (vendorStatus === 200) {
        Object.assign(cancellation, { vendor });
    }
    const { status: subStatus, body: submission } = await api.get({
        path: `hiatus/forms/user/${cancellation.userId}/submission/${cancellation.form.submissionId}`
    });
    if (subStatus === 200) {
        Object.assign(cancellation, { submission });
    }
    return { status, body: cancellation };
};

export const getUser = async (userId: any) => {
    const { status, body: user } = await api.get({
        path: `hiatus/identity-users-manage/user/${userId}`
    });
    if (status !== 200) {
        return { status };
    }

    const { status: profileStatus, body: profile } = await api.get({
        path: `hiatus/profile/user/${userId}/profile`
    });
    if (profileStatus === 200) {
        Object.assign(user, { ...profile });
    }

    return { status, body: user };
};

export const getProfile = async (userId: any) => {
    const { status, body } = await api.get({
        path: `hiatus/profile/user/${userId}/profile`
    });
    if (status !== 200) {
        return { status };
    }
    return { status, body };
};

export const getCognitoUser = async (userId: any) => {
    const { status, body } = await api.get({
        path: `hiatus/identity-users-manage/user/${userId}`
    });
    if (status !== 200) {
        return { status };
    }
    return { status, body };
};

export const updateCancellation = ({ cancellationId, status, resolution, notes, badData }: any) => {
    return api.put({
        path: `hiatus/subscription-cancel-admin/cancellation/${cancellationId}`,
        body: { status, resolution, notes, badData }
    });
};

export const getProductSubscriptions = async (userId: any) => {
    const { status, body } = await api.get({
        path: `hiatus/product-subscriptions/user/${userId}/subscription`
    });

    if (status !== 200) {
        return { status };
    }

    return { status, body };
};

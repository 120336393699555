import React from 'react';
import { Route } from '../../router/utils';
import Search from './Search';
import Detail from './Detail';

export default ({
    path
}: any = {}) => {
    return <>
        <Route
            path={`${path}`}
            exact
            render={() => {
                return <Search />;
            }}
        />
        <Route
            path={`${path}/:userId/:negotiationId`}
            exact
            render={({
                match
            }: any) => {
                const { userId, negotiationId } = match.params;
                return <Detail userId={userId} negotiationId={negotiationId} />;
            }}
        />
    </>;
};
